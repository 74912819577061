import {storyblokEditable} from '@storyblok/js'
import {useRouter} from 'next/router'
import useUser from '../../../lib/hooks/use-user'
import {pathTranslations} from '../../../path-translations'
import type {CategoryCardListStoryblok} from '../../../storyblok-types/component-types-sb'
import CategoryList from '../../collection/CategoryList'

const CategoryListBlok = ({blok}: {blok: CategoryCardListStoryblok}) => {
  const {locale} = useRouter()

  const {user} = useUser()

  const CategoryCards = blok.category_cards.map(category => {
    let link = '/'

    const categorySlug = category.link?.categorySlug?.[locale!]

    if (category.type === 'promotions') {
      link = pathTranslations.promotions[locale!]!
    } else if (category.type === 'category' && categorySlug) {
      link = `${pathTranslations.products[locale!]}/${categorySlug}`
    } else {
      link = pathTranslations.products[locale!]!
    }

    return {
      id: category._uid,
      title: category.title,
      icon: category.pictogram?.filename,
      iconAlt: category.pictogram?.alt ?? '',
      linkType: category.type?.[0] ?? 'link',
      link: user.activeBU || !category.tabacco ? link : `/login?url=${link}`,
    }
  })

  const productsOverviewPath = pathTranslations.products[locale!]!

  return (
    <CategoryList
      sbEditable={storyblokEditable(blok)}
      title={blok.title}
      cards={CategoryCards}
      path={productsOverviewPath}
    />
  )
}

export default CategoryListBlok
