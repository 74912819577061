import {Bitter, Open_Sans} from '@next/font/google'

import {apiPlugin, storyblokInit} from '@storyblok/react'
import type {NextPage} from 'next'
import {SessionProvider} from 'next-auth/react'
import {appWithTranslation} from 'next-i18next'
import {withPasswordProtect} from 'next-password-protect'
import type {AppProps} from 'next/app'
import {QueryClientProvider} from 'react-query'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import {ModalProvider} from '../lib/context/modal-context'
import type {GetLayout} from '../lib/page-props'
import queryClient from '../lib/react-query-client'
import '../styles/globals.css'
import '../styles/print.css'

import Script from 'next/script'
import useLocaleCookie from '../lib/hooks/use-locale-cookie'

const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '600', '700'],
  display: 'swap',
  variable: '--font-open-sans',
})

const bitter = Bitter({
  subsets: ['latin'],
  weight: ['400', '600'],
  display: 'swap',
  variable: '--font-bitter',
})

import ContactForm from '../components/form/ContactForm'
import AccountPage from '../components/storyblok/global/AccountPage'
import ContentPage from '../components/storyblok/global/ContentPage'
import Homepage from '../components/storyblok/global/Homepage'
import ProfileHomepage from '../components/storyblok/global/ProfileHomepage'
import StatusPage from '../components/storyblok/global/StatusPage'
import AccountDetailsBlok from '../components/storyblok/page/AccountDetailsBlok'
import CarouselBlok from '../components/storyblok/page/CarouselBlok'
import CategoryListBlok from '../components/storyblok/page/CategoryListBlok'
import CompanyUSPListBlok from '../components/storyblok/page/CompanyUSPListBlok'
import FaqListBlok from '../components/storyblok/page/FAQListBlok'
import FavoriteListsBlok from '../components/storyblok/page/FavoriteListsBlok'
import OrderListBlok from '../components/storyblok/page/OrderListBlok'
import OrderSuggestionBlok from '../components/storyblok/page/OrderSuggestionBlok'
import InvoiceListBlok from '../components/storyblok/page/InvoiceListBlok'
import JobListBlok from '../components/storyblok/page/JobListBlok'
import MediaTextBlok from '../components/storyblok/page/MediaTextBlok'
import NewProductsCarouselBlok from '../components/storyblok/page/NewProductsCarousel'
import Quote from '../components/storyblok/page/QuoteBlok'
import SalesContactBlok from '../components/storyblok/page/SalesContactBlok'
import ServiceCardListBlok from '../components/storyblok/page/ServiceCardListBlok'
import StatusBlok from '../components/storyblok/page/StatusBlok'
import StatusWithSearchBlok from '../components/storyblok/page/StatusWithSearchBlok'
import TextBlok from '../components/storyblok/page/TextCardBlok'
import PersonalMessageBlok from '../components/storyblok/page/PersonalMessageBlok'
import CTACardBlok from '../components/storyblok/page/CTACardBlok'
import HighlightCarouselBlok from '../components/storyblok/page/HighlightCarouselBlok'
import {HistoryProvider} from '../lib/hooks/use-history'
import ProgressBar from 'next-nprogress-bar'
import PDFViewerBlok from '../components/storyblok/page/PDFViewerBlok'
import PDFTeaserBlok from '../components/storyblok/page/PDFTeaserBlok'
import {CartContextProvider} from '../lib/context/cart-context'
import ErrorBoundary from '../components/error-boundary'

const components = {
  homepage: Homepage,
  content_page: ContentPage,
  account_page: AccountPage,
  status_page: StatusPage,
  profile_homepage: ProfileHomepage,
  quote: Quote,
  company_usp_list: CompanyUSPListBlok,
  category_card_list: CategoryListBlok,
  service_card_list: ServiceCardListBlok,
  faq_list: FaqListBlok,
  job_list: JobListBlok,
  media_text: MediaTextBlok,
  text: TextBlok,
  carousel: CarouselBlok,
  sales_contact: SalesContactBlok,
  account_details: AccountDetailsBlok,
  favorite_lists: FavoriteListsBlok,
  order_list: OrderListBlok,
  status: StatusBlok,
  status_with_search: StatusWithSearchBlok,
  new_products_carousel: NewProductsCarouselBlok,
  contact_form: ContactForm,
  personal_message: PersonalMessageBlok,
  cta_card: CTACardBlok,
  highlight_carousel: HighlightCarouselBlok,
  invoice_list: InvoiceListBlok,
  order_proposal: OrderSuggestionBlok,
  pdf_viewer: PDFViewerBlok,
  pdf_teaser: PDFTeaserBlok,
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components,
})

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>
}

export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: GetLayout<T>
}

function MyApp({Component, pageProps}: AppPropsWithLayout) {
  useLocaleCookie()
  const {navigation, footer, translatedPaths, ...componentProps} = pageProps

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={pageProps.session}>
          <ModalProvider>
            <HistoryProvider translatedPaths={translatedPaths}>
              <CartContextProvider>
                <div className={`${openSans.variable} ${bitter.variable}`}>
                  {getLayout(
                    <>
                      <Script
                        id="google-tag-manager"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-PVD35VL');`,
                        }}
                      />
                      <Script
                        key="script-onetrust"
                        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                        data-document-language="true"
                        type="text/javascript"
                        data-domain-script={process.env.NEXT_PUBLIC_DATA_DOMAIN_SCRIPT}
                        strategy="afterInteractive"
                      />
                      <Component {...componentProps} />
                      <ProgressBar height="4px" color="#FDB94A" />
                    </>,
                    pageProps,
                    pageProps.translatedPaths,
                  )}
                </div>
              </CartContextProvider>
            </HistoryProvider>
          </ModalProvider>
        </SessionProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

MyApp.componentDidCatch = (error: any) => {
  // @ts-ignore
  this?.setState({error})
  // Anything else you want to do with the error.
}

export default appWithTranslation(
  process.env.NEXT_PUBLIC_ENABLE_PASSWORD_PROTECT === 'false'
    ? MyApp
    : withPasswordProtect(MyApp, {
        loginApiUrl: '/api/password-protect/login',
        checkApiUrl: '/api/password-protect/password-check',
      }),
)
