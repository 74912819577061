export const getNearestDateInFuture = (dates: Date[]) => {
  const sorted = dates.sort((a, b) => a.getTime() - b.getTime())
  const nearest = sorted.find(date => date.getTime() > Date.now())

  return nearest
}

export const dateToShortString = (date: Date, locale: string, symbol?: string) => {
  if (!date) return ''

  let dateString = date.toLocaleDateString(locale, {dateStyle: 'short'}) // hardcoded locale 'nl-BE' outputs DD/MM/YYYY

  if (symbol) {
    dateString = dateString.replace(/\D/g, symbol)
  }

  return dateString
}

export const dateToLongString = (date: Date, locale: string) => {
  if (!date) return ''

  let dateString = date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return dateString
}

export const dateToTimeString = (date: Date, locale: string) => {
  if (!date) return ''

  let timeString = date.toLocaleTimeString(locale, {timeStyle: 'short'}) // hardcoded locale 'nl-BE' outputs DD/MM/YYYY

  return timeString
}
