import type {PdfTeaserStoryblok} from '../../../storyblok-types/component-types-sb'
import PDFTeaserCard from '../../cards/PDFTeaserCard'
import ComponentContainer from '../../containers/ComponentContainer'

const PDFTeaserBlok = ({blok}: {blok: PdfTeaserStoryblok}) => {
  const path =
    blok.link?.url == ''
      ? `/${blok.link?.story?.url}`
      : blok.link?.linktype === 'email'
      ? `mailto:${blok.link?.url}`
      : blok.link?.url

  return (
    <ComponentContainer type="medium">
      <PDFTeaserCard
        teaserAlt={blok.teaser.alt ?? ''}
        teaser={blok.teaser.filename}
        title={blok.title}
        path={path}
      />
    </ComponentContainer>
  )
}

export default PDFTeaserBlok
