import {ListProductCardProps} from '../components/cards/ListProductCard'
import {ProductListByKeysQuery} from '../gql-types'
import {centPrecisionToString} from './commercetools/ct-utilities'

// Some Remarks
//* no type yet for product that is received from cosmosDB
//* no image in cosmosDB for product
//* no slug in cosmosDB for product
//* These are now fetched from commercetools using id (Should these be used from there?)

export const mapOrderProduct = (
  product: any,
  locale: string,
  productCommercetools?: ProductListByKeysQuery['products']['results'][0],
  commercetoolsColisProducts?: {[k: string]: ProductListByKeysQuery['products']['results'][0]},
) => {
  const orderCardProduct: ListProductCardProps = {
    id: product.product_id,
    brand: product.product_attributes.brand ?? '',
    productName: product.product_attributes.name[locale] ?? '',
    contentPerUnit: product.product_attributes.content_per_unit,
    quantity: product.quantity,
    price: centPrecisionToString(
      product.total_price.cent_amount,
      product.total_price.fraction_digits,
      product.total_price.currency_code,
    ),
    notAvailable: true,
    hidePrice: true,
  }

  //check if product exists in commercetools and if colis, check if products exist
  let productAvailable: boolean = productCommercetools ? true : false

  const colisProducts = productCommercetools?.masterData.current?.masterVariant.attributesRaw.find(
    item => item.name === 'colis_products',
  )?.value

  if (colisProducts) {
    colisProducts.forEach((product: string) => {
      if (!commercetoolsColisProducts?.[JSON.parse(product).product]) {
        productAvailable = false
      }
    })
  }

  if (productAvailable) {
    orderCardProduct.notAvailable = false
    orderCardProduct.image =
      productCommercetools?.masterData.current?.masterVariant.images?.[0]?.url ?? ''
    orderCardProduct.slug = productCommercetools?.masterData.current?.slug ?? '#'
    orderCardProduct.category = productCommercetools?.masterData.current?.categories?.[0]?.key ?? ''
  }

  return orderCardProduct
}
