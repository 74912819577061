import type {PersonalMessageStoryblok} from '../../../storyblok-types/component-types-sb'
import PersonalMessageCard from '../../cards/PersonalMessageCard'

import RichText from '../../RichText'

const PersonalMessageBlok = ({blok}: {blok: PersonalMessageStoryblok; fitParent?: string}) => {
  const button = {
    label: blok.cta_label,
    path:
      blok.cta_link?.url == ''
        ? `/${blok.cta_link?.story?.url}`
        : blok.cta_link?.linktype === 'email'
        ? `mailto:${blok.cta_link?.url}`
        : blok.cta_link?.url,
    openInNewWindow: blok.cta_link?.target ? true : blok.cta_link?.target ? true : false,
  }
  return <PersonalMessageCard message={<RichText richText={blok.message} />} CTA={button} />
}

export default PersonalMessageBlok
