import Image from 'next/image'
import Link from 'next/link'

export interface PromoCardProps {
  id: string | number
  image: string
  imageAlt?: string
  imageFill?: boolean
  label?: string
  description?: string
  link: string
}

const PromoCard = ({image, imageAlt, label, description, link, imageFill}: PromoCardProps) => {
  return (
    <Link href={link} className="h-full">
      <div className="w-full h-full rounded-t-3xl bg-fixmerWhite flex flex-col overflow-hidden rounded-b-3xl">
        <div className="w-full relative g768:min-h-[20rem] h-[18rem] g768:h-[22vw]">
          <Image
            src={image}
            alt={imageAlt ?? 'promo'}
            fill
            className={imageFill ? 'object-cover' : 'object-contain'}
          />
        </div>
        <div className="flex min-h-[4rem] g768:h-fit  items-center py-4 bg-fixmerWhite w-full">
          <div className="flex items-center text-center h-fit">
            {label ? (
              <p className={`bg-fixmerRed p-4 rounded-full text-white text-body font-bold mx-4`}>
                <span>{label}</span>
              </p>
            ) : null}
          </div>
          <div
            className={`font-semiBold pr-4 flex items-center ${
              !label ? 'g768:px-6 px-4 py-4' : ''
            } h-full`}
          >
            {description?.substring(0, 100)}
            {description?.length && description.length > 100 ? '...' : ''}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PromoCard
