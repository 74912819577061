import type {PdfViewerStoryblok} from '../../../storyblok-types/component-types-sb'

import dynamic from 'next/dynamic'

const PDFViewer = dynamic(() => import('../../components/PDFViewer'), {
  loading: () => <p>Loading PDF viewer...</p>,
  ssr: false,
})

const PDFViewerBlok = ({blok}: {blok: PdfViewerStoryblok}) => {
  return <PDFViewer pdf={blok.pdf?.filename!} />
}

export default PDFViewerBlok
