import router from 'next/router'
import type {CartProductCardProps} from '../components/cards/CartProductCard'
import type {CartPromoCardProps} from '../components/cards/CartPromoCard'
import {ProductListByKeysQuery} from '../gql-types'
import ProductImagePlaceHolder from '../public/assets/placeholder.svg'
import {centPrecisionToString} from './commercetools/ct-utilities'
import type {ShoppingCartWithSubtotals, ShoppingCartWithSubtotalsLineItem} from './service-types'

export interface CartProps {
  totalVatExcl: string
  totalVatExclBeforePromo: string
  totalVat: string
  totalVatIncl: string
  totalVatInclBeforePromo: string
  nonTobaccoProducts?: {
    products?: CartProductCardProps[]
    totalVatExcl: string
    totalVatExclBeforePromo: string
    totalVat: string
    totalVatIncl: string
    totalVatInclBeforePromo: string
  }
  tobaccoProducts?: {
    products?: CartProductCardProps[]
    total: string
    totalBeforePromo: string
  }
  freeProducts?: CartPromoCardProps[]
}

export const mapCart = async (data: ShoppingCartWithSubtotals, locale: string) => {
  const cart: CartProps = {
    totalVatExcl: data.discounted_totals.total_net
      ? centPrecisionToString(
          data.discounted_totals.total_net.cent_amount,
          data.discounted_totals.total_net.fraction_digits,
          data.discounted_totals.total_net.currency_code,
        )
      : '0.00',
    totalVatExclBeforePromo: data.undiscounted_totals.total_net
      ? centPrecisionToString(
          data.undiscounted_totals.total_net.cent_amount,
          data.undiscounted_totals.total_net.fraction_digits,
          data.undiscounted_totals.total_net.currency_code,
        )
      : '0.00',
    totalVat: data.discounted_totals.total_tax
      ? centPrecisionToString(
          data.discounted_totals.total_tax.cent_amount,
          data.discounted_totals.total_tax.fraction_digits,
          data.discounted_totals.total_tax.currency_code,
        )
      : '0.00',
    totalVatIncl: data.discounted_totals.total_gross
      ? centPrecisionToString(
          data.discounted_totals.total_gross.cent_amount,
          data.discounted_totals.total_gross.fraction_digits,
          data.discounted_totals.total_gross.currency_code,
        )
      : '0.00',
    totalVatInclBeforePromo: data.undiscounted_totals.total_gross
      ? centPrecisionToString(
          data.undiscounted_totals.total_gross.cent_amount,
          data.undiscounted_totals.total_gross.fraction_digits,
          data.undiscounted_totals.total_gross.currency_code,
        )
      : '0.00',
  }

  if (data.non_tobacco_products && data.non_tobacco_products.line_items.length > 0) {
    const nonTobaccoProducts = {
      products: mapCartProducts(data.non_tobacco_products?.line_items, locale),
      totalVatExcl: data.non_tobacco_products.discounted_totals.total_net
        ? centPrecisionToString(
            data.non_tobacco_products.discounted_totals.total_net.cent_amount,
            data.non_tobacco_products.discounted_totals.total_net.fraction_digits,
            data.non_tobacco_products.discounted_totals.total_net.currency_code,
          )
        : '0.00',
      totalVatExclBeforePromo: data.non_tobacco_products.undiscounted_totals.total_net
        ? centPrecisionToString(
            data.non_tobacco_products.undiscounted_totals.total_net.cent_amount,
            data.non_tobacco_products.undiscounted_totals.total_net.fraction_digits,
            data.non_tobacco_products.undiscounted_totals.total_net.currency_code,
          )
        : '0.00',
      totalVat: data.non_tobacco_products.discounted_totals.total_tax
        ? centPrecisionToString(
            data.non_tobacco_products.discounted_totals.total_tax.cent_amount,
            data.non_tobacco_products.discounted_totals.total_tax.fraction_digits,
            data.non_tobacco_products.discounted_totals.total_tax.currency_code,
          )
        : '0.00',
      totalVatIncl: data.non_tobacco_products.discounted_totals.total_gross
        ? centPrecisionToString(
            data.non_tobacco_products.discounted_totals.total_gross.cent_amount,
            data.non_tobacco_products.discounted_totals.total_gross.fraction_digits,
            data.non_tobacco_products.discounted_totals.total_gross.currency_code,
          )
        : '0.00',
      totalVatInclBeforePromo: data.non_tobacco_products.undiscounted_totals.total_gross
        ? centPrecisionToString(
            data.non_tobacco_products.undiscounted_totals.total_gross.cent_amount,
            data.non_tobacco_products.undiscounted_totals.total_gross.fraction_digits,
            data.non_tobacco_products.undiscounted_totals.total_gross.currency_code,
          )
        : '0.00',
    }
    cart.nonTobaccoProducts = nonTobaccoProducts
  }

  if (data.tobacco_products && data.tobacco_products.line_items.length > 0) {
    const tobaccoProducts = {
      products: mapCartProducts(data.tobacco_products?.line_items, locale),
      total: data.tobacco_products.discounted_totals.total_gross
        ? centPrecisionToString(
            data.tobacco_products.discounted_totals.total_gross.cent_amount,
            data.tobacco_products.discounted_totals.total_gross.fraction_digits,
            data.tobacco_products.discounted_totals.total_gross.currency_code,
          )
        : '0.00',
      totalBeforePromo: data.tobacco_products.undiscounted_totals.total_gross
        ? centPrecisionToString(
            data.tobacco_products.undiscounted_totals.total_gross.cent_amount,
            data.tobacco_products.undiscounted_totals.total_gross.fraction_digits,
            data.tobacco_products.undiscounted_totals.total_gross.currency_code,
          )
        : '0.00',
    }
    cart.tobaccoProducts = tobaccoProducts
  }

  if (data.free_products) {
    const freeProductKeys = data.free_products?.map(product => product.sku ?? '') ?? []
    const productsResponse = await fetch(`/api/get-products-by-key?locale=${router.locale}`, {
      method: 'POST',
      body: JSON.stringify(freeProductKeys),
    })

    const freeProductsCommercetools: ProductListByKeysQuery['products']['results'] =
      await productsResponse.json()

    const freeProducts: CartPromoCardProps[] = freeProductsCommercetools.map(product => {
      return {
        label: product.masterData.current?.name ?? '',
        timesApplied: data.free_products?.find(freeProduct => freeProduct.sku === product.key)
          .quantity,
        image: product.masterData.current?.masterVariant.images?.[0]?.url ?? undefined,
        imageAlt: product.masterData.current?.masterVariant.images[0]?.label ?? undefined,
      }
    })

    cart.freeProducts = freeProducts
  }

  return cart
}

export const mapCartProducts = (
  lineItems?: ShoppingCartWithSubtotalsLineItem[],
  locale?: string,
) => {
  const cartItems: CartProductCardProps[] | undefined = lineItems?.map(lineItem => {
    const product: CartProductCardProps = {
      id: lineItem.product_key,
      brand: lineItem.product_attributes?.brand ?? '',
      productKey: lineItem.product_key ?? '',
      productName: lineItem.product_attributes?.name[locale ?? 'nl'] ?? '',
      categoryKey: lineItem.main_category,
      freeProductPromo: lineItem.has_free_product_promo_associated ?? false,
      linkedProducts: lineItem.linked_products?.map(product => {
        return {
          name: product.product_name[locale ?? 'nl'] ?? '',
          price: centPrecisionToString(
            product.totals.total_net.cent_amount,
            product.totals.total_net.fraction_digits,
            product.totals.total_net.currency_code,
          ),
        }
      }),
      slug: lineItem.slug?.[locale ?? 'nl'],
      image: lineItem.product_attributes?.images?.[0] ?? ProductImagePlaceHolder,
      imageAlt: lineItem.product_attributes.name ?? '',
      contentPerUnit: lineItem.product_attributes.content_per_unit ?? '',
      basePrice: lineItem.pricing.undiscounted_totals.total_net
        ? centPrecisionToString(
            lineItem.pricing.undiscounted_totals.total_net.cent_amount,
            lineItem.pricing.undiscounted_totals.total_net.fraction_digits,
            lineItem.pricing.undiscounted_totals.total_net.currency_code,
          )
        : centPrecisionToString(
            lineItem.pricing.discounted_totals.total_net.cent_amount,
            lineItem.pricing.discounted_totals.total_net.fraction_digits,
            lineItem.pricing.discounted_totals.total_net.currency_code,
          ),
      amount: lineItem.quantity,
    }

    //If reduced price exists and reduced price and regular price are not equal, add reduced price to product
    if (
      lineItem.pricing.undiscounted_totals.total_net &&
      lineItem.pricing.undiscounted_totals.total_net.cent_amount !==
        lineItem.pricing.discounted_totals.total_net.cent_amount
    ) {
      product.reducedPrice = centPrecisionToString(
        lineItem.pricing.discounted_totals.total_net.cent_amount,
        lineItem.pricing.discounted_totals.total_net.fraction_digits,
        lineItem.pricing.discounted_totals.total_net.currency_code,
      )
    }

    //If applied promotions exist, add the promotions to product
    /*
    if (lineItem.promotions) {
      product.appliedPromos = lineItem.promotions.map(promotion => {
        return {
          timesApplied: promotion.times_applied,
          promo: promotion.name,
        }
      })
    }
    */

    //If suggested promotions exist, add the promotion to product
    if (lineItem.promotion_suggestion) {
      product.suggestedPromoName = lineItem.promotion_suggestion.name
      product.suggestedPromoQuantity = lineItem.promotion_suggestion.quantity_required_to_reach
    }

    return product
  })

  return cartItems
}
