import type {CollapsibleCardProps} from '../../cards/CollapsibleCard'
import CollapsibleCard from '../../cards/CollapsibleCard'
import ComponentContainer from '../../containers/ComponentContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface CollapsibleListProps extends SbEditableProps {
  cards: CollapsibleCardProps[]
}

export const CollapsibleList = ({cards, sbEditable}: CollapsibleListProps) => {
  return (
    <div {...sbEditable}>
      <ComponentContainer type="large" className="2xl:w-[50vw]">
        <div className="flex flex-col pb-16 gap-y-2">
          {cards.map(card => {
            return (
              <CollapsibleCard
                key={card.id}
                id={card.id}
                description={card.description}
                subtitle={card.subtitle}
                title={card.title}
                openDefault={card.openDefault}
                CTA={card.CTA}
              />
            )
          })}
        </div>
      </ComponentContainer>
    </div>
  )
}

export default CollapsibleList
