import {Listbox} from '@headlessui/react'
import {useState} from 'react'
import styles from './DropdownFilter.module.css'

export type DropdownFilterProps = {
  title?: string
  name?: string
  // eslint-disable-next-line no-unused-vars
  onSelect: (selectId: string) => void
  selectables: {id: string | number; name: string}[]
  selectedId?: string | number
  className?: string
  fullWidth?: boolean
  invertColor?: boolean
}

const DropdownFilter = ({
  title,
  name,
  selectables,
  onSelect,
  selectedId,
  className = '',
  fullWidth,
  invertColor,
}: DropdownFilterProps) => {
  const [selectedItem, setSelectedItem] = useState(
    selectedId ? selectables.find(x => x.id === selectedId) : selectables[0],
  )

  const handleTransition = (item: {id: string; name: string}) => {
    setSelectedItem(item)
    onSelect(item.id)
  }

  // useEffect(() => {
  //   setSelectedItem(selectedId ? selectables.find(x => x.id === selectedId) : selectables[0])
  // }, [selectables, selectedId])

  return (
    <div className={fullWidth ? 'w-full' : ''}>
      <div className="w-full">
        <div className="relative w-full flex">
          {title ? <h4 className="g768:h5-like mb-8 mr-4 font-bold">{title}</h4> : null}
        </div>
        <Listbox
          value={selectedItem}
          onChange={handleTransition}
          as="div"
          className="relative flex-grow"
          name={name}
        >
          {({open}) => (
            <>
              <Listbox.Button
                className={`${className} ${
                  invertColor ? 'border-fixmerWhite' : 'border-fixmerBlue'
                }  truncate  ${
                  open ? 'border-t border-x rounded-t-3xl' : 'border rounded-3xl '
                } w-full`}
              >
                <div className="flex h-full w-full cursor-pointer items-center justify-between px-6 py-2">
                  <div className={`text-center ${invertColor ? 'text-white' : ''}`}>
                    {selectedItem?.name}
                  </div>
                  <div
                    className={`ml-2 h-4 w-4 bg-contain bg-center bg-no-repeat ${
                      invertColor ? 'brightness-0 invert' : ''
                    }  ${styles.dropdownIcon}`}
                  ></div>
                </div>
              </Listbox.Button>

              <Listbox.Options className="z-20 absolute border-fixmerBlue border-x border-b rounded-b-3xl bg-fixmerBackgroundGrey w-full cursor-pointer  overflow-hidden">
                <div className="overflow-y-auto max-h-60">
                  {selectables.map(item => (
                    <div
                      className="w-full hover:last:rounded-b-3xl hover:bg-fixmerLightGrey"
                      key={item.id}
                    >
                      <Listbox.Option
                        value={item}
                        className="py-4 text-[1rem] px-6 text-fixmerBlue "
                      >
                        {item.name}
                      </Listbox.Option>
                    </div>
                  ))}
                </div>
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </div>
    </div>
  )
}

export default DropdownFilter
