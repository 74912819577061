export interface LoadingDotsProps {
  className?: string
  light?: boolean
}

const LoadingDots = ({className, light}: LoadingDotsProps) => {
  const dotClassNames = `w-1 h-1 rounded-full animate-bounce ${
    light ? 'bg-white' : 'bg-fixmerBlue'
  }`

  return (
    <div className={` ${className} p-1 flex gap-1`}>
      <div className={dotClassNames} />
      <div className={dotClassNames} style={{animationDelay: '200ms'}} />
      <div className={dotClassNames} style={{animationDelay: '400ms'}} />
    </div>
  )
}

export default LoadingDots
