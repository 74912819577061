import {storyblokEditable} from '@storyblok/react'
import {useRouter} from 'next/router'
import type {ServiceCardListStoryblok} from '../../../storyblok-types/component-types-sb'
import ServiceCardList from '../../collection/ServiceCardList'

const ServiceCardListBlok = ({blok}: {blok: ServiceCardListStoryblok}) => {
  const {asPath} = useRouter()

  const inAccountPage = asPath.includes('/account/')

  const services = blok.service_cards.map(service => {
    return {
      id: service._uid,
      description: service.description,
      icon: service.pictogram?.filename,
      iconAlt: service.pictogram?.alt ?? '',
      button: {
        label: service.cta_label,
        path:
          service.cta_link?.url == ''
            ? `/${service.cta_link?.story?.url}`
            : service.cta_link?.linktype === 'email'
            ? `mailto:${service.cta_link?.url}`
            : service.cta_link?.url,
        openInNewWindow: service.cta_link?.target ? true : service.cta_link?.target ? true : false,
      },
    }
  })
  return (
    <ServiceCardList
      sbEditable={storyblokEditable(blok)}
      fitParent={inAccountPage}
      title={blok.title}
      services={services}
    />
  )
}

export default ServiceCardListBlok
