import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import type {StatusPageStoryblok} from '../../../storyblok-types/component-types-sb'

const StatusPage = ({blok}: StatusPageStoryblok) => (
  <main {...storyblokEditable(blok)}>
    {blok.body
      ? blok.body.map((blok: any) => <StoryblokComponent blok={blok} key={blok._uid} />)
      : null}
  </main>
)

export default StatusPage
