import Image from 'next/image'
import {useRef} from 'react'
import {useInViewport} from 'react-in-viewport'
import {gtmClickToCall, gtmClickToEmail} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import PreviewContainer from '../../containers/PreviewContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'
export interface MediaTextCardProps extends SbEditableProps {
  title?: string
  description?: JSX.Element
  image: string
  imageAlt?: string
  mediaPosition: '' | 'left' | 'right'
  bgStyle: '' | 'white' | 'grey'
  fitParent?: boolean
  CTA?: {label?: string; path: string; openInNewWindow?: boolean}
}

const MediaTextCard = ({
  title,
  description,
  image,
  imageAlt,
  mediaPosition,
  bgStyle,
  fitParent,
  CTA,
  sbEditable,
}: MediaTextCardProps) => {
  const myRef = useRef<HTMLDivElement>(null)
  const {inViewport} = useInViewport(myRef)
  return (
    <div
      className={`
        ${
          !fitParent ? 'w-screen' : 'w-full py-4 g768:py-6'
        } flex g768:justify-center items-center ${
        bgStyle === 'white'
          ? 'g768:bg-fixmerWhite first:rounded-t-3xl first:mt-4'
          : 'g768:bg-fixmerGrey'
      }`}
      {...sbEditable}
    >
      <ComponentContainer
        type={!fitParent ? 'large' : 'full'}
        noPadding={fitParent}
        className={` ${!fitParent ? '2xl:w-[60vw]' : ''}`}
      >
        <PreviewContainer mediaTextContainer title={title ?? ''}>
          <div
            ref={myRef}
            className={`rounded-xl transition-all duration-500 ${
              inViewport ? 'translate-y-0 opacity-100' : 'translate-y-32 opacity-0'
            } ${
              mediaPosition === 'left' ? 'g768:flex-row' : 'g768:flex-row-reverse'
            } flex flex-col g768:justify-center min-h-[20rem] h-fit g768:flex-row w-full overflow-hidden`}
          >
            <div className="g768:rounded-3xl w-full g768:w-fit overflow-hidden mr-8">
              <div className="relative w-full g768:w-[30vw] g768:h-full h-64">
                <Image src={image} alt={imageAlt ?? ''} fill className="object-cover" />
              </div>
            </div>
            <div
              className={` p-5 g768:flex-col flex g768:justify-center  g768:w-[40vw] ${
                bgStyle === 'white'
                  ? 'bg-fixmerWhite text-fixmerBlue'
                  : 'bg-fixmerGrey text-fixmerWhite'
              }`}
            >
              <div>
                <h2 className="hidden g768:block mb-6">{title}</h2>
                {description}
                {CTA && CTA.path && CTA.label ? (
                  <Button
                    className="mt-4 w-fit"
                    openInNewWindow={CTA.openInNewWindow}
                    link={CTA.path}
                    label={CTA.label}
                    inverse={bgStyle !== 'white'}
                    styling={`${bgStyle === 'white' ? 'primary' : 'secondary'}`}
                    onClick={() => {
                      const path = CTA.path
                      if (path?.startsWith('tel:')) {
                        gtmClickToCall(path.replace(/tel:/g, ''))
                      } else if (path?.startsWith('mailto:')) {
                        gtmClickToEmail(path.replace(/mailto:/g, ''))
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </PreviewContainer>
      </ComponentContainer>
    </div>
  )
}

export default MediaTextCard
