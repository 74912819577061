import Image from 'next/image'
import Link from 'next/link'
import {gtmClickCarrouselSmall, gtmClickFullWidthBanner} from '../../../lib/gtm-events'

export interface HighLightCardProps {
  id: number | string
  image?: string
  imageAlt?: string
  backgroundImage?: string
  backgroundImageMobile?: string
  backgroundColor: string
  label?: string
  title?: string
  description?: string
  path: string
  openInNewTab?: boolean
  promoLabel?: string
  fullWidthBanner?: boolean
}

export const HighlightCard = ({
  image,
  imageAlt,
  backgroundImage,
  backgroundImageMobile,
  backgroundColor,
  label,
  title,
  description,
  path,
  openInNewTab,
  promoLabel,
  fullWidthBanner,
}: HighLightCardProps) => {
  return (
    <div
      onClick={() => {
        //Handle gtm promo events
        if (fullWidthBanner) {
          gtmClickFullWidthBanner(path)
        } else {
          gtmClickCarrouselSmall(title ?? 'promo', path)
        }
      }}
      style={{backgroundColor: backgroundColor}}
      className="w-full h-full relative"
    >
      <div className="w-full h-full absolute">
        {backgroundImage ? (
          <div className="relative h-full w-full hidden g640:block">
            <Image src={backgroundImage} alt={imageAlt ?? ''} fill className="object-contain" />
          </div>
        ) : null}
        {backgroundImageMobile ? (
          <div className="relative h-full w-full g640:hidden">
            <Image
              src={backgroundImageMobile}
              alt={imageAlt ?? ''}
              fill
              className="object-contain"
            />
          </div>
        ) : null}
      </div>
      <Link
        href={path}
        target={openInNewTab ? '_blank' : ''}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        className="w-full g640:py-12 g640:pl-12 p-8 overflow-hidden absolute z-10 h-full flex g640:flex-row flex-col justify-between items-center"
      >
        <div className="flex-col h-full g640:w-1/2 w-full text-fixmerWhite items-center g640:items-start g640:justify-between flex">
          {label ? <p className="border rounded-full px-4 w-fit py-2">{label}</p> : <div></div>}
          <div className="flex flex-col mt-4 gap-4">
            <h3 className="h1-like g768:h2-like g1024:h1-like text-center g640:text-start">
              {title}
            </h3>
            <p className="text-center g640:text-start">{description}</p>
          </div>
        </div>
        {image ? (
          <div className="relative g640:w-1/2 flex justify-center w-full h-full min-h-[50%] mt-4 mb-12 g640:mb-0">
            <Image src={image} alt={imageAlt ?? ''} fill className="object-contain" />
          </div>
        ) : null}
        {promoLabel ? (
          <div className="g1440:w-64  w-32 z-20 flex g1440:items-end items-start justify-start rounded-full g1440:h-64 h-32 bg-fixmerRed absolute g1440:-top-24 -bottom-2 -right-6">
            <div className="text-white flex justify-center items-center h2-like w-3/4 h-4/5 g1440:ml-8 ml-2 mb-0">
              <p className="g1440:h2-like h3-like text-center">{promoLabel}</p>
            </div>
          </div>
        ) : null}
      </Link>
    </div>
  )
}

export default HighlightCard
