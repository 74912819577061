import {useTranslation} from 'next-i18next'
import {Dispatch, SetStateAction} from 'react'
import Button from '../../buttons/Button'
import CollapsibleInvoiceCard, {
  CollapsibleInvoiceCardProps,
} from '../../cards/CollapsibleInvoiceCard'
import LoadingSpinner from '../../components/LoadingSpinner'
import DropdownFilter from '../../filters/DropdownFilter'

export interface InvoiceListProps {
  title: string
  years: {id: string | number; name: string}[]
  invoices: CollapsibleInvoiceCardProps[]
  hasMore: boolean
  loadMore: () => void
  isLoading?: boolean
  setYear: Dispatch<SetStateAction<string>>
}

const InvoiceList = ({
  title,
  years,
  invoices,
  hasMore,
  loadMore,
  isLoading,
  setYear,
}: InvoiceListProps) => {
  const {t} = useTranslation()

  const selectYear = (selectId: string) => {
    const year = years.find(year => year.id === selectId)?.name
    setYear(year ?? years[0].name)
  }

  return (
    <div className="w-full">
      <div className="flex gap-4 justify-between g768:justify-start w-full">
        <h2>{title}</h2>
        {years && years.length > 0 ? (
          <DropdownFilter selectables={years} onSelect={selectYear} />
        ) : null}
      </div>
      <div className="mt-8 flex-col flex gap-3">
        {invoices?.map(order => {
          return <CollapsibleInvoiceCard key={order.id} {...order} />
        })}
      </div>
      {isLoading ? (
        <div className="flex justify-center mt-4">
          <LoadingSpinner centerLoader={false} />
        </div>
      ) : null}
      {hasMore && !isLoading ? (
        <div className="flex justify-center w-full mt-12">
          <Button onClick={() => loadMore?.()} styling="primary" label={t('general.moreResults')} />
        </div>
      ) : null}
    </div>
  )
}

export default InvoiceList
