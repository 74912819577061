import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import Price from '../../components/Price'
import useProductPrices from '../../../lib/hooks/use-product-prices'
import {centPrecision, centPrecisionToString} from '../../../lib/commercetools/ct-utilities'
import LoadingDots from '../../components/LoadingDots'
import classnames from 'classnames'
import useProductsInLists from '../../../lib/hooks/use-products-in-list'
import {useContext} from 'react'
import {CartContext} from '../../../lib/context/cart-context'
import {getPriceFromString, gtmHandleProduct} from '../../../lib/gtm-events'
import useUser from '../../../lib/hooks/use-user'
import {useRouter} from 'next/router'
import {ModalContext} from '../../../lib/context/modal-context'
import classNames from 'classnames'
import AddToCartButton from '../../components/AddToCartButton'

export interface ListProductCardProps {
  id: number | string
  image?: string
  brand: string
  productName: string
  slug?: string
  hidePrice?: boolean
  contentPerUnit: string
  quantity?: number
  price?: string // Fetched if not provided
  notAvailable?: boolean // Set if price not provided and not available
  highlight?: boolean
  favorited?: boolean // Fetched if not provided
  amountInCart?: number // Fetched if not provided
  path?: string
  category?: string
  buyOptions?: boolean
}

export const ListProductCard = ({
  id,
  image,
  brand,
  productName,
  contentPerUnit,
  hidePrice,
  quantity,
  slug,
  price: propsPrice,
  notAvailable: propsNotAvailable,
  highlight,
  favorited,
  category,
  amountInCart,
  buyOptions,
}: ListProductCardProps) => {
  const {t} = useTranslation()
  const router = useRouter()

  const {productSlugsInLists} = useProductsInLists()

  const {prices, keysLoading} = useProductPrices(propsPrice ? [] : [id.toString()])

  const {cartProductQuantities, cartAddProduct, cartRemoveProduct, cartChangeQuantity} =
    useContext(CartContext)

  const {user} = useUser()

  // Pricing
  const productPrice = prices?.[id]?.price

  const priceString = productPrice
    ? centPrecisionToString(
        productPrice.cent_amount,
        productPrice.fraction_digits,
        productPrice.currency_code,
      )
    : ''

  const isPriceLoading = keysLoading?.includes(id.toString())

  const price = propsPrice ? propsPrice : priceString
  const notAvailable = propsNotAvailable ?? (!isPriceLoading && !price)

  //Classnames
  const favoriteIcon = classNames(
    'w-6 h-6 z-10 cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out',
  )

  const wrapperClasses = classnames(
    'w-full g768:flex justify-between px-4 py-2 bg-fixmerWhite scroll-mt-64 g420:scroll-mt-56 g1024:scroll-mt-[16rem] 2xl:scroll-mt-[12vw]',
    {
      'border-4 border-fixmerYellow product-card-highlight border-collapse': highlight, // cart-item-highlight to support scrolling to matches
      'border-b last:border-b-0 border-fixmerLightGray': !highlight,
    },
  )

  const buttonWrapper = classNames('m-auto w-full flex')

  // Favorited in lists
  const isFavorited = favorited || (slug ? productSlugsInLists.includes(slug) : false)

  // Amount in cart
  const amount = amountInCart ?? cartProductQuantities[id]?.quantity ?? 0

  // Handlers
  const handleGTM = (event: 'add_to_cart' | 'remove_from_cart', quantity?: number) => {
    const priceNumber = getPriceFromString(price)

    gtmHandleProduct(
      event,
      priceNumber,
      id as string,
      productName,
      brand ?? '',
      category ?? '',
      quantity ?? 1,
    )
  }

  const handleAddToCart = async () => {
    await cartAddProduct(id as string, category as string)
    handleGTM('add_to_cart')
  }
  const handleRemoveProduct = async () => {
    await cartRemoveProduct(id as string)
    handleGTM('remove_from_cart')
  }

  const handleChangeAmount = async (quantity: number) => {
    const previousAmount = cartProductQuantities[id].quantity
    await cartChangeQuantity(id as string, quantity)

    if (previousAmount < quantity) {
      handleGTM('add_to_cart', quantity - previousAmount)
    } else {
      handleGTM('remove_from_cart', previousAmount - quantity)
    }
  }

  const handleFavoriteClick = () => {
    if (user.activeBU) {
      showListModal()
    } else {
      router.push(`/login?url=${router.asPath}`)
    }
  }

  // Modal
  const {addProduct, showModal} = useContext(ModalContext)

  const showListModal = () => {
    showModal()
    addProduct({
      id,
      image,
      imageAlt: '',
      brand,
      productName,
      category,
      price: centPrecision(productPrice?.cent_amount!, productPrice?.fraction_digits!),
      contentPerUnit,
    })
  }

  return (
    <div className={wrapperClasses} scroll-id={id}>
      <Link
        href={slug ? `/${t('general.products')}/${slug}` : '#'}
        className="w-full g768:flex justify-between items-center bg-fixmerWhite"
      >
        <div className="flex w-full">
          <div className="min-w-[2.5rem] w-10 h-10 cartImage g768:h-16 g768:w-16 relative mr-2 g768:mr-6">
            <Image
              src={image ?? '/assets/product-placeholder.png'}
              alt={productName}
              fill
              className="object-contain"
            />
          </div>
          <div className="flex g768:items-center items-start g768:flex-row flex-col  w-full g768:w-fit gap-2">
            <div
              className={`flex gap-2 flex-wrap g768:items-center g768:flex-row flex-col w-full g768:w-fit`}
            >
              <div
                className={`flex g768:items-center items-start ${
                  notAvailable ? 'opacity-25' : 'opacity-100'
                } flex-wrap gap-2`}
              >
                <h3 className="g1280:h5-like text-balance">
                  <span className="font-normal mr-1">{brand}</span>
                  {productName}
                </h3>
              </div>
              <div className="flex items-center justify-between mb-2 g768:mb-0 gap-2">
                <div
                  className={`flex flex-wrap gap-2 ${
                    !contentPerUnit ? 'justify-end' : 'justify-between'
                  } items-center`}
                >
                  <div className="flex items-center gap-4">
                    {contentPerUnit && contentPerUnit ? (
                      <p
                        className={`border-fixmerYellow flex items-center w-fit border text-fixmerBlue py-1 px-2 rounded-full ${
                          notAvailable ? 'opacity-25' : 'opacity-100'
                        }`}
                      >
                        <span>{contentPerUnit}</span>
                      </p>
                    ) : null}
                    <p className="mr-[4vw]">{id}</p>
                  </div>

                  {notAvailable ? (
                    <p className="border-fixmerRed flex items-center w-fit border   text-fixmerRed py-1 px-2 rounded-full">
                      <span> {t('order.notAvailable')} </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="flex w-full g768:w-fit pl-12 justify-end items-center gap-6">
        <div className="flex gap-6 items-center">
          {quantity && !buyOptions ? (
            <div className=" gap-y-4 relative z-20 min-w-fit items-center justify-between g768:justify-start">
              <p
                className={`bg-fixmerBackgroundGrey rounded-full py-[0.3rem] font-bold px-[0.7rem] ${
                  notAvailable ? 'opacity-25' : ''
                }`}
              >
                {quantity}
              </p>
            </div>
          ) : null}

          {!hidePrice ? (
            <div className="g768:w-[10vw] g768:min-w-[6rem] g768:max-w-[8rem] w-fit flex justify-end">
              {isPriceLoading && <LoadingDots />}
              {!isPriceLoading && price && <Price price={price} styling="small" />}
            </div>
          ) : null}
        </div>
        {buyOptions ? (
          <div className="flex gap-6 items-center">
            <button onClick={handleFavoriteClick} aria-label="favorite" className={favoriteIcon}>
              {isFavorited ? (
                <div className="w-full h-full">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 9.52897C22 11.0754 21.4062 12.5608 20.3458 13.6596C17.9049 16.1897 15.5374 18.828 13.0053 21.2664C12.4249 21.8172 11.5042 21.7971 10.9488 21.2214L3.65376 13.6596C1.44875 11.3739 1.44875 7.68398 3.65376 5.39832C5.88044 3.0902 9.50794 3.0902 11.7346 5.39832L11.9998 5.67317L12.2648 5.39848C13.3324 4.29125 14.7864 3.66675 16.3053 3.66675C17.8242 3.66675 19.2781 4.29119 20.3458 5.39832C21.4063 6.4972 22 7.98252 22 9.52897Z"
                      fill="#FDB94A"
                      stroke="#FDB94A"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              ) : (
                <div className="w-full h-full">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 9.52897C22 11.0754 21.4062 12.5608 20.3458 13.6596C17.9049 16.1897 15.5374 18.828 13.0053 21.2664C12.4249 21.8172 11.5042 21.7971 10.9488 21.2214L3.65376 13.6596C1.44875 11.3739 1.44875 7.68398 3.65376 5.39832C5.88044 3.0902 9.50794 3.0902 11.7346 5.39832L11.9998 5.67317L12.2648 5.39848C13.3324 4.29125 14.7864 3.66675 16.3053 3.66675C17.8242 3.66675 19.2781 4.29119 20.3458 5.39832C21.4063 6.4972 22 7.98252 22 9.52897Z"
                      stroke="#2B2E42"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </button>
            <div className="w-fit">
              <AddToCartButton
                amount={amount}
                className={buttonWrapper}
                addToCart={handleAddToCart}
                removeFromCart={handleRemoveProduct}
                changeQuantity={handleChangeAmount}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ListProductCard
