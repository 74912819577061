import classNames from 'classnames'
import {useRef} from 'react'
import {useInViewport} from 'react-in-viewport'
import type {USPCardProps} from '../../cards/USPCard'
import USPCard from '../../cards/USPCard'
import ComponentContainer from '../../containers/ComponentContainer'
import PreviewContainer from '../../containers/PreviewContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface USPListProps extends SbEditableProps {
  title: string
  path?: string
  cards: USPCardProps[]
  onAccountDetail?: boolean
}

const USPList = ({title, path, cards, onAccountDetail, sbEditable}: USPListProps) => {
  const myRef = useRef<HTMLDivElement>(null)
  const {inViewport} = useInViewport(myRef)

  const uspWrapperClasses = classNames('w-full', {
    'g1024:w-1/5': cards.length === 5,
    'g1024:w-1/4': cards.length === 4 || cards.length > 5,
    'g1024:w-1/3': cards.length === 3,
    'g1024:w-1/2': cards.length === 2,
    'g1024:w-full': cards.length === 1,
    'g1024:min-w-[50%]': onAccountDetail,
  })

  return (
    <div {...sbEditable}>
      <ComponentContainer
        noPadding={onAccountDetail}
        className={onAccountDetail ? 'py-4 g768:py-8' : ''}
        type={onAccountDetail ? 'full' : 'large'}
      >
        <PreviewContainer path={path} title={title}>
          <div
            ref={myRef}
            className={`flex ${
              inViewport ? 'translate-y-0 opacity-100' : 'translate-y-32 opacity-0'
            } flex-col gap-y-4 g768:flex-row flex-wrap transition-all ease-in-out duration-500 justify-center g768:justify-start bg-fixmerWhite rounded-2xl g768:py-4`}
          >
            {cards.map((card, index) => {
              return (
                <div key={card.id} className={`${uspWrapperClasses}`}>
                  <USPCard
                    id={card.id}
                    title={card.title}
                    image={card.image}
                    imageAlt={card.imageAlt}
                    onAccountDetail={onAccountDetail}
                    description={card.description}
                    lastCard={index === cards.length - 1}
                  ></USPCard>
                </div>
              )
            })}
          </div>
        </PreviewContainer>
      </ComponentContainer>
    </div>
  )
}

export default USPList
