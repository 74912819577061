import {storyblokEditable} from '@storyblok/js'
import dynamic from 'next/dynamic'
import type {StatusWithSearchStoryblok} from '../../../storyblok-types/component-types-sb'
import {ProductOverviewCategoryCard} from '../../../lib/page-props'

const StatusWithSearch = dynamic(() => import('../../components/StatusWithSearch'))

const StatusWithSearchBlok = ({
  blok,
  categoryCards,
}: {
  blok: StatusWithSearchStoryblok
  categoryCards: ProductOverviewCategoryCard[]
}) => {
  return (
    <StatusWithSearch
      largeText={blok.large_text}
      smallText={blok.small_text}
      categoryCards={categoryCards}
      sbEditable={storyblokEditable(blok)}
    ></StatusWithSearch>
  )
}

export default StatusWithSearchBlok
