import type {AdvertisementCardProps} from '../components/cards/AdvertismentCard'
import type {ProductCardProps} from '../components/cards/ProductCard'
import type {ProductListProps} from '../components/collection/ProductList'
import {pathTranslations} from '../path-translations'
import {
  default as ProductImagePlaceHolder,
  default as PromoImagePlaceholder,
} from '../public/assets/placeholder.svg'
import type {
  AlgoliaHit,
  AlgoliaProductHit,
  AlgoliaProductRetailPrice,
  AlgoliaPromotionGroupHit,
} from './algolia/algolia-types'
import {centPrecisionToString} from './commercetools/ct-utilities'

export const selectPrice = (prices: AlgoliaProductRetailPrice[]) => {
  if (!prices) {
    return undefined
  }

  const now = Date.now()

  return prices.find(price => {
    const isAfterFrom = now >= new Date(price.valid_from).getTime()
    const isBeforeUntil = price.valid_until.startsWith('0001')
      ? true
      : now < new Date(price.valid_until).getTime()
    return isAfterFrom && isBeforeUntil
  })
}

export const mapProductData = (
  data: AlgoliaHit[],
  locale: string,
  businessUnitKey?: string,
  customerGroup?: string,
) => {
  const products: ProductListProps['cards'] = []

  data?.forEach(hit => {
    if (hit.content_type === 'product') {
      products.push(mapProduct(hit as AlgoliaProductHit, locale, businessUnitKey, customerGroup))
    } else if (hit.content_type === 'promotiongroup') {
      products.push(mapPromotionGroup(hit as AlgoliaPromotionGroupHit))
    }
  })

  return products
}

export const mapProduct = (
  product: AlgoliaProductHit,
  locale: string,
  businessUnitKey?: string,
  customerGroup?: string,
): ProductCardProps => {
  const pricePerUnit = selectPrice(product.algolia_recommended_retail_prices)

  const now = Date.now()

  const filteredPromotions = product.promotions?.filter(promo => {
    const channels = promo.customer_target?.customer_channels
    const customers = promo.customer_target?.customer

    // Check valid_from and valid_until

    if (!promo.valid_from && !promo.valid_until) {
      return true
    }

    const isAfterFrom = now >= new Date(promo.valid_from).getTime()
    const isBeforeUntil = promo.valid_until.startsWith('0001')
      ? true
      : now < new Date(promo.valid_until).getTime()
    const isValid = isAfterFrom && isBeforeUntil

    if (!isValid) {
      return false
    }

    // If channels defined, it must contain the user's customer group
    if (customerGroup && channels && channels.length > 0) {
      return channels.includes(customerGroup)
    }

    // If customers defined, it must contain the user's business unit key
    if (businessUnitKey && customers && customers.length > 0) {
      return customers.includes(businessUnitKey)
    }

    // If both channel and customers are not defined, promo should be visible
    return true
  })

  return {
    type: product.content_type,
    id: product.id,
    slug: product.slug,
    path: `${pathTranslations.products[locale]}/${product.full_slug ?? product.slug} ` ?? `/`,
    productName: product.name,
    brand: product.brand,
    category: product.marketing_product_category?.level_2?.[0]?.key,
    horizontal: true,
    // Flags
    bestSeller: product.qualification_flags.bestseller,
    newProduct: product.qualification_flags.new,
    // Image
    image: product.thumbnail_image[0] ?? ProductImagePlaceHolder.src,
    imageAlt: product.name,
    // Quantity
    contentPerUnit: product.content_per_unit,
    // Promos
    promos: filteredPromotions
      ?.map(promo => {
        return {
          label: promo.label,
          url: promo.slug,
        }
      })
      .filter((value, index, array) => array.indexOf(value) === index),
    // Prices
    pricePerUnit: pricePerUnit
      ? centPrecisionToString(
          pricePerUnit.price_incl_vat.cent_amount,
          pricePerUnit.price_incl_vat.fraction_digits,
          pricePerUnit.price_incl_vat.currency_code,
        )
      : '',
    // Base price and reduced priced are handled in ProductCard
    // Account related stuff is handled in ProductCard
  }
}

const mapPromotionGroup = (promotionGroup: AlgoliaPromotionGroupHit): AdvertisementCardProps => {
  const imgKeys = promotionGroup.image ? Object.keys(promotionGroup.image) : []

  const imageKey =
    imgKeys.find(key => /w350/gi.test(key)) ||
    imgKeys.find(key => /origin/gi.test(key)) ||
    imgKeys[0]

  const image = imageKey ? promotionGroup.image[imageKey] : PromoImagePlaceholder.src

  return {
    type: promotionGroup.content_type,
    id: promotionGroup.id,
    image: image,
    path: promotionGroup.slug,
    description: promotionGroup.description,
    name: promotionGroup.name,
    imageAlt: promotionGroup.name,
    backgroundDark: true,
    label: promotionGroup.promotion_labels[0], // TODO: can be more labels?
  }
}
