import {MultilinkStoryblok} from '../../storyblok-types/component-types-sb'

export interface UrlProps {
  url: string
  openInNewTab?: boolean
  anchor?: string
}

export const getUrlProps = (link: MultilinkStoryblok | undefined): UrlProps => {
  if (!link) {
    return {url: ''}
  }

  const openInNewTab = link.target === '_blank'

  if (link.linktype === 'story' && link.story) {
    if (link.story.slug === 'home') {
      return {
        url: '/',
        openInNewTab,
      }
    }
    return {
      url: `/${link.story.full_slug}${link.anchor ? `#${link.anchor}` : ''}`,
      openInNewTab,
    }
  }

  return {
    url: `${link.cached_url ?? link.url}${link.anchor ? `#${link.anchor}` : ''}`,
    openInNewTab: link.target === '_blank',
  }
}
