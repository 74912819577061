import {useRouter} from 'next/router'
import type {HighlightCarouselStoryblok} from '../../../storyblok-types/component-types-sb'
import CTACard from '../../cards/CTACard'
import PDFTeaserCard from '../../cards/PDFTeaserCard'
import HighlightCarousel from '../../collection/HighlightCarousel'
import ComponentContainer from '../../containers/ComponentContainer'
import {pathTranslations} from '../../../path-translations'
import {getUrlProps} from '../../../lib/storyblok/storyblok-helpers'

const HighlightCarouselBlok = ({blok}: {blok: HighlightCarouselStoryblok}) => {
  const router = useRouter()

  const highlightCards = blok.carousel_cards.filter(filterValidFromUntil).map((card, index) => {
    let path = ''
    let openInNewTab = false

    if (card.item_link) {
      const slugArray: string[] = []
      if (card.item_link.type === 'product') {
        slugArray.push(pathTranslations.products[router.locale!]!)
      } else if (card.item_link.type === 'promo') {
        slugArray.push(pathTranslations.promotions[router.locale!]!)
      }

      slugArray.push(card.item_link.slugAllLocales[router.locale!]!)

      path = slugArray.join('/')
    } else if (card.item_external_link) {
      const urlProps = getUrlProps(card.item_external_link)

      path = urlProps.url
      openInNewTab = urlProps.openInNewTab ?? false
    }

    return {
      id: index,
      image: card.image?.filename ?? undefined,
      backgroundImage: card.image_full?.filename ?? undefined,
      backgroundImageMobile: card.image_full_mobile?.filename ?? undefined,
      imageAlt: card.image ? card.image.name : card.image_full ? card.image_full.name : undefined,
      backgroundColor: card.background_color.color,
      label: card.label ?? undefined,
      title: card.title,
      description: card.description ?? undefined,
      path,
      openInNewTab,
      promoLabel: card.promo_label,
    }
  })

  const callToAction = blok.call_to_action?.filter(filterValidFromUntil)[0]

  let ctaButton = undefined

  if (callToAction?.component === 'cta_card') {
    ctaButton = {
      label: callToAction.button_label,
      path:
        callToAction.button.url == ''
          ? `/${callToAction.button.story?.url}`
          : callToAction.button.linktype === 'email'
          ? `mailto:${callToAction.button.url}`
          : callToAction.button.url,
      openInNewWindow: callToAction.button.target
        ? true
        : callToAction.button.target
        ? true
        : false,
    }
  }

  return (
    <div className="highlightCarrousel">
      <ComponentContainer type="large">
        <div className="g768:w-full h-full gap-8 flex g768:flex-row flex-col justify-between m-auto">
          <div
            className={`w-full aspect-[2/3] ${
              blok.call_to_action && blok.call_to_action.length > 0
                ? 'g768:w-[65%] g640:aspect-[8/3]'
                : 'g768:w-full g640:aspect-[16/3]'
            }`}
          >
            <HighlightCarousel
              timeToTransition={
                blok.slide_duration_transition ? +blok.slide_duration_transition : undefined
              }
              cards={highlightCards}
              fullWidthBanner={!blok.call_to_action || blok.call_to_action.length <= 0}
            />
          </div>
          {blok.call_to_action && blok.call_to_action.length > 0 ? (
            <div className="g768:w-[35%] g768:h-full h-fit w-full bg-fixmerWhite rounded-2xl">
              {callToAction?.component === 'cta_card' ? (
                <CTACard
                  title={callToAction.title ?? ''}
                  imageAlt={callToAction.image.name}
                  image={callToAction.image.filename}
                  button={ctaButton}
                  backgroundColor={callToAction.background_color.color}
                ></CTACard>
              ) : callToAction?.component === 'pdf_teaser' ? (
                <PDFTeaserCard
                  title={callToAction.title}
                  teaser={callToAction.teaser.filename}
                  teaserAlt={callToAction.teaser.alt ?? ''}
                  path={
                    callToAction.link?.url == ''
                      ? `/${callToAction.link?.story?.url}`
                      : callToAction.link?.linktype === 'email'
                      ? `mailto:${callToAction.link?.url}`
                      : callToAction.link?.url
                  }
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </ComponentContainer>
    </div>
  )
}

const filterValidFromUntil = (item: {valid_from?: string; valid_until?: string}) => {
  const validFromOk = item.valid_from ? new Date(item.valid_from).getTime() <= Date.now() : true
  const validUntilOk = item.valid_until ? new Date(item.valid_until).getTime() >= Date.now() : true

  return validFromOk && validUntilOk
}

export default HighlightCarouselBlok
