import {storyblokEditable} from '@storyblok/js'
import type {CarouselStoryblok} from '../../../storyblok-types/component-types-sb'
import PromoList from '../../collection/PromoList'

const CarouselListBlok = ({blok}: {blok: CarouselStoryblok}) => {
  const cards = blok.carousel_item.map(card => {
    return {
      id: card._uid,
      description: card.title,
      image: card.image?.filename,
      imageAlt: card.image?.alt ?? '',
      label: card.label,
      link:
        card.cta_link?.url === ''
          ? `/${card.cta_link?.story?.url}`
          : card.cta_link?.linktype === 'email'
          ? `mailto:${card.cta_link?.url}`
          : card.cta_link?.url ?? '',
      openInNewWindow: card.cta_link?.target ? true : card.cta_link?.target ? true : false,
      imageFill: true
    }
  })

  const seeAllLink =
    blok.see_all_button_link?.url && blok.see_all_button_link?.url !== ''
      ? blok.see_all_button_link?.url
      : blok.see_all_button_link?.story?.url && blok.see_all_button_link?.story?.url !== ''
      ? blok.see_all_button_link?.story?.url
      : ''

  return (
    <PromoList
      sbEditable={storyblokEditable(blok)}
      title={blok.title}
      seeAllPath={seeAllLink}
      cards={cards}
    />
  )
}

export default CarouselListBlok
