import {useTranslation} from 'next-i18next'
import useUser from '../../../lib/hooks/use-user'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import {SbEditableProps} from '../../storyblok/blok-props'

export interface PersonalMessageCardProps extends SbEditableProps {
  message: JSX.Element
  CTA?: {label?: string; path?: string; openInNewWindow?: boolean}
}

const PersonalMessageCard = ({message, CTA, sbEditable}: PersonalMessageCardProps) => {
  const {user} = useUser()
  const {t} = useTranslation()

  return (
    <div {...sbEditable}>
      <ComponentContainer type="large" noPadding className="py-6 g768:py-12">
        <div className="g768:bg-fixmerWhite flex flex-col gap-4 p-6 rounded-2xl">
          <h2 className="g768:h3-like">{t('confirmation.greeting', {user: user.firstName})}</h2>
          {message}
          {CTA && CTA.path && CTA.label ? (
            <Button
              className="w-fit"
              openInNewWindow={CTA.openInNewWindow}
              link={CTA.path}
              label={CTA.label}
              styling="secondary"
            />
          ) : null}
        </div>
      </ComponentContainer>
    </div>
  )
}

export default PersonalMessageCard
