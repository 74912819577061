import {ListProductCardProps} from '../components/cards/ListProductCard'
import {CategoryNamesQuery, ProductListByKeysQuery} from '../gql-types'
import fetchCtData from './commercetools/fetch-ct-data'
import {QueryType} from './commercetools/query-types'
import {mapCtProductsToCard} from './map-commercetools-products-to-card'
import {sortProductsByCategory} from './sort-product-cards-by-category'

export const getAndDivideCommercetoolsProductsFromKeys = async (
  keys: string[],
  locale: string,
  productsWithQuantities?: {id: string; quantity: string}[],
) => {
  if (productsWithQuantities) {
    // Safeguard for incorrect quantities
    productsWithQuantities = productsWithQuantities.filter(product => +product.quantity > 0)
  }

  if (keys.length == 0) {
    return {
      tobaccoProducts: [],
      nonTobaccoProducts: [],
      tobaccoProductsSortedByCategory: {},
      nonTobaccoProductsSortedByCategory: {},
    }
  }

  //Get the full product information for all products from commercetools (filter out undefined products)
  const response = await fetch(`/api/get-products-by-key?locale=${locale}`, {
    method: 'POST',
    body: JSON.stringify(keys),
  })

  const productsCommercetools: ProductListByKeysQuery['products']['results'] = await response.json()

  // Get all the category keys within the list (1 subcategory deep)
  const categoryKeys = [
    ...new Set(
      productsCommercetools
        .map(product => {
          const key = product.masterData.current?.categories?.[0]?.key
          if (key) {
            const parts = key.split('-')
            return `${parts[0]}-${parts[1]}`
          }
          return null
        })
        .filter(key => key !== null),
    ),
  ]

  // Get the categories from commercetools with name.
  const responseCategories = await fetchCtData<CategoryNamesQuery>(QueryType.categoryNamesByKeys, {
    locale: locale!,
    keys: `${categoryKeys.map(key => `\"${key}\"`).join(',')}`,
  })

  const categoriesCT = responseCategories.categories.results

  //Seperate tobacco product and non tobacco products
  const tobaccoProducts = productsCommercetools.filter(product =>
    product.masterData.current?.categories?.[0]?.key?.includes('C10'),
  )

  //Array with all tobacco products
  const tobaccoProductsMapped: ListProductCardProps[] = mapCtProductsToCard(
    tobaccoProducts,
    productsWithQuantities,
  )

  //Object with all tobacco products divided by category
  const tobaccoProductsSortedByCategory: {
    [key: string]: ListProductCardProps[] | undefined
  } = sortProductsByCategory(tobaccoProductsMapped, categoriesCT)

  const nonTobaccoProducts = productsCommercetools.filter(
    product => !product.masterData.current?.categories?.[0]?.key?.includes('C10'),
  )

  const nonTobaccoProductsMapped: ListProductCardProps[] = mapCtProductsToCard(
    nonTobaccoProducts,
    productsWithQuantities,
  )

  //Object with all tobacco products divided by category
  const nonTobaccoProductsSortedByCategory: {
    [key: string]: ListProductCardProps[] | undefined
  } = sortProductsByCategory(nonTobaccoProductsMapped, categoriesCT)

  return {
    tobaccoProducts: tobaccoProductsMapped,
    nonTobaccoProducts: nonTobaccoProductsMapped,
    tobaccoProductsSortedByCategory,
    nonTobaccoProductsSortedByCategory,
  }
}
