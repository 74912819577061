import {storyblokEditable} from '@storyblok/js'
import {useRouter} from 'next/router'
import type {MediaTextStoryblok} from '../../../storyblok-types/component-types-sb'
import MediaTextCard from '../../cards/MediaTextCard'

import RichText from '../../RichText'

const MediaTextBlok = ({blok}: {blok: MediaTextStoryblok; fitParent?: string}) => {
  const {asPath} = useRouter()

  const inAccountPage = asPath.includes('/account/')

  const button = {
    label: blok.cta_label,
    path:
      blok.cta_link?.url == ''
        ? `/${blok.cta_link?.story?.url}`
        : blok.cta_link?.linktype === 'email'
        ? `mailto:${blok.cta_link?.url}`
        : blok.cta_link?.url,
    openInNewWindow: blok.cta_link?.target ? true : blok.cta_link?.target ? true : false,
  }
  return (
    <MediaTextCard
      sbEditable={storyblokEditable(blok)}
      title={blok.title}
      description={<RichText richText={blok.description} />}
      image={blok.image?.filename ?? ''}
      imageAlt={blok.image?.alt}
      mediaPosition={blok.position[0]}
      bgStyle={blok.style[0]}
      fitParent={inAccountPage}
      CTA={button}
    />
  )
}

export default MediaTextBlok
