import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface StatusProps extends SbEditableProps {
  title: string
  subtitle?: string
  description?: string
  image?: string
  imageAlt?: string
  buttonLabel?: string
}

const Status = ({
  title,
  subtitle,
  description,
  image,
  imageAlt,
  sbEditable,
  buttonLabel,
}: StatusProps) => {
  const {t} = useTranslation()
  return (
    <div {...sbEditable}>
      <ComponentContainer type="medium" noPadding>
        <div className="w-full py-12 g768:py-24 flex flex-col gap-y-4 items-center">
          {image ? (
            <div className="w-full h-[15rem] mb-4 g768:h-[22rem] relative">
              <Image src={image} alt={imageAlt ?? 'image'} fill className="object-contain"></Image>
            </div>
          ) : null}
          <h1 className="text-fixmerBlue">{title}</h1>
          {subtitle ? <h4 className="text-center">{subtitle}</h4> : null}
          {description ? <p className="text-center max-w-[50rem]">{description}</p> : null}
          <Button
            link="/"
            hideChevron
            label={buttonLabel ? buttonLabel : t('contactForm.goBack')}
            styling="primary"
          ></Button>
        </div>
      </ComponentContainer>
    </div>
  )
}

export default Status
