import classNames from 'classnames'
import type {FocusEventHandler, KeyboardEventHandler} from 'react'
import {useRef} from 'react'
import Button from '../../buttons/Button'

import styles from './AddToCartButton.module.css'

interface AddToCartButtonProps {
  amount: number
  isLoading?: boolean
  className?: string
  fullWidth?: boolean
  addToCart?: () => void
  changeQuantity?: (quantity: number) => void
  removeFromCart?: () => void
}

const AddToCartButton = ({
  amount,
  isLoading = false,
  className = '',
  fullWidth,
  addToCart,
  changeQuantity,
  removeFromCart,
}: AddToCartButtonProps) => {
  const amountInputRef = useRef<HTMLInputElement>(null)

  if (amountInputRef.current) {
    amountInputRef.current.value = amount.toString()
  }

  const handleAddToCart = () => {
    addToCart?.()
  }

  const handleRemoveProduct = () => {
    removeFromCart?.()
  }

  const handleIncreaseAmount = () => {
    changeQuantity?.(amount + 1)
  }

  const handleDecreaseAmount = () => {
    changeQuantity?.(amount - 1)
  }

  const handleChangeAmount: FocusEventHandler<HTMLInputElement> = event => {
    const quantity = event.target.value

    if (quantity.toString() !== amount.toString()) {
      changeQuantity?.(+quantity)
    }
  }

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    } else {
      event.currentTarget.style.width = `${Math.max(event.currentTarget.value.length, 1)}ch`
    }
  }

  const onFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.currentTarget.type = 'text'
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length)
    event.currentTarget.type = 'number'
  }

  const loadingClasses = classNames('', {
    'bg-fixmerGrey text-fixmerLightGrey cursor-not-allowed': isLoading,
    'bg-fixmerBlue text-fixmerWhite': !isLoading,
  })

  return (
    <div className={className}>
      {amount === 0 ? (
        <Button
          cart
          label="+"
          iconRight
          onClick={handleAddToCart}
          styling="primary"
          disabled={isLoading}
          fullWidth={fullWidth}
        />
      ) : (
        <div
          className={`items-center justify-between flex gap-x-1 rounded-3xl leading-[1.4] py-2.5 px-3 g1680:py-[0.5vw]  outline-none ${loadingClasses}`}
        >
          {amount > 1 ? (
            <button
              onClick={handleDecreaseAmount}
              aria-label="decrease"
              className={`group w-6 flex justify-center items-center h-6`}
              disabled={isLoading}
            >
              <div
                className={`w-[10px] h-[10px] group-hover:scale-150 transition-transform duration-300 ${styles.minus} bg-contain bg-center bg-no-repeat  `}
              />
            </button>
          ) : (
            <button
              onClick={handleRemoveProduct}
              aria-label="remove product"
              className={`group w-7 flex justify-center items-center h-6`}
              disabled={isLoading}
            >
              <div
                className={`w-4 h-4 group-hover:scale-125 transition-transform duration-300 ${styles.delete} bg-contain bg-center bg-no-repeat  `}
              />
            </button>
          )}
          <input
            ref={amountInputRef}
            defaultValue={amount ?? 1}
            type="number"
            onBlur={handleChangeAmount}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            style={{
              width: `${amount.toString().length}ch`,
            }}
            className={`g768:text-h5 text-center text-h4 ${loadingClasses}`}
            disabled={isLoading}
            tabIndex={-1}
          />
          <button
            onClick={handleIncreaseAmount}
            aria-label="increase"
            className={`group w-6 flex justify-center items-center h-6`}
            disabled={isLoading}
          >
            <div
              className={`w-[10px] h-[10px] group-hover:scale-150  transition-transform duration-300 ${styles.plus} bg-contain bg-center bg-no-repeat`}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default AddToCartButton
