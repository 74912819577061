// Supported query types for "type" query parameter in /api/commercetools/query
export enum QueryType {
  Account = 'account',
  Carts = 'carts',
  CartsOnlyIdsAndQuantity = 'cartsOnlyIdsAndQuantity',
  ShoppingListsByBusinessUnit = 'shoppingListsByBusinessUnit',
  ShoppingListById = 'shoppingListById',
  ProductsInShoppingLists = 'productsInShoppingLists',
  categoryNamesByKeys = 'categoryNamesByKeys',
}

export enum MutationType {
  CreateShoppingList = 'createShoppingList',
  DeleteShoppingList = 'deleteShoppingList',
  AddLineItemToShoppingList = 'addLineItemToShoppingList',
  RemoveLineItemFromShoppingList = 'removeLineItemFromShoppingList',
}
