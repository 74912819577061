import Image from 'next/image'
import {gtmClickToCall, gtmClickToEmail} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'
export interface ServiceProps {
  id: string | number
  icon: string
  iconAlt?: string
  description?: string
  fitParent?: boolean
  button?: {label?: string; path?: string; openInNewWindow?: boolean}
}

export interface ServiceCardListProps extends SbEditableProps {
  title: string
  services: ServiceProps[]
  fitParent: boolean
}

const ServiceCardList = ({title, services, sbEditable, fitParent}: ServiceCardListProps) => {
  return (
    <div
      {...sbEditable}
      className={` ${
        fitParent ? 'w-full p-8' : 'w-screen'
      } bg-fixmerGrey text-fixmerWhite rounded-b-2xl py-12 g768:py-[5vw]`}
    >
      <ComponentContainer type={!fitParent ? 'large' : 'full'} className="2xl:w-[50vw]" noPadding>
        <div>
          <h3 className="h2-like ">{title}</h3>
          <div className="flex flex-col g768:flex-row justify-between flex-wrap gap-2 g768:gap-y-6 w-full">
            {services.map(service => {
              return (
                <div key={service.id} className="flex g768:w-[48%] mt-6 g1680:mt-[2vw]">
                  <div className="relative w-fit h-fit min-w-[40px] min-h-[38px]">
                    <Image
                      fill
                      src={service.icon}
                      alt={service.iconAlt ?? 'serviceIcon'}
                      className="object-contain bg-no-repeat"
                    ></Image>
                  </div>
                  <div className="ml-6 g768:ml-[2.5vw]">
                    <p className="">{service.description}</p>
                    {service.button ? (
                      <Button
                        styling="secondary"
                        className="mt-4 w-fit"
                        openInNewWindow={service.button.openInNewWindow}
                        label={service.button.label}
                        inverse
                        link={service.button.path}
                        disabled={service.button?.path === ''}
                        onClick={() => {
                          const path = service.button?.path
                          if (path?.startsWith('tel:')) {
                            gtmClickToCall(path.replace(/tel:/g, ''))
                          } else if (path?.startsWith('mailto:')) {
                            gtmClickToEmail(path.replace(/mailto:/g, ''))
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </ComponentContainer>
    </div>
  )
}

export default ServiceCardList
