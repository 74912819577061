import classNames from 'classnames'
import {useTranslation} from 'next-i18next'

export interface PriceProps {
  price: string
  reducedPrice?: string
  className?: string
  styling?: 'extraSmall' | 'small' | 'medium' | 'large'
  gap?: 'small' | 'large'
  alternative?: boolean
}

const Price = ({
  price,
  reducedPrice,
  className,
  styling = 'medium',
  gap = 'large',
  alternative,
}: PriceProps) => {
  const basePriceSplit = price?.split('.')
  const reducedPriceSplit = reducedPrice?.split('.')

  const {t} = useTranslation()

  const priceClassesMedium = classNames('price relative', {
    'text-[0.7rem]': styling === 'extraSmall',
    'text-[1rem]': styling === 'small',
    'text-price-x-small': styling === 'medium',
    'text-price-small': styling === 'large',
  })

  const priceClassesSmall = classNames('price relative', {
    'text-[0.6rem]': styling === 'extraSmall',
    'text-[0.7rem]': styling === 'small',
    'text-[0.8rem]': styling === 'medium',
    'text-price-x-small': styling === 'large',
  })

  const priceClassesLarge = classNames('price relative', {
    'text-price-x-small': styling === 'extraSmall',
    'text-price-small': styling === 'small',
    'text-price-medium': styling === 'medium',
    'text-price-large': styling === 'large',
  })

  const priceReducedBottom = classNames({
    'bottom-[0.1rem]': styling === 'extraSmall',
    'bottom-[0.3rem]': styling === 'small',
    'bottom-[0.35rem]': styling === 'medium',
    'bottom-[0.6rem]': styling === 'large',
  })

  const priceCurrentBottom = classNames({
    'bottom-[0.4rem]': styling === 'extraSmall',
    'bottom-[0.5rem]': styling === 'small' || styling === 'medium',
    'bottom-[0.55rem]': styling === 'large',
  })

  return (
    <div>
      <div
        className={`w-full flex flex-wrap price items-center ${
          gap === 'large' ? 'gap-x-4' : 'gap-x-2'
        } ${className}`}
      >
        {reducedPriceSplit && price !== reducedPrice ? (
          <div className="flex-col flex">
            {alternative ? (
              <p className="font-normal mb-2 g768:mb-3">{t('promo.original')}</p>
            ) : null}
            <p className={priceClassesMedium}>
              <span>{basePriceSplit?.[0]}</span>.
              <span className={`${priceClassesSmall} ${priceReducedBottom}`}>
                {basePriceSplit?.[1]}
              </span>
              <span
                className={`absolute z-10 top-0 items-center left-0 flex justify-center w-full h-full`}
              >
                <svg
                  width="31"
                  height="20"
                  viewBox="0 0 31 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="0.732474" y1="19.5776" x2="30.7325" y2="0.577591" stroke="#D12127" />
                </svg>
              </span>
            </p>
          </div>
        ) : null}
        <div className="flex-col flex">
          {alternative ? (
            <p className="font-normal mb-2 g768:mb-3">
              {reducedPrice ? t('promo.bestPromo') : t('promo.original')}
            </p>
          ) : null}
          <p
            className={`${priceClassesLarge} ${
              reducedPriceSplit && price !== reducedPrice ? 'text-fixmerRed' : 'text-fixmerBlue'
            } ${alternative ? ' g768:!text-price-small !text-price-x-small' : ''}`}
          >
            <span>{reducedPriceSplit ? reducedPriceSplit?.[0] : basePriceSplit?.[0]}</span>.
            <span
              className={`${priceClassesMedium} ${
                alternative ? 'g768:!text-price-x-small !text-[0.6rem]' : ''
              } ${priceCurrentBottom}`}
            >
              {reducedPriceSplit ? reducedPriceSplit?.[1] : basePriceSplit?.[1]}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Price
