import {storyblokEditable} from '@storyblok/js'
import {useRouter} from 'next/router'
import type {TextStoryblok} from '../../../storyblok-types/component-types-sb'
import TextCard from '../../cards/TextCard'

import RichText from '../../RichText'

const TextBlok = ({blok}: {blok: TextStoryblok}) => {
  const {asPath} = useRouter()

  const inAccountPage = asPath.includes('/account/')

  return (
    <TextCard
      sbEditable={storyblokEditable(blok)}
      content={<RichText richText={blok.description}></RichText>}
      fitParent={inAccountPage}
    />
  )
}

export default TextBlok
