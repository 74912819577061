import {storyblokEditable} from '@storyblok/js'
import type {JobListStoryblok} from '../../../storyblok-types/component-types-sb'
import RichText from '../../RichText'
import CollapsibleList from '../../collection/CollapsibleList'

const JobListBlok = ({blok}: {blok: JobListStoryblok}) => {
  const JobCards = blok.job_items.map(job => {
    return {
      id: job._uid,
      title: job.title,
      subtitle: job.subtitle,
      description: <RichText richText={job.description} />,
      openDefault: job.open_by_default,
      CTA: {
        label: job.cta_label,
        path:
          job.cta_link?.url == ''
            ? `/${job.cta_link?.story?.url}`
            : job.cta_link?.linktype === 'email'
            ? `mailto:${job.cta_link?.url}`
            : job.cta_link?.url,
        openInNewWindow: job.cta_link?.target ? true : job.cta_link?.target ? true : false,
      },
    }
  })

  return <CollapsibleList sbEditable={storyblokEditable(blok)} cards={JobCards} />
}

export default JobListBlok
