import {Disclosure, Transition} from '@headlessui/react'
import {gtmClickToCall, gtmClickToEmail} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import type {SbEditableProps} from '../../storyblok/blok-props'
import styles from './CollapsibleCard.module.css'

export interface CollapsibleCardProps extends SbEditableProps {
  id: number | string
  title: string
  subtitle?: string
  description: JSX.Element
  openDefault?: boolean
  CTA?: {label?: string; path?: string; openInNewWindow?: boolean}
}

const CollapsibleCard = ({
  id,
  title,
  subtitle,
  description,
  openDefault,
  sbEditable,
  CTA,
}: CollapsibleCardProps) => {
  return (
    <div {...sbEditable}>
      <Disclosure defaultOpen={openDefault} key={id}>
        {({open}) => (
          <div>
            <Disclosure.Button className="w-full">
              <div
                className={`h-fit 2xl:p-[1vw] flex flex-col p-4 g768:py-6 ${
                  open ? 'rounded-x-3xl rounded-t-3xl' : 'rounded-3xl'
                } w-full cursor-pointer bg-fixmerWhite `}
              >
                <div className="mb-0 flex w-full items-center justify-between text-left font-bold normal-case">
                  <div>
                    <h4 className="h3-like h-full g768:h4-like">{title}</h4>
                    {subtitle ? (
                      <p className="uppercase font-semibold font-bitter mt-1 g1680:mt-[0.5vw]">
                        {subtitle}
                      </p>
                    ) : null}
                  </div>
                  <span
                    className={`flex transition-all ml-4 w-3 h-3 bg-no-repeat bg-center bg-contain ${
                      styles.arrow
                    } ${open ? 'rotate-0' : 'rotate-180'}`}
                  />
                </div>
              </div>
            </Disclosure.Button>
            <div>
              <Transition
                enter="transition-all duration-500 origin-top ease-in-out transform"
                enterFrom="scale-y-0"
                enterTo="scale-y-100"
              >
                <Disclosure.Panel className=" pb-4 2xl:pb-[1.5vw] -mt-2 px-6 rounded-x-3xl rounded-b-3xl  bg-fixmerWhite">
                  <div>
                    <div className="richttext max-w-[60rem]">{description}</div>
                    {CTA && CTA.path && CTA.label ? (
                      <Button
                        className="w-fit mt-4 text-fixmerWhite"
                        openInNewWindow={CTA.openInNewWindow}
                        fullWidth
                        hideChevron
                        link={CTA.path}
                        label={CTA.label}
                        styling="primary"
                        onClick={() => {
                          const path = CTA.path
                          if (path?.startsWith('tel:')) {
                            gtmClickToCall(path.replace(/tel:/g, ''))
                          } else if (path?.startsWith('mailto:')) {
                            gtmClickToEmail(path.replace(/mailto:/g, ''))
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

export default CollapsibleCard
