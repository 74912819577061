import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import useUser from '../../../lib/hooks/use-user'
import type {OrderProposalStoryblok} from '../../../storyblok-types/component-types-sb'
import VerticalProductList from '../../collection/VerticalProductList'
import LoadingSpinner from '../../components/LoadingSpinner'
import {getAndDivideCommercetoolsProductsFromKeys} from '../../../lib/get-commercetools-products-from-keys'

const OrderSuggestionBlok = ({blok}: {blok: OrderProposalStoryblok}) => {
  const router = useRouter()
  const {user} = useUser()

  const {t} = useTranslation('common')

  //Fetch order proposal from commercetools
  const {
    data: orderProposal,
    isFetching,
    isRefetching,
  } = useQuery(
    ['order_proposal', user.activeBU],
    async () => {
      //Get the order proposal for a business unit from commercetools
      let response = await fetch(`/api/get-order-proposal`)

      if (response.status === 204) {
        return undefined
      }

      const orderProposalJson = await response.json()

      const productKeys: string[] = []
      let products: {id: string; quantity: string}[] =
        orderProposalJson?.map((item: string) => {
          const product = JSON.parse(item)
          productKeys.push(product.product_id)
          return {
            id: product.product_id,
            quantity: product.quantity,
          }
        }) ?? []

      //Get the products from keys and divide them by category
      const ctProducts = await getAndDivideCommercetoolsProductsFromKeys(
        productKeys,
        router.locale!,
        products,
      )

      return ctProducts
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!user.activeBU,
    },
  )

  return (
    <div>
      <div className="w-full">
        {isRefetching || isFetching ? (
          <div className="my-8">
            <LoadingSpinner />
          </div>
        ) : orderProposal ? (
          <VerticalProductList
            title={blok.title}
            nonTobaccoProducts={orderProposal?.nonTobaccoProducts}
            tobaccoProductsSortedByCategory={orderProposal?.tobaccoProductsSortedByCategory}
            tobaccoProducts={orderProposal?.tobaccoProducts}
            nonTobaccoProductsSortedByCategory={orderProposal?.nonTobaccoProductsSortedByCategory}
          />
        ) : (
          t('orderSuggestion.noSuggestionFound')
        )}
      </div>
    </div>
  )
}

export default OrderSuggestionBlok
