import {Disclosure, Transition} from '@headlessui/react'
import {useTranslation} from 'next-i18next'

import Button from '../../buttons/Button'
import styles from './CollapsibleOrderCard.module.css'

import {useRouter} from 'next/router'
import {useContext, useState} from 'react'
import {gtmAddOrderlistToCart} from '../../../lib/gtm-events'

import {downloadPastOrder} from '../../../lib/client-download-past-order'
import {CartContext} from '../../../lib/context/cart-context'
import {dateToLongString, dateToShortString, dateToTimeString} from '../../../lib/utitlities'
import LoadingDots from '../../components/LoadingDots'
import ListProductCard, {ListProductCardProps} from '../ListProductCard'

export interface ShippingNoteLineItemProps {
  product_id: string
  product_name: {
    en: string
    nl: string
    fr: string
  }
  delivered_quantity: number
  ordered_quantity: number
  product_price: string
  date_updated: string
  unit1_ean: string
  unit1_package_code: string
  unit1_quantity: string
  unit2_ean: string
  unit2_package_code: string
  unit2_quantity: string
  unit3_ean: string
  unit3_package_code: string
  unit3_quantity: string
  article_category: string
}

export interface ShippingNoteProps {
  shippingnote_number: string
  line_items: ShippingNoteLineItemProps[]
}

export interface CollapsibleOrderCardProps {
  id: number | string
  status?: string
  statusColor?: string
  timestamp: string
  tobaccoProducts?: ListProductCardProps[]
  nonTobaccoProducts?: ListProductCardProps[]
  shippingNote?: ShippingNoteProps
}

const CollapsibleOrderCard = ({
  id,
  status,
  statusColor,
  timestamp,
  tobaccoProducts,
  nonTobaccoProducts,
  shippingNote,
}: CollapsibleOrderCardProps) => {
  const {t} = useTranslation()

  const {locale} = useRouter()

  const {cartAddProductList, isCartLoading} = useContext(CartContext)

  const [orderAdded, setOrderAdded] = useState(0)

  //check if atleast 1 product is available
  const productsAvailable = [...(tobaccoProducts ?? []), ...(nonTobaccoProducts ?? [])].some(
    product => !product.notAvailable,
  )

  const date = new Date(timestamp)

  const handleAddToCart = async () => {
    const productKeys: string[] = []
    const categoryKeys: string[] = []
    const quantities: number[] = []
    tobaccoProducts?.forEach(product => {
      if (!product.notAvailable) {
        productKeys.push(product.id.toString())
        categoryKeys.push(product.category ?? '')
        quantities.push(product.quantity ?? 1)
      }
    })
    nonTobaccoProducts?.forEach(product => {
      if (!product.notAvailable) {
        productKeys.push(product.id.toString())
        categoryKeys.push(product.category ?? '')
        quantities.push(product.quantity ?? 1)
      }
    })

    await cartAddProductList(productKeys, categoryKeys, quantities, () => {
      setOrderAdded(orderAdded + 1)
      gtmAddOrderlistToCart(tobaccoProducts ?? [], nonTobaccoProducts ?? [])
    })
  }

  const handleExport = async () => {
    if (shippingNote) {
      downloadPastOrder(shippingNote, locale!)
    }
  }

  return (
    <Disclosure key={id}>
      {({open}) => (
        <div className={`${styles.shadow} rounded-x-3xl rounded-3xl rounded-t-3xl`}>
          <Disclosure.Button className="w-full">
            <div
              className={`h-fit 2xl:p-[1vw] flex flex-col p-4 g768:py-4 ${
                open ? 'rounded-x-3xl rounded-t-3xl' : 'rounded-3xl'
              } w-full cursor-pointer bg-fixmerWhite `}
            >
              <div className="mb-0 flex w-full items-center justify-between text-left font-bold normal-case">
                <div className="flex flex-row items-center flex-wrap gap-3">
                  <h4 className="h3-like h-full g1280:h5-like">
                    <span className="g768:block hidden">
                      {t('orderHistory.orderDate', {
                        date: dateToShortString(date, locale!, '/'),
                      })}
                    </span>
                    <span className="g768:hidden block">
                      {dateToShortString(date, locale!, '/')}
                    </span>
                  </h4>

                  {status ? (
                    <p
                      style={{backgroundColor: statusColor}}
                      className={`flex items-center mr-4 w-fit border text-fixmerWhite py-1 px-2 rounded-full`}
                    >
                      <span className="text-small">{status}</span>
                    </p>
                  ) : null}
                </div>
                <span
                  className={`flex transition-all ml-4 w-3 h-3 bg-no-repeat bg-center bg-contain ${
                    styles.arrow
                  } ${open ? 'rotate-0' : 'rotate-180'}`}
                />
              </div>
            </div>
          </Disclosure.Button>
          <div>
            <Transition
              enter="transition-all duration-200 origin-top ease-in-out transform"
              enterFrom="scale-y-0"
              enterTo="scale-y-100"
            >
              <Disclosure.Panel
                className={`pt-2 pb-4 2xl:py-[0.8vw] rounded-x-3xl rounded-b-3xl  bg-fixmerWhite`}
              >
                <div className="w-full border-b pb-4">
                  <div className="px-4">
                    <p>
                      {t('orderHistory.orderDescription', {
                        date: dateToLongString(date, locale!),
                        time: dateToTimeString(date, locale!),
                      })}
                    </p>
                    {productsAvailable ? (
                      <div className="flex items-center mt-2 gap-2">
                        <Button
                          styling="primary"
                          hideChevron
                          onClick={handleAddToCart}
                          label={t('order.addAllArticles')}
                          disabled={isCartLoading}
                        />
                        {shippingNote ? (
                          <Button
                            styling="secondary"
                            hideChevron
                            onClick={() => handleExport()}
                            label={t('general.export')}
                          />
                        ) : null}
                      </div>
                    ) : null}

                    {isCartLoading ? (
                      <LoadingDots className="mt-4" />
                    ) : (
                      <>
                        {orderAdded > 0 ? (
                          <p className="text-fixmerBlue font-bold mt-4">
                            {t('order.orderAddedToCart', {quantity: orderAdded})}
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                {tobaccoProducts ? (
                  <div className="flex flex-col gap-4 mt-4">
                    <h4 className="px-4">{t('cart.tobaccoProducts')}</h4>
                    {tobaccoProducts.map(item => {
                      return <ListProductCard key={item.id} {...item} />
                    })}
                  </div>
                ) : null}
                {nonTobaccoProducts ? (
                  <div className="flex flex-col gap-4 mt-8">
                    <h4 className="px-4">{t('cart.nonTobaccoProducts')}</h4>
                    {nonTobaccoProducts.map(item => {
                      return <ListProductCard key={item.id} {...item} />
                    })}
                  </div>
                ) : null}
              </Disclosure.Panel>
            </Transition>
          </div>
        </div>
      )}
    </Disclosure>
  )
}

export default CollapsibleOrderCard
