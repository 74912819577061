import {attributesListToMap} from './commercetools/ct-utilities'

export const mapCtProductsToCard = (
  productsCommercetools: any[],
  products?:
    | {
        id: string
        quantity: string
      }[]
    | undefined,
) => {
  const mappedProducts = productsCommercetools.map(item => {
    const productData = item.masterData.current
    const productAttributes = attributesListToMap(productData?.masterVariant.attributesRaw)
    return {
      id: item.key!,
      image: productData?.masterVariant.images?.[0]?.url ?? undefined,
      brand: productAttributes.brand ?? '',
      productName: productData?.name ?? '',
      slug: productData?.slug ?? '',
      contentPerUnit: productAttributes.content_per_unit ?? '',
      category: productData?.categories?.[0]?.key,
      quantity:
        parseInt(products?.find(product => product.id === item.key)?.quantity as string) ??
        undefined,
    }
  })

  return mappedProducts
}
