import {storyblokEditable} from '@storyblok/js'
import dynamic from 'next/dynamic'
import type {StatusStoryblok} from '../../../storyblok-types/component-types-sb'

const Status = dynamic(() => import('../../components/Status'))

const StatusBlok = ({blok}: {blok: StatusStoryblok}) => {
  return (
    <Status
      sbEditable={storyblokEditable(blok)}
      title={blok.title}
      image={blok.image.filename}
      imageAlt={blok.image.alt ?? ''}
      subtitle={blok.subtitle}
      description={blok.description}
    ></Status>
  )
}

export default StatusBlok
