import {useRouter} from 'next/router'
import React, {ReactNode, createContext, useContext, useEffect, useRef, useState} from 'react'
import {TranslatedPath} from '../page-props'

interface HistoryContextProps {
  history: HistoryItem[]
  setHistory(data: HistoryItem[]): void
  back(fallbackRoute?: string): void
}

interface HistoryProviderProps {
  translatedPaths: TranslatedPath[]
  children: ReactNode
}

interface HistoryItem {
  translatedPaths: TranslatedPath[]
}

const HistoryContext = createContext<HistoryContextProps>({} as HistoryContextProps)

export const HistoryProvider = ({children, translatedPaths}: HistoryProviderProps) => {
  const router = useRouter()
  const [history, setHistory] = useState<HistoryItem[]>([])

  const isBack = useRef(false)
  const currentPaths = useRef<TranslatedPath[]>()

  const back = (fallbackRoute?: string) => {
    isBack.current = true

    const lastItem = history[history.length - 1]
    const path = lastItem?.translatedPaths.find(path => path.lang === router.locale)

    // Go back in history
    if (path) {
      router.push(`/${path.lang}/${path.path}`)
    }

    // If history is empty use fallback route
    else if (fallbackRoute) {
      router.push(fallbackRoute)
    }
  }

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true
      return true
    })

    // Push current page to history when leaving page
    const onRouteChangeStart = (url: string) => {
      // Don't push if navigating backwards
      if (isBack.current) {
        return
      }

      const [toPath, toQuery] = url.split('?')
      const [fromPath, fromQuery] = `/${router.locale}${router.asPath}`.split('?')

      const isNewQuery = fromQuery !== toQuery
      const isNewPath =
        translatedPaths?.find(path => `/${path.lang}${path.path}` === toPath) === undefined

      // Don't push login page
      if (fromPath.startsWith(`/${router.locale}/login`)) {
        return
      }

      // Don't push if only language changed
      if (!isNewPath && !isNewQuery) {
        return
      }

      // Replace last history item if only query changed
      if (!isNewPath) {
        setHistory(current => [
          ...current.slice(0, -1),
          {
            translatedPaths: addQueryToTranslatedPaths(translatedPaths, fromQuery),
          },
        ])
        return
      }

      // Push history item
      setHistory(current => [
        ...current,
        {
          translatedPaths: addQueryToTranslatedPaths(translatedPaths, fromQuery),
        },
      ])
    }

    const onRouteChangeComplete = () => {
      // When navigated backwards, remove last item
      if (isBack.current) {
        setHistory(current => current.slice(0, -1))
      }

      isBack.current = false
      currentPaths.current = translatedPaths
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router])

  return (
    <HistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export function useHistory(): HistoryContextProps {
  const context = useContext(HistoryContext)
  return context
}

const addQueryToTranslatedPaths = (paths: TranslatedPath[], query: string): TranslatedPath[] => {
  return paths?.map(path => {
    let newPath = path.path

    if (query) {
      newPath = newPath.concat(`?${query}`)
    }

    return {
      lang: path.lang,
      path: newPath,
    }
  })
}
