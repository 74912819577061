import {useQueries} from 'react-query'
import type {
  PricesByProductKey,
  PricingServiceProduct,
  PricingServiceResponse,
} from '../service-types'
import useUser from './use-user'

// This hook keeps track of product prices in session storage cache
// Missing prices are fetched from the pricing service and added to the cache.
const useProductPrices = (keys: string[]) => {
  const {user} = useUser()

  const results = useQueries(
    keys.map(key => ({
      queryKey: getCacheKey(user.activeBU!, key),
      queryFn: async () => {
        return fetchPrice(key, user.activeBU)
      },
      onSuccess: (data: PricingServiceResponse) => {
        cachePrice(getCacheKey(user.activeBU!, key), data.data[0])
      },
      cacheTime: 60 * 60 * 1000, // 1 hour
      staleTime: 60 * 60 * 1000, // 1 hour
      enabled: !!user.activeBU,
    })),
  )

  const keysLoading = results
    .filter(item => item.isLoading)
    .map(item => item.data?.data[0]?.product_key)

  const cache = getCachedPrices(user.activeBU)

  return {
    prices: cache,
    keysLoading: keysLoading,
  }
}

const getCacheKey = (businessUnitKey: string, productKey: string) =>
  ['price', businessUnitKey, productKey].join('_')

const fetchPrice = async (
  productKey: string,
  businessUnitKey?: string,
): Promise<PricingServiceResponse | undefined> => {
  if (!businessUnitKey) {
    return undefined
  }

  const pricingResponse = await fetch(`/api/get-product-prices?productKeys=${productKey}`)

  const pricingJson = await pricingResponse.json()

  return pricingJson
}

const cachePrice = (cacheKey: string, data?: PricingServiceProduct) => {
  if (typeof window !== 'undefined') {
    if (!data) {
      window.sessionStorage.removeItem(cacheKey)
    } else {
      window.sessionStorage.setItem(cacheKey, JSON.stringify(data))
    }
  }
}

const getCachedPrices = (businessUnitKey?: string): PricesByProductKey | undefined => {
  if (!businessUnitKey) {
    return undefined
  }

  if (typeof window !== 'undefined') {
    const prices: PricingServiceProduct[] = []

    Object.keys(window.sessionStorage)
      .filter(key => key.startsWith(`price_${businessUnitKey}`))
      .forEach(storageKey => {
        const storagePrice = window.sessionStorage.getItem(storageKey)

        if (storagePrice) {
          try {
            prices.push(JSON.parse(storagePrice))
          } catch {
            window.sessionStorage.removeItem(storageKey)
          }
        }
      })

    const parsedCache = Object.fromEntries(prices.map(price => [price.product_key, price]))

    return parsedCache
  }

  return undefined
}

export default useProductPrices
