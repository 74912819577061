import dynamic from 'next/dynamic'
import type {ReactNode} from 'react'
import {createContext, useState} from 'react'
import type {ProductInfoForListsProps} from '../../components/cards/SaveToListCard'

const Modal = dynamic(() => import('../../components/components/Modal'))

export type ModalContextType = {
  addProduct: (product?: ProductInfoForListsProps) => void
  showModal: () => void
}

export const ModalContext = createContext<ModalContextType>({
  addProduct: () => {},
  showModal: () => {},
})

export const ModalProvider = ({children}: {children: ReactNode}) => {
  const [product, setProduct] = useState<ProductInfoForListsProps | null>(null)
  const [modalVisible, setModalVisible] = useState(false)

  const HideModal = () => {
    setModalVisible(false)
  }

  return (
    <ModalContext.Provider
      value={{
        addProduct: product => setProduct(product ?? null),
        showModal: () => setModalVisible(true),
      }}
    >
      {modalVisible ? <Modal hideModal={HideModal} product={product} /> : null}

      {children}
    </ModalContext.Provider>
  )
}
