export interface ContentContainer {
  children: JSX.Element
  className?: string
  roundedTopDesktop?: boolean
  topContainer?: boolean
}

const ContentContainer = ({
  children,
  topContainer,
  className = '',
  roundedTopDesktop,
}: ContentContainer) => {
  return (
    <div
      className={`w-full rounded-2xl ${!topContainer ? 'bg-fixmerBackgroundGrey' : ''}  ${
        !roundedTopDesktop ? 'g768:rounded-t-none rounded-t-2xl' : 'rounded-t-2xl'
      }`}
    >
      <div className={`py-[0.1px] ${className}`}>{children}</div>
    </div>
  )
}

export default ContentContainer
