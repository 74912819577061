import React from 'react'
import type {UseFormRegister} from 'react-hook-form/dist/types'

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label: string
  className?: string
  register?: UseFormRegister<any>
}

const TextArea = ({label, className = '', register, ...inputProps}: TextAreaProps) => {
  const inputClasses =
    'block rounded-3xl w-full text-fixmerBlue bg-white  appearance-none peer px-8 duration-300  form-like pt-6'
  const labelCommonClasses =
    'absolute form-like duration-300 top-3 left-8 origin-[0] cursor-text peer-focus:pt-2 font-bold bg-white w-1/2'
  const labelFocussedClasses =
    '-translate-y-3 scale-75 text-fixmerGrey peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-fixmerGrey peer-focus:font-bold'
  const labelNotFocussedClasses =
    'peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-fixmerBlue peer-placeholder-shown:font-normal'

  return (
    <div className={`relative ${className}`}>
      <textarea
        className={inputClasses}
        placeholder={inputProps.placeholder ?? ' '}
        {...inputProps}
        id={inputProps.name}
        {...register?.(inputProps.name ?? label, {required: inputProps.required})}
      />
      <label
        htmlFor={inputProps.name}
        className={[labelCommonClasses, labelFocussedClasses, labelNotFocussedClasses].join(' ')}
      >
        {label}
      </label>
    </div>
  )
}

export default TextArea
