import Image from 'next/image'
import {gtmClickToCall, gtmClickToEmail} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'
export interface ContactCardProps extends SbEditableProps {
  firstName?: string
  lastName?: string
  position?: string
  phone?: string
  email?: string
  image: string
  imageAlt?: string
  desktopStyling?: boolean
  fitParent?: boolean
}

const ContactCard = ({
  firstName,
  lastName,
  position,
  phone,
  email,
  image,
  imageAlt,
  fitParent,
  sbEditable,
}: ContactCardProps) => {
  return (
    <div {...sbEditable} className="h-full w-full">
      <ComponentContainer noPadding={fitParent ? true : false} type={fitParent ? 'full' : 'medium'}>
        <div
          className={`${
            !fitParent
              ? 'g768:h-[15vw] g768:min-h-[15rem] g768:w-full g768:flex-row g768:rounded-3xl'
              : 'h-full w-full'
          } m-auto flex h-[28rem] 2xl:h-[20vw] w-full flex-col overflow-hidden rounded-xl`}
        >
          <div className={`relative h-1/2 w-full ${!fitParent ? 'g768:h-full g768:w-3/5' : ''} `}>
            <Image src={image} alt={imageAlt ?? 'contact'} fill className="object-cover"></Image>
          </div>
          <div
            className={`relative flex h-1/2 w-full items-center bg-fixmerGrey px-4 text-fixmerWhite g768:px-8 ${
              !fitParent ? 'g768:h-full' : ''
            }`}
          >
            <div>
              <h3 className="h2-like g768:h3-like mb-2">
                {firstName} {lastName}
              </h3>
              <p className="mb-1 font-bold">{position}</p>
              <div className="flex flex-col">
                {phone ? (
                  <Button
                    className="mt-4 w-fit"
                    styling="secondary"
                    link={`tel:${phone}`}
                    phone
                    inverse
                    label={phone}
                    onClick={() => {
                      gtmClickToCall(phone)
                    }}
                  />
                ) : null}
                {email ? (
                  <Button
                    className="mt-4 w-fit"
                    styling="secondary"
                    link={`mailto:${email}`}
                    email
                    inverse
                    label={email}
                    onClick={() => {
                      gtmClickToEmail(email)
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  )
}

export default ContactCard
