import {useRouter} from 'next/router'
import {useState} from 'react'
import {useInfiniteQuery, useQuery} from 'react-query'
import {ProductListByKeysQuery} from '../../../gql-types'
import useUser from '../../../lib/hooks/use-user'
import {mapOrderProduct} from '../../../lib/map-order-product-to-card'
import type {CosmosOrder} from '../../../lib/service-types'
import type {OrderListStoryblok} from '../../../storyblok-types/component-types-sb'
import {CollapsibleOrderCardProps} from '../../cards/CollapsibleOrderCard'
import OrderList from '../../collection/OrderList'
import {useTranslation} from 'next-i18next'
import LoadingSpinner from '../../components/LoadingSpinner'

const ORDERS_PER_PAGE = 10

const OrderListBlok = ({blok}: {blok: OrderListStoryblok}) => {
  const router = useRouter()
  const {user} = useUser()
  const {t} = useTranslation('common')

  const [year, setYear] = useState('')

  const title = blok.title

  //Fetch all distinct years where the user has placed orders. When done set the latest year as default
  const {data: orderYears, error: orderYearsError} = useQuery(
    ['order_years_list', user.activeBU],
    async () => {
      const response = await fetch(`/api/get-order-years-by-customer`)

      if (!response.ok) {
        throw new Error('Failed to fetch order years')
      }

      const years = await response.json()

      if (years.length > 0) {
        setYear(years[0])
      }
      return years.map((year: string, index: number) => {
        return {
          id: index,
          name: year,
        }
      })
    },
    {
      retry: 3,
    },
  )

  if (year === '' && orderYears && orderYears.length > 0) {
    setYear(orderYears[0].name)
  }

  const {
    data: orderPages,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery<{
    orders: CollapsibleOrderCardProps[]
    continuationToken: string
  }>({
    queryKey: ['order_list', user.activeBU, year],
    getNextPageParam: lastPage => lastPage.continuationToken,
    queryFn: async ({pageParam}) => {
      // Fetch orders from cosmos db
      const url = '/api/get-orders-by-customer'
      const params = new URLSearchParams()
      params.set('year', year)
      params.set('limit', ORDERS_PER_PAGE.toString())

      if (pageParam) {
        params.set('continuation', pageParam)
      }

      const ordersResponse = await fetch(`${url}?${params}`)
      const ordersJson = await ordersResponse.json()

      const orders = ordersJson.orders as CosmosOrder[]
      const continuationToken = ordersJson.continuationToken as string

      //Get products in orders from commercetools
      const uniqueProductKeys = [
        ...new Set(
          orders.flatMap(order => [
            ...(order.tobaccoProducts?.map(product => product.product_id) ?? []),
            ...(order.nonTobaccoProducts?.map(product => product.product_id) ?? []),
          ]),
        ),
      ]

      const response = await fetch(`/api/get-products-by-key?locale=${router.locale}`, {
        method: 'POST',
        body: JSON.stringify(uniqueProductKeys),
      })

      const commercetoolsProducts: ProductListByKeysQuery['products']['results'] =
        await response.json()

      const commercetoolProductsByKey = Object.fromEntries<
        ProductListByKeysQuery['products']['results'][0]
      >(commercetoolsProducts.map(product => [product.key ?? '', product]))

      //Get products within colis
      const uniqueProductColisKeys = [
        ...new Set(
          commercetoolsProducts.flatMap(product => [
            ...(product.masterData.current?.masterVariant.attributesRaw
              .find(item => item.name === 'colis_products')
              ?.value.map((product: string) => JSON.parse(product).product) ?? []),
          ]),
        ),
      ]

      const colisResponse = await fetch(`/api/get-products-by-key?locale=${router.locale}`, {
        method: 'POST',
        body: JSON.stringify(uniqueProductColisKeys),
      })

      const commercetoolsColisProducts: ProductListByKeysQuery['products']['results'] =
        await colisResponse.json()

      const commercetoolColisProductsByKey = Object.fromEntries<
        ProductListByKeysQuery['products']['results'][0]
      >(commercetoolsColisProducts.map(product => [product.key ?? '', product]))

      // Map orders from cosmos + ct data
      const mappedOrders = orders.map(order => {
        const mappedOrder: CollapsibleOrderCardProps = {
          id: order.id,
          status: order.status,
          timestamp: order.timestamp,
          tobaccoProducts: order.tobaccoProducts?.map(product => {
            return mapOrderProduct(
              product,
              router.locale!,
              commercetoolProductsByKey[product.product_id],
              commercetoolColisProductsByKey,
            )
          }),
          nonTobaccoProducts: order.nonTobaccoProducts?.map(product => {
            return mapOrderProduct(
              product,
              router.locale!,
              commercetoolProductsByKey[product.product_id],
              commercetoolColisProductsByKey,
            )
          }),
        }
        if (order.shippingnote) {
          mappedOrder.shippingNote = order.shippingnote
        }
        return mappedOrder
      })

      return {
        orders: mappedOrders,
        continuationToken,
      }
    },
    enabled: !!year,
    refetchOnWindowFocus: false,
  })

  const orders = orderPages?.pages.flatMap(page => page.orders)

  return (
    <>
      {orders && orders.length > 0 ? (
        <OrderList
          title={title}
          orders={orders}
          years={orderYears}
          hasMore={hasNextPage ?? false}
          loadMore={() => fetchNextPage()}
          isLoading={isRefetching}
          setYear={setYear}
        />
      ) : (
        <div className="w-full">
          <div className="flex gap-4 justify-between g768:justify-start w-full">
            <h2>{title}</h2>
          </div>
          <div className="my-8">
            {orderYearsError ? (
              <p>{t('orderHistory.fetchError')}</p>
            ) : orderYears && orderYears.length === 0 ? (
              <p>{t('order.noOrderFound')}</p>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default OrderListBlok
