import {useRef, useState, useEffect} from 'react'
import type {UseFormRegister} from 'react-hook-form/dist/types'
import styles from './Input.module.css'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string
  className?: string
  autoFocus?: boolean
  type?: string
  register?: UseFormRegister<any>
}

const Input = ({label, autoFocus, className = '', register, type, ...inputProps}: InputProps) => {
  const inputClasses =
    'block rounded-full w-full text-fixmerBlue bg-white h-[61px] appearance-none peer px-8 duration-300 focus:h-[68px] placeholder-shown:h-14 form-like pt-4'
  const labelCommonClasses =
    'absolute form-like duration-300 top-4 left-8 origin-[0] cursor-text font-bold'
  const labelFocussedClasses =
    '-translate-y-1 scale-75 text-fixmerGrey peer-focus:-translate-y-1 peer-focus:scale-75 peer-focus:text-fixmerGrey peer-focus:font-bold'
  const labelNotFocussedClasses =
    'peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-fixmerBlue peer-placeholder-shown:font-normal'

  const [passwordVisible, setPasswordVisible] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()!
    }
  })

  return (
    <div className={`relative ${className}`}>
      <input
        ref={inputRef}
        className={inputClasses}
        placeholder={inputProps.placeholder ?? ' '}
        type={type !== 'password' ? type : passwordVisible ? 'text' : 'password'}
        {...inputProps}
        id={inputProps.name}
        onClick={event => {
          // This 'unfreezes' the input in case it was autofilled by the browser
          event.currentTarget.value = event.currentTarget.value
        }}
        {...register?.(inputProps.name ?? label, {required: inputProps.required})}
      />
      <label
        htmlFor={inputProps.name}
        className={[labelCommonClasses, labelFocussedClasses, labelNotFocussedClasses].join(' ')}
      >
        {label}
      </label>

      {type === 'password' && (inputProps.value || register) ? (
        <div className={`absolute z-10 g768:right-12 right-8 h-full flex items-center top-0`}>
          <button
            className={`h-5 w-5 bg-contain bg-center bg-no-repeat duration-300 peer-placeholder-shown:top-1  ${
              passwordVisible ? styles.disableEyeIcon : styles.eyeIcon
            }`}
            type="button"
            onClick={event => {
              event.preventDefault()
              setPasswordVisible(!passwordVisible)
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Input
