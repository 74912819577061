import {storyblokEditable} from '@storyblok/js'
import type {AccountDetailsStoryblok} from '../../../storyblok-types/component-types-sb'
import AccountDetails from '../../account/AccountDetails'

const AccountDetailsBlok = ({blok}: {blok: AccountDetailsStoryblok}) => {
  const rows =
    blok.table?.tbody.map(item => ({
      label: item.body[0].value ?? '',
      value: item.body[1].value ?? '',
    })) ?? []

  return (
    <AccountDetails
      sbEditable={storyblokEditable(blok)}
      rows={rows}
      hideSalesContact={!blok.show_sales_contact}
      salesContactHeader={blok.sales_contact_header}
    />
  )
}

export default AccountDetailsBlok
