import {useRouter} from 'next/router'
import {useQuery, useQueryClient} from 'react-query'
import type {ProductsInShoppingListsQuery} from '../../gql-types'
import fetchCtData from '../commercetools/fetch-ct-data'
import {QueryType} from '../commercetools/query-types'
import useUser from './use-user'

// This hook returns all products add in a shopping list for the active business unit
const useProductsInLists = () => {
  const {user} = useUser()
  const {locale} = useRouter()
  const queryClient = useQueryClient()

  const queryKey = `listed_products_${user.activeBU}`

  const fetchLists = async () => {
    const response = await fetchCtData<ProductsInShoppingListsQuery>(
      QueryType.ProductsInShoppingLists,
      {
        locale: locale!,
      },
    )

    const productSlugsInLists: string[] = []

    response.shoppingLists.results.forEach(list =>
      list.lineItems.forEach(item => {
        if (item.productSlug) {
          productSlugsInLists.push(item.productSlug)
        }
      }),
    )

    return productSlugsInLists
  }

  const {data} = useQuery(queryKey, fetchLists, {
    cacheTime: 60 * 60 * 1000, // 1 hour
    staleTime: 60 * 60 * 1000, // 1 hour
    enabled: !!user.activeBU,
  })

  const productSlugsInLists = data ?? []

  const invalidateProductsInListsQuery = async () => queryClient.invalidateQueries(queryKey)

  return {
    productSlugsInLists,
    invalidateProductsInListsQuery,
  }
}

export default useProductsInLists
