import type {StoryData} from '@storyblok/react'
import {getStoryblokApi} from '@storyblok/react'
import {NavigationActionItem, NavigationItem, NavigationLinkSubItem} from '../components/types'
import {pathTranslations} from '../path-translations'
import type {
  CategoryCardStoryblok,
  NavigationStoryblok,
  NavigationSubItemStoryblok,
  SalesContactStoryblok,
} from '../storyblok-types/component-types-sb'
import type {FooterStoryblok} from './../storyblok-types/component-types-sb.d'
import type {ProductCategory} from './../storyblok-types/types-sb.d'
import type {StaticPathParams, TranslatedPath} from './page-props'

// Async fetcher functions
export const fetchStoriesUrlMap = async (): Promise<
  {params: StaticPathParams; locale: string}[]
> => {
  const storyblokApi = getStoryblokApi()

  // Get stories from storyblok with 'page' in content_type codename
  const {data: stories} = await storyblokApi.get('cdn/stories', {
    resolve_links: 'url',
    excluding_slugs: 'account/*',
    filter_query: {
      component: {like: '*page*'},
    },
  })

  const paths: {params: StaticPathParams; locale: string}[] = stories.stories.flatMap(
    (story: StoryData) => {
      const storyPaths: {params: StaticPathParams; locale: string}[] = [
        {
          locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
          params: {slug: story.full_slug === 'home' ? [] : story.full_slug.split('/')},
        },
      ]

      story.translated_slugs
        //Filter out languages that are not in the environment variables
        ?.filter(translatedSlug => process.env.NEXT_PUBLIC_LOCALES?.includes(translatedSlug.lang))
        .forEach(translatedSlug => {
          storyPaths.push({
            locale: translatedSlug.lang,
            params: {slug: translatedSlug.path === 'home' ? [] : translatedSlug.path.split('/')},
          })
        })

      return storyPaths
    },
  )

  return paths
}

export const fetchStory = async (
  slug: string,
  locale: string | undefined,
  preview: boolean = false,
  skipCache: boolean = true,
) => {
  const storyblokApi = getStoryblokApi()

  const {data} = await storyblokApi.getStory(slug, {
    version: preview ? 'draft' : 'published',
    resolve_links: 'url',
    language: locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    cv: skipCache ? Date.now() : undefined,
  })

  return data.story
}

export const fetchStoryById = async (
  id: number,
  locale: string | undefined,
  preview: boolean = false,
) => {
  const storyblokApi = getStoryblokApi()

  const {data} = await storyblokApi.get(`cdn/stories/${id}`, {
    version: preview ? 'draft' : 'published',
    resolve_links: 'url',
    language: locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  })

  return data.story
}

export const fetchSalesContactByEmail = async (
  email: string,
  locale: string,
  preview: boolean = false,
): Promise<SalesContactStoryblok | undefined> => {
  const storyblokApi = getStoryblokApi()

  const {data} = await storyblokApi.get(`cdn/stories`, {
    version: preview ? 'draft' : 'published',
    language: locale,
    starts_with: 'sales-contacts/',
    filter_query: {
      email: {
        in: email,
      },
    },
  })

  return (data.stories[0]?.content as SalesContactStoryblok) ?? undefined
}

export const fetchNavigationAndFooter = async (
  locale: string,
  preview: boolean,
  skipCache?: boolean,
) => {
  const navigation = await fetchStory('global-components/navigation', locale, preview, skipCache)
  const footer = await fetchStory('global-components/footer', locale, preview, skipCache)

  return {
    navigation: navigation.content.global[0],
    footer: footer.content.global[0],
  }
}

export const fetchAccountNavigation = async (
  locale: string,
  preview: boolean,
  skipCache?: boolean,
) => {
  const navigation = await fetchStory(
    'global-components/account-navigation',
    locale,
    preview,
    skipCache,
  )

  // Temporary: manually adding download catalogue navigation item for non-production environments
  // When ok for production, add item in storyblok instead (global-components/account-navigation)
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
    navigation.content.global[0].navigation_items.push({
      _uid: '6db7132d-4186-467d-b8ec-152a0f92temp',
      component: 'navigation_action_item',
      action: 'download-catalogue',
      title: 'Download catalogue',
      icon_dark: {
        filename: 'https://a.storyblok.com/f/185347/24x24/908a715527/download_dark.svg',
      },
      icon_light: {
        filename: 'https://a.storyblok.com/f/185347/24x24/6236176320/download_light.svg',
      },
    })
  }

  return {accountNavigation: navigation.content.global[0]}
}

export const fetchMetaTagsProductOverview = async (locale: string, preview: boolean) => {
  const metaTags = await fetchStory('global-components/meta-tags-product-overview', locale, preview)
  return {meta_tags: metaTags.content.global[0]}
}

export const fetchCategoryList = async (
  locale: string,
  preview: boolean,
): Promise<ProductCategory[]> => {
  const categoryListStory = await fetchStory('global-components/category-list', locale, preview)

  const categoryCards = categoryListStory.content.global[0].category_cards

  const promotionsPath = pathTranslations.promotions[locale]!

  const categories: ProductCategory[] = categoryCards.map((card: CategoryCardStoryblok) => {
    let slug = '/'

    const categorySlug = card.link.categorySlug[locale]

    if (card.type === 'promotions') {
      slug = promotionsPath.slice(promotionsPath.lastIndexOf('/'))
    } else if (card.type === 'category' && categorySlug) {
      slug = `/${categorySlug}`
    }

    let name = card.title

    if (card.type === 'category' && card.link.categoryName[locale]) {
      name = card.link.categoryName[locale]
    }

    return {
      id: card.link.categoryId,
      key: card.link.categoryKey,
      type: card.type,
      image: card.pictogram?.filename,
      tobacco: card.tabacco ?? false,
      name,
      slug,
    }
  })

  return categories
}

// Helper functions

export const getTranslatedLinksFromStory = (story: StoryData): TranslatedPath[] => {
  if (!story) {
    return []
  }

  const defaultLangPath = story.default_full_slug ?? ''

  const translatedSlugs = [
    {
      path: `/${defaultLangPath}`.replace(/\/(home|profile-homepage)/g, ''),
      lang: process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
    },
    ...(story.translated_slugs
      //Filter out languages that are not in the environment variables
      ?.filter(translatedSlug => process.env.NEXT_PUBLIC_LOCALES?.includes(translatedSlug.lang))
      .map(translatedSlug => ({
        path: `/${translatedSlug.path}`.replace(/(home|profile-homepage)/g, ''),
        lang: translatedSlug.lang,
      })) ?? []),
  ]

  return translatedSlugs
}

export const getNavigationItems = (
  navigation: NavigationStoryblok | FooterStoryblok,
  locale: string,
  activeBU?: string,
): NavigationItem[] => {
  return (
    navigation?.navigation_items?.map(item => {
      if (item.component === 'navigation_action_item') {
        let iconAlt: string = ''

        if (item.action === 'download_catalogue') {
          iconAlt = 'download'
        }

        const navItem: NavigationActionItem = {
          type: 'action',
          id: item._uid,
          description: item.title,
          iconDark: item.icon_dark?.filename,
          iconLight: item.icon_light?.filename,
          iconAlt,
          action: 'download-catalogue',
        }

        return navItem
      }

      // Navigation item (contains link to other page or story)
      // Possibly has sub items
      const subItems: NavigationLinkSubItem[] | undefined = item.sub_navigation?.map(
        (subItem: NavigationSubItemStoryblok) => {
          let link = '/'
          const categorySlug = `${subItem.category?.categorySlug?.[locale!]}`
          if (subItem.use_category) {
            link = pathTranslations.products[locale!]!

            if (subItem.type === 'category') {
              link = `${pathTranslations.products[locale!]}/${categorySlug}`
            } else if (subItem.type === 'promotions') {
              link = pathTranslations.promotions[locale!]!
            }
          } else {
            link =
              subItem.link?.url === '' ? `/${subItem.link?.story?.full_slug}` : subItem.link?.url
          }

          return {
            type: 'link',
            id: subItem._uid,
            description: subItem.title,
            path:
              !activeBU && subItem.category?.categoryKey.startsWith('C10')
                ? `/login?url=${link}`
                : link,
            openInNewWindow: subItem.link?.target ?? subItem.path?.target,
          }
        },
      )

      const navItem: NavigationItem = {
        type: 'link',
        id: item._uid,
        description: item.title,
        path: item.link?.url === '' ? `/${item.link?.story?.full_slug}` : item.link?.url,
        openInNewWindow: item.link?.target ?? item.path?.target,
        subItems,
      }

      return navItem
    }) ?? []
  )
}
