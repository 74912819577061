import download from 'downloadjs'
import {ShippingNoteProps} from '../components/cards/CollapsibleOrderCard'

const formatDate = (timestamp: string) => {
  const date = new Date(timestamp)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}
export const downloadPastOrder = async (shippingNote: ShippingNoteProps, locale: string) => {
  const columns = [
    'id_order',
    'product_name',
    'product_quantity_delivered',
    'product_quantity_validate',
    'product_price',
    'reference',
    'date_upd',
    'article_category',
    'order_reference',
    'ordered_quantity',
    'packaging_code_unit1',
    'ean_unit1',
    'quantity_unit1',
    'packaging_code_unit2',
    'ean_unit2',
    'quantity_unit2',
    'packaging_code_unit3',
    'ean_unit3',
    'quantity_unit3',
  ]

  const data = shippingNote.line_items.map(product => {
    return [
      product.product_id,
      product.product_name[
        locale as keyof {
          en: string
          nl: string
          fr: string
        }
      ] ?? '',
      product.delivered_quantity ?? 1,
      '--',
      product.product_price.replace('.', ','),
      `10${product.product_id}`,
      formatDate(product.date_updated),
      product.article_category,
      shippingNote.shippingnote_number,
      product.ordered_quantity,
      product.unit1_package_code ?? '',
      product.unit1_ean ?? '',
      product.unit1_quantity ?? '',
      product.unit2_package_code ?? '',
      product.unit2_ean ?? '',
      product.unit2_quantity ?? '',
      product.unit3_package_code ?? '',
      product.unit3_ean ?? '',
      product.unit3_quantity ?? '',
    ]
  })

  const responseCSV = [columns.join(';'), data.map(cols => cols.join(';')).join('\n')].join('\n')

  const filename = `Order-Detail-${shippingNote.shippingnote_number}`

  const csvContent = 'data:text/csv;charset=utf-8,' + responseCSV

  download(csvContent, filename)
}
