import {storyblokEditable} from '@storyblok/react'
import {useRouter} from 'next/router'
import type {CompanyUspListStoryblok} from '../../../storyblok-types/component-types-sb'
import USPList from '../../collection/USPList'

const CompanyUSPListBlok = ({blok}: {blok: CompanyUspListStoryblok}) => {
  const {asPath} = useRouter()

  const inAccountPage = asPath.includes('/account/')

  const USPCards = blok.company_usps.map((usp, index) => {
    return {
      id: usp._uid,
      title: usp.title,
      image: usp.pictogram?.filename,
      imageAlt: usp.pictogram?.alt,
      description: usp.description,
      fitParent: {inAccountPage},
      lastCard: index === blok.company_usps.length - 1,
    }
  })

  return (
    <USPList
      path={blok.cta_link?.story?.url}
      sbEditable={storyblokEditable(blok)}
      title={blok.title ?? ''}
      cards={USPCards}
      onAccountDetail={inAccountPage}
    />
  )
}

export default CompanyUSPListBlok
