import {useRef, useState} from 'react'
// @ts-ignore
import Slider from 'react-slick'
import HighlightCard, {HighLightCardProps} from '../../cards/HighlightCard'
import styles from './HighlightCarousel.module.css'
export interface HighlightCarouselProps {
  cards: HighLightCardProps[]
  fullWidthBanner?: boolean
  timeToTransition?: number
}

const HighlightCarousel = ({cards, timeToTransition, fullWidthBanner}: HighlightCarouselProps) => {
  const timeToTransitionActive = timeToTransition !== undefined && timeToTransition !== 0

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: timeToTransitionActive ? true : false,
    cssEase: 'linear',
    navigator: true,
    arrows: true,
    autoplay: timeToTransitionActive ? true : false,
    autoplaySpeed: timeToTransitionActive ? timeToTransition * 1000 : null,
    beforeChange: (_oldIndex: number, newIndex: number) => {
      setImageSlide(newIndex)
    },
  }
  const sliderRef = useRef(null)

  const [imageSlide, setImageSlide] = useState<number>(0)

  const [disableButton, setDisableButton] = useState<boolean>(false)

  return (
    <div className="flex h-full relative items-center">
      {imageSlide !== 0 ? (
        <button
          onClick={() => {
            if (!disableButton) {
              // @ts-ignore
              sliderRef.current?.slickGoTo(imageSlide - 1)
              setImageSlide(imageSlide - 1)
              setDisableButton(true)
              setTimeout(() => {
                setDisableButton(false)
              }, 600)
            }
          }}
          className={`w-12 h-12 flex justify-center items-center z-10 absolute g420:-left-6 left-2 bg-white rounded-full`}
        >
          <div
            className={` w-1/2 h-1/2 rotate-180 ${styles.arrow} bg-no-repeat bg-center bg-contain`}
          ></div>
        </button>
      ) : null}
      <Slider ref={sliderRef} {...settings} className="w-full h-full overflow-hidden rounded-2xl">
        {cards?.map((card, index) => {
          return <HighlightCard fullWidthBanner={fullWidthBanner} key={index} {...card} />
        })}
      </Slider>
      {imageSlide < cards.length - 1 ? (
        <button
          onClick={() => {
            if (!disableButton) {
              // @ts-ignore
              sliderRef.current?.slickGoTo(imageSlide + 1)
              setImageSlide(imageSlide - 1)
              setDisableButton(true)
              setTimeout(() => {
                setDisableButton(false)
              }, 600)
            }
          }}
          className={`w-12 h-12 z-10 flex justify-center items-center absolute g420:-right-6 right-2 bg-white rounded-full`}
        >
          <div className={` w-1/2 h-1/2 ${styles.arrow} bg-no-repeat bg-center bg-contain`}></div>
        </button>
      ) : null}
    </div>
  )
}

export default HighlightCarousel
