import {storyblokEditable} from '@storyblok/js'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import useUser from '../../../lib/hooks/use-user'
import {mapProductData} from '../../../lib/map-product-data'
import {pathTranslations} from '../../../path-translations'
import type {NewProductsCarouselStoryblok} from '../../../storyblok-types/component-types-sb'
import type {ProductCardProps} from '../../cards/ProductCard'
import ProductSlider from '../../collection/ProductSlider'

const NewProductsCarouselBlok = ({blok}: {blok: NewProductsCarouselStoryblok}) => {
  const router = useRouter()

  const title = blok.title

  const {user} = useUser()
  const {activeBU: businessUnitKey, buGroup: customerGroup} = user

  const {data} = useQuery(`new_products`, async () => {
    const response = await fetch(`/api/get-new-products?locale=${router.locale}`)
    return response.json()
  })

  const products = mapProductData(data, router.locale!, businessUnitKey, customerGroup)

  return products && products.length > 0 ? (
    <ProductSlider
      title={title}
      cards={products as ProductCardProps[]}
      seeAllPath={pathTranslations.products[router.locale!]}
      sbEditable={storyblokEditable(blok)}
    />
  ) : null
}

export default NewProductsCarouselBlok
