import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {useForm} from 'react-hook-form'
import {gtmContact} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import ComponentContainer from '../../containers/ComponentContainer'
import Input from '../Input'
import TextArea from '../TextArea/TextArea'

export interface ContactFormData {
  firstname: string
  lastname: string
  email: string
  phone: string
  steetAndNumber: string
  postalAndCity: string
  message: string
}

interface ContactFormProps {}

const ContactFrom = ({}: ContactFormProps) => {
  const router = useRouter()
  const {t} = useTranslation()
  const {register, handleSubmit, formState} = useForm<ContactFormData>()

  const {isSubmitting} = formState

  const onSubmit = handleSubmit(async data => {
    data.message = `<br/><br/>${data.message.replace(/\n/g, '<br/>')}`

    const response = await fetch(`/api/request-visit?locale=${router.locale}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })

    if (response.ok) {
      gtmContact()
      router.push('visit-requested')
    }
  })

  return (
    <ComponentContainer type="medium">
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <Input label={t('contactForm.firstName')} name="firstname" register={register} required />
        <Input label={t('contactForm.lastName')} name="lastname" register={register} required />
        <Input
          label={t('contactForm.email')}
          type="email"
          name="email"
          register={register}
          required
        />
        <Input
          label={t('contactForm.phone')}
          type="tel"
          pattern="(\+[0-9]{2}|00?)\s*[0-9]{3}\s*[0-9]{2}\s*[0-9]{2}\s*[0-9]{2}"
          name="phone"
          register={register}
          required
        />
        <Input
          label={t('contactForm.address')}
          name="steetAndNumber"
          register={register}
          required
        />
        <Input label={t('contactForm.city')} name="postalAndCity" register={register} required />

        <TextArea label="Message" name="message" register={register} required rows={8} />

        <Button
          label={t('contactForm.requestVisit')}
          styling="primary"
          fullWidth
          buttonType="submit"
          disabled={isSubmitting}
        />
      </form>
    </ComponentContainer>
  )
}

export default ContactFrom
