// @ts-ignore
import Slider from 'react-slick'

export interface SliderContainerProps {
  children?: JSX.Element[]
}

const SliderContainer = ({children}: SliderContainerProps) => {
  const settings = {
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    infinite: false,
    navigator: true,
    arrows: false,
  }
  return (
    <Slider {...settings} className="w-full">
      {children}
    </Slider>
  )
}

export default SliderContainer
