import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {Dispatch, SetStateAction} from 'react'
import {useQuery} from 'react-query'
import Button from '../../buttons/Button'
import CollapsibleOrderCard, {CollapsibleOrderCardProps} from '../../cards/CollapsibleOrderCard'
import LoadingSpinner from '../../components/LoadingSpinner'
import DropdownFilter from '../../filters/DropdownFilter'

export interface OrderListProps {
  title: string
  years: {id: string | number; name: string}[]
  orders: CollapsibleOrderCardProps[]
  hasMore: boolean
  loadMore: () => void
  isLoading?: boolean
  setYear: Dispatch<SetStateAction<string>>
}

const OrderList = ({
  title,
  years,
  orders,
  hasMore,
  loadMore,
  isLoading,
  setYear,
}: OrderListProps) => {
  const {t} = useTranslation()

  const {locale} = useRouter()

  const selectYear = (selectId: string) => {
    const year = years.find(year => year.id === selectId)?.name
    setYear(year ?? years[0].name)
  }

  //Get order status from storyblok
  const {data: orderStatuses} = useQuery(
    ['order_status'],
    async () => {
      const statusesStoryblok = await fetch(`/api/get-order-statuses?locale=${locale}`)
      const statusesJson = await statusesStoryblok.json()

      return statusesJson
    },
    {
      cacheTime: 60 * 60 * 1000, // 1 hour
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  )

  return (
    <div className="w-full">
      <div className="flex gap-4 justify-between g768:justify-start w-full">
        <h2>{title}</h2>
        {years && years.length > 0 ? (
          <DropdownFilter selectables={years} onSelect={selectYear} />
        ) : null}
      </div>
      <div className="mt-8 flex-col flex gap-3">
        {orders?.map(order => {
          const {status, ...orderWithoutStatus} = order
          const orderStatusStoryblok = orderStatuses
            ? orderStatuses.find(
                (status: {name: string; value: string; color: string}) =>
                  status.name === order.status?.toLowerCase(),
              )
            : null

          const statusValue = orderStatusStoryblok ? orderStatusStoryblok.value : status

          const statusColor = orderStatusStoryblok ? orderStatusStoryblok.color : '#FDB94A'

          return (
            <CollapsibleOrderCard
              key={order.id}
              statusColor={statusColor}
              status={statusValue}
              {...orderWithoutStatus}
            />
          )
        })}
      </div>
      {isLoading ? (
        <div className="flex justify-center mt-4">
          <LoadingSpinner centerLoader={false} />
        </div>
      ) : null}
      {hasMore && !isLoading ? (
        <div className="flex justify-center w-full mt-12">
          <Button onClick={() => loadMore?.()} styling="primary" label={t('general.moreResults')} />
        </div>
      ) : null}
    </div>
  )
}

export default OrderList
