import {CartProductCardProps} from '../components/cards/CartProductCard'
import {ListProductCardProps} from '../components/cards/ListProductCard'
import {ProductCardProps} from '../components/cards/ProductCard'
import {centPrecision} from './commercetools/ct-utilities'
import {CartProps} from './map-cart-items'
import {ProductPricesWithPromotionItem, ProductPricesWithPromotions} from './service-types'

export interface gtmCommerceItemProps {
  item_id: string
  item_name: string
  affiliation: string
  coupon?: string
  index: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  location_id?: string
  price: number
  quantity: number
  discount?: number
}

export interface gtmCommerceProps {
  event: 'view_item' | 'add_to_cart' | 'remove_from_cart' | 'add_to_wishlist'
  currency: string
  price: number
  items: gtmCommerceItemProps[]
}

export interface gtmPurchaseProps {
  transactionId: string
  tax: number
  currency: string
  price: number
  items: gtmCommerceItemProps[]
}

declare const window: Window & {dataLayer: Record<string, unknown>[]}

const initalCheck = () => {
  if (!window.dataLayer) {
    window.dataLayer = []
  }
}

// When user clicks on tel: link
export const gtmClickToCall = (phone: string) => {
  initalCheck()

  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'click_to_call',
    eventAction: phone,
    eventLabel: '',
    eventValue: 0,
    eventNonInteraction: false,
  })
}

// When user clicks on mailto: link
export const gtmClickToEmail = (email: string) => {
  initalCheck()

  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'click_to_mail',
    eventAction: email,
    eventLabel: '',
    eventValue: 0,
    eventNonInteraction: false,
  })
}

//When user clicks on full width promo banner
export const gtmClickFullWidthBanner = (path: string) => {
  initalCheck()
  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'carrousel_large',
    eventAction: 'click',
    eventLabel: path,
    eventValue: 0,
    eventNonInteraction: false,
  })
}

//When user clicks on carrousel promo
export const gtmClickCarrouselSmall = (action: string, path: string) => {
  initalCheck()
  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'carrousel_small',
    eventAction: action,
    eventLabel: path,
    eventValue: 0,
    eventNonInteraction: false,
  })
}

//When user clicks on folder banner
export const gtmClickBannerFolder = (action: string, path: string) => {
  initalCheck()
  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'banner_folder',
    eventAction: action,
    eventLabel: path,
    eventValue: 0,
    eventNonInteraction: false,
  })
}

// When user submits contact form
export const gtmContact = () => {
  initalCheck()

  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'contact',
    eventAction: 'request_visit',
    eventLabel: '',
    eventValue: 0,
    eventNonInteraction: false,
  })
}

// When user logs in
export const gtmLogin = () => {
  initalCheck()

  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'user',
    eventAction: 'login',
    eventLabel: '',
    eventValue: 0,
    eventNonInteraction: false,
  })
}

// When user resets his password
export const gtmResetPassword = () => {
  initalCheck()

  window.dataLayer.push({
    event: 'GA Tracking',
    eventCategory: 'user',
    eventAction: 'reset_password',
    eventLabel: '',
    eventValue: 0,
    eventNonInteraction: false,
  })
}

// When users views cart
export const gtmViewCart = (cart: CartProps) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: mapCartToECommerce(cart),
  })
}

//When user views product detail page
export const gtmViewProductDetail = (
  pricingKey: 'prices_incl_VAT' | 'prices_excl_VAT',
  productPrices: ProductPricesWithPromotions,
  productInfo: Omit<ProductCardProps, 'onDetailPage' | 'horizontal'>,
  cheapestPromotion?: ProductPricesWithPromotionItem,
) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: mapProductDetailToEcommerce(
      pricingKey,
      productPrices,
      productInfo,
      cheapestPromotion,
    ),
  })
}

//When user adds single product to shopping cart
export const gtmHandleProduct = (
  event: 'add_to_cart' | 'remove_from_cart' | 'add_to_wishlist',
  price: number,
  id: string,
  productName: string,
  brand: string,
  category: string,
  quantity: number,
) => {
  initalCheck()
  const dataObject: gtmCommerceProps = {
    event: event,
    currency: 'EUR',
    price: price,
    items: [
      {
        item_id: id,
        item_name: productName,
        affiliation: 'Fixmer',
        // coupon: '',
        index: 0,
        item_brand: brand!,
        item_category: category,
        // item_category2: '',
        // item_category3: '',
        // item_category4: '',
        // item_category5: '',
        //item_list_id: ''
        //item_list_name: ''
        // location_id: '',
        price: price,
        quantity: quantity,
      },
    ],
  }

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: event,
    ecommerce: dataObject,
  })
}

//When user begins checkout
export const gtmBeginCheckout = (cart: CartProps) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: mapCartToECommerce(cart),
  })
}

//When user adds order list to shopping cart
export const gtmAddOrderlistToCart = (
  tobaccoProducts: ListProductCardProps[],
  nonTobaccoProducts: ListProductCardProps[],
) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: mapOrderListToECommerce(tobaccoProducts, nonTobaccoProducts),
  })
}

//When user adds favorite list to shoppingcart
export const gtmAddFavoriteListToCart = (products: ProductCardProps[]) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: mapFavoriteListToECommerce(products),
  })
}

//When user completes order
export const gtmCompleteOrder = (cart: CartProps, orderId: string) => {
  initalCheck()

  window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: mapOrderCompletionToECommerce(cart, orderId),
  })
}

const mapCartToECommerce = (cart: CartProps) => {
  const tobaccoProducts = cart.tobaccoProducts?.products ?? []
  const nonTobaccoProducts = cart.nonTobaccoProducts?.products ?? []

  const eCommerce = {
    currency: 'EUR', // Altijd EUR?
    value: getPriceFromString(cart.totalVatExcl),
    items: [
      ...tobaccoProducts.map((product, index) =>
        mapProductFromCart(product, index, 'tobacco_products', 'Tobacco Products'),
      ),
      ...nonTobaccoProducts.map((product, index) =>
        mapProductFromCart(
          product,
          index + tobaccoProducts.length,
          'non_tobacco_products',
          'Non tobacco Products',
        ),
      ),
    ],
  }

  return eCommerce
}

const mapOrderCompletionToECommerce = (cart: CartProps, orderId: string) => {
  const tobaccoProducts = cart.tobaccoProducts?.products ?? []
  const nonTobaccoProducts = cart.nonTobaccoProducts?.products ?? []

  const eCommerce = {
    currency: 'EUR',
    transactionId: orderId,
    tax: getPriceFromString(cart?.totalVat),
    price: getPriceFromString(cart?.totalVatExcl),

    items: [
      ...tobaccoProducts.map((product, index) =>
        mapProductFromCart(product, index, 'tobacco_products', 'Tobacco Products'),
      ),
      ...nonTobaccoProducts.map((product, index) =>
        mapProductFromCart(
          product,
          index + tobaccoProducts.length,
          'non_tobacco_products',
          'Non tobacco Products',
        ),
      ),
    ],
  }

  return eCommerce
}

const mapFavoriteListToECommerce = (products: ProductCardProps[]) => {
  const items: gtmCommerceItemProps[] = []
  let totalPriceList = 0
  products.forEach((product, index) => {
    const basePrice = getPriceFromString(product.basePrice!)
    const reducedPrice = product.reducedPrice ? getPriceFromString(product.reducedPrice) : basePrice

    totalPriceList += reducedPrice
    items.push(mapProductFromFavoriteListItem(product, index))
  })

  const eCommerce = {
    currency: 'EUR', // Altijd EUR?
    value: totalPriceList, // Total incl VAT ?
    items: items,
  }

  return eCommerce
}

const mapProductDetailToEcommerce = (
  pricingKey: 'prices_incl_VAT' | 'prices_excl_VAT',
  productPrices: ProductPricesWithPromotions,
  productInfo: Omit<ProductCardProps, 'onDetailPage' | 'horizontal'>,
  cheapestPromotion?: ProductPricesWithPromotionItem,
) => {
  const item: gtmCommerceItemProps = {
    item_id: productInfo.id,
    item_name: productInfo.productName,
    affiliation: 'Fixmer',
    // coupon: '',
    index: 0,
    item_brand: productInfo.brand!,
    item_category: productInfo.category,
    // item_category2: '',
    // item_category3: '',
    // item_category4: '',
    // item_category5: '',
    //item_list_id: ''
    //item_list_name: ''
    // location_id: '',
    price:
      centPrecision(
        productPrices[pricingKey]?.price.cent_amount,
        productPrices[pricingKey]?.price.fraction_digits,
      ) ?? 0,
    quantity: 1,
  }

  if (cheapestPromotion) {
    item.coupon = cheapestPromotion.code
    item.discount =
      item.price -
      centPrecision(
        cheapestPromotion[pricingKey]?.price.cent_amount!,
        cheapestPromotion[pricingKey]?.price.fraction_digits!,
      )
  }

  const eCommerce = {
    currency: productPrices[pricingKey].price.currency_code,
    value:
      centPrecision(
        productPrices[pricingKey]?.price.cent_amount,
        productPrices[pricingKey]?.price.fraction_digits,
      ) ?? 0,
    items: [item],
  }

  return eCommerce
}

const mapOrderListToECommerce = (
  tobaccoProducts: ListProductCardProps[],
  nonTobaccoProducts: ListProductCardProps[],
) => {
  const items: gtmCommerceItemProps[] = []

  let totalPriceList = 0

  tobaccoProducts?.forEach((product, index) => {
    //Todo include product prices
    if (!product.notAvailable) {
      totalPriceList += getPriceFromString(product.price!) ?? 0
      items.push(
        mapProductFromOrderListItem(product, index, 'tobacco_products', 'tobacco Products'),
      )
    }
  })

  nonTobaccoProducts?.forEach((product, index) => {
    if (!product.notAvailable) {
      totalPriceList += getPriceFromString(product.price!) ?? 0

      items.push(
        mapProductFromOrderListItem(
          product,
          index + tobaccoProducts.length,
          'non_tobacco_products',
          'Non tobacco Products',
        ),
      )
    }
  })

  const eCommerce: gtmCommerceProps = {
    event: 'add_to_cart',
    currency: 'EUR',
    price: totalPriceList,
    items: items,
  }

  return eCommerce
}

const mapProductFromCart = (
  product: CartProductCardProps,
  index: number,
  itemListId: string,
  itemListName: string,
) => {
  const basePrice = getPriceFromString(product.basePrice!)
  const reducedPrice = product.reducedPrice ? getPriceFromString(product.reducedPrice) : basePrice

  const discount = basePrice - reducedPrice

  return {
    item_id: product.id,
    item_name: product.productName,
    affiliation: 'Fixmer',
    // coupon: '',
    discount: parseFloat(discount.toFixed(2)),
    index,
    item_brand: product.brand,
    // item_category: '',
    // item_category2: '',
    // item_category3: '',
    // item_category4: '',
    // item_category5: '',
    item_list_id: itemListId,
    item_list_name: itemListName,
    // location_id: '',
    price: parseFloat(reducedPrice.toFixed(2)),
    quantity: product.amount,
  }
}

const mapProductFromOrderListItem = (
  product: ListProductCardProps,
  index: number,
  itemListId: string,
  itemListName: string,
) => {
  return {
    item_id: product.id.toString(),
    item_name: product.productName,
    affiliation: 'Fixmer',
    // coupon: '',
    index,
    item_brand: product.brand,
    // item_category: '',
    // item_category2: '',
    // item_category3: '',
    // item_category4: '',
    // item_category5: '',
    item_list_id: itemListId,
    item_list_name: itemListName,
    // location_id: '',
    price: getPriceFromString(product.price!),
    quantity: product.quantity ?? 1,
  }
}

const mapProductFromFavoriteListItem = (product: ProductCardProps, index: number) => {
  const basePrice = getPriceFromString(product.basePrice!)
  const reducedPrice = product.reducedPrice ? getPriceFromString(product.reducedPrice) : basePrice

  const discount = basePrice - reducedPrice
  return {
    item_id: product.id.toString(),
    item_name: product.productName,
    discount: parseFloat(discount.toFixed(2)),
    affiliation: 'Fixmer',
    // coupon: '',
    index,
    item_brand: product.brand!,
    // item_category: '',
    // item_category2: '',
    // item_category3: '',
    // item_category4: '',
    // item_category5: '',
    // location_id: '',
    price: parseFloat(reducedPrice.toFixed(2)),
    quantity: 1,
  }
}

export const getPriceFromString = (priceString: string): number => {
  return parseFloat(/([0-9]+[\.,][0-9]*)/.exec(priceString)?.[1] ?? '0')
}
