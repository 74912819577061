import {gtmClickToCall, gtmClickToEmail} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import Image from 'next/image'
import {SbEditableProps} from '../../storyblok/blok-props'
export interface CTACardProps extends SbEditableProps {
  image: string
  imageAlt: string
  title: string
  backgroundColor: string
  button?: {label?: string; path: string; openInNewWindow?: boolean}
}

const CTACard = ({image, imageAlt, title, backgroundColor, button, sbEditable}: CTACardProps) => {
  return (
    <div
      style={{backgroundColor: backgroundColor}}
      className="w-full h-full pt-8 pl-8 flex flex-col justify-between rounded-2xl"
      {...sbEditable}
    >
      <div>
        <h4 className="h3-like pr-8 g1024:h4-like">{title}</h4>
        <div className="w-fit">
          {button ? (
            <Button
              className="mt-4 w-fit"
              openInNewWindow={button.openInNewWindow}
              fullWidth
              link={button.path}
              label={button.label}
              styling="primary"
              onClick={() => {
                const path = button.path
                if (path?.startsWith('tel:')) {
                  gtmClickToCall(path.replace(/tel:/g, ''))
                } else if (path?.startsWith('mailto:')) {
                  gtmClickToEmail(path.replace(/mailto:/g, ''))
                }
              }}
            />
          ) : null}
        </div>
      </div>
      <div className="flex items-end justify-end">
        <div className="w-full max-w-[24rem] mt-4 relative h-[12rem]">
          <Image alt={imageAlt} fill className="object-cover" src={image}></Image>
        </div>
      </div>
    </div>
  )
}

export default CTACard
