import Image from 'next/image'
import Link from 'next/link'
import {gtmClickBannerFolder} from '../../../lib/gtm-events'

export interface PDFTeaserCardProps {
  title?: string
  teaser: string
  teaserAlt: string
  path: string
}

const PDFTeaserCard = ({title, teaser, teaserAlt, path}: PDFTeaserCardProps) => {
  return (
    <div
      onClick={() => {
        //Launch gtm on folder click event
        gtmClickBannerFolder(title ?? 'pdf', path)
      }}
      className="w-full h-full"
    >
      <Link
        href={path}
        className="w-full flex flex-col justify-center h-full items-center bg-fixmerWhite   rounded-2xl"
      >
        <div className="aspect-[1.414/1] h-fit my-2.5 w-full  relative">
          <Image fill alt={teaserAlt} className="object-contain" src={teaser}></Image>
        </div>
        <h3 className="py-4">{title}</h3>
      </Link>
    </div>
  )
}

export default PDFTeaserCard
