import Image from 'next/image'
import Link from 'next/link'

export interface CategoryCardProps {
  id: number | string
  title?: string
  icon?: string
  iconAlt?: string
  link: string
}

const CategoryCard = ({title, icon, iconAlt, link}: CategoryCardProps) => {
  return (
    <div
      className={`bg-white px-4 w-full  items-center  h-full group transition-shadow duration-300 hover:shadow-xl  flex justify-center rounded-xl`}
    >
      <Link className="w-full h-full flex flex-col justify-center " href={link}>
        <div className="g1200:my-[3vw] flex flex-col items-center">
          <div className="relative flex group-hover:scale-125 transition-transform  w-14 h-14 g768:w-[4vw] g768:h-[4vw]">
            {icon ? (
              <Image
                fill
                src={icon}
                alt={iconAlt ?? 'icon'}
                className="object-contain object-center fill-slate-200"
              ></Image>
            ) : null}
          </div>
          <h4 className="mt-4 g768:h5-like text-center">{title}</h4>
        </div>
      </Link>
    </div>
  )
}

export default CategoryCard
