import Image from 'next/image'

export interface USPCardProps {
  id: number | string
  title: string
  image?: string
  imageAlt?: string
  description?: string
  lastCard?: boolean
  onAccountDetail?: boolean
}

const USPCard = ({
  title,
  image,
  imageAlt,
  description,
  lastCard,
  onAccountDetail,
}: USPCardProps) => {
  return (
    <div
      className={`w-full pt-6 ${
        onAccountDetail ? 'g1024:px-0' : ''
      } py-6 g1024:py-4 g1024:px-8 g1024:border-b-0 px-2 border-dotted ${
        !lastCard ? 'border-b-2 g1024:border-r-2' : ''
      } border-fixmerLightGrey h-full text-fixmer `}
    >
      <div
        className={`flex g1024:flex-col h-full w-full items-center ${
          image ? 'justify-start' : 'justify-center'
        }`}
      >
        {image ? (
          <div className="relative g1024:w-full mb-4 g1024:mb-6 g1024:h-[14vw] g1024:min-h-[8rem] g1024:min-w-[8rem] min-w-[5rem] min-h-[5rem]">
            <Image fill src={image} alt={imageAlt || 'image'} className="object-contain"></Image>
          </div>
        ) : null}
        <div className="ml-2">
          <h3 className="g1024:h4-like">{title}</h3>
          <p className="mt-2">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default USPCard
