import type {ProductCardProps} from '../../cards/ProductCard'
import ProductCard from '../../cards/ProductCard'
import ComponentContainer from '../../containers/ComponentContainer'
import PreviewContainer from '../../containers/PreviewContainer'
import SliderContainer from '../../containers/SliderContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'
import useProductPrices from './../../../lib/hooks/use-product-prices'

export interface ProductSliderProps extends SbEditableProps {
  title: string
  seeAllPath?: string
  cards?: ProductCardProps[]
}

const ProductSlider = ({title, seeAllPath, cards, sbEditable}: ProductSliderProps) => {
  const productIds = cards?.map(card => card.id) ?? []

  useProductPrices(productIds)

  return (
    <div {...sbEditable}>
      <ComponentContainer type="large" className="caroussel">
        <PreviewContainer title={title} path={seeAllPath}>
          <SliderContainer>
            {cards?.map((card, index) => {
              return (
                <div className={` ${index !== 0 ? 'pl-4' : ''} h-full`} key={card.id}>
                  <ProductCard {...card} horizontal={false} />
                </div>
              )
            })}
          </SliderContainer>
        </PreviewContainer>
      </ComponentContainer>
    </div>
  )
}

export default ProductSlider
