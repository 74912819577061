import {storyblokEditable} from '@storyblok/js'
import type {CtaCardStoryblok} from '../../../storyblok-types/component-types-sb'
import CTACard from '../../cards/CTACard'
import ComponentContainer from '../../containers/ComponentContainer'

const CTACardBlok = ({blok}: {blok: CtaCardStoryblok}) => {
  const button = {
    label: blok.button_label,
    path:
      blok.button?.url == ''
        ? `/${blok.button?.story?.url}`
        : blok.button?.linktype === 'email'
        ? `mailto:${blok.button?.url}`
        : blok.button?.url,
    openInNewWindow: blok.button?.target ? true : blok.button?.target ? true : false,
  }
  return (
    <ComponentContainer type="large">
      <div className="h-fit g768:w-full m-auto">
        <CTACard
          sbEditable={storyblokEditable(blok)}
          title={blok.title}
          imageAlt={blok.image.name}
          image={blok.image.filename}
          button={button}
          backgroundColor={blok.background_color.color}
        ></CTACard>
      </div>
    </ComponentContainer>
  )
}

export default CTACardBlok
