import {useSession} from 'next-auth/react'

const useUser = () => {
  const session = useSession()

  return {
    user: {
      ...session.data?.user,
    },
    loggedIn: session.status === 'authenticated',
    status: session.status,
  }
}

export default useUser
