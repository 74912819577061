import {storyblokEditable} from '@storyblok/js'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import type {ShoppingListByIdQuery, ShoppingListsByBusinessUnitQuery} from '../../../gql-types'
import fetchCtData from '../../../lib/commercetools/fetch-ct-data'
import {QueryType} from '../../../lib/commercetools/query-types'
import useUser from '../../../lib/hooks/use-user'
import type {FavoriteListsStoryblok} from '../../../storyblok-types/component-types-sb'
import {getAndDivideCommercetoolsProductsFromKeys} from '../../../lib/get-commercetools-products-from-keys'

const FavoriteMenuList = dynamic(() => import('../../../components/collection/FavoritesMenuList'))

const FavoriteListsBlok = ({blok}: {blok: FavoriteListsStoryblok}) => {
  const router = useRouter()
  const title = blok.title

  const {user} = useUser()

  const {data: lists} = useQuery(
    `list_data_${user.activeBU}`,
    async () => {
      return fetchCtData<ShoppingListsByBusinessUnitQuery>(QueryType.ShoppingListsByBusinessUnit, {
        locale: router.locale!,
      })
    },
    {
      cacheTime: 60 * 60 * 1000, // 1 hour
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  )

  const {data: selectedList} = useQuery(
    [`selectedList`, router.query.id],
    async () => {
      //Get Product Keys From List
      const list = await fetchCtData<ShoppingListByIdQuery>(QueryType.ShoppingListById, {
        locale: router.locale!,
        id: router.query.id as string,
      })

      const productKeys = list.shoppingList?.lineItems
        .map(item => item.variant?.sku)
        .filter(item => !!item)

      //Get the products from keys and divide them by category
      const ctProducts = await getAndDivideCommercetoolsProductsFromKeys(
        productKeys as string[],
        router.locale!,
      )

      return {
        id: router.query.id as string,
        version: list.shoppingList?.version,
        name: list.shoppingList?.name ?? '',
        ...ctProducts,
      }
    },
    {
      enabled: router.query.id !== undefined,
    },
  )

  const favoriteMenuList = lists?.shoppingLists.results.map(list => {
    return {
      id: list.id,
      name: list.name ?? '',
      amountInList: list.lineItems.filter(item => !!item.variant?.sku).length,
    }
  })

  return (
    <FavoriteMenuList
      sbEditable={storyblokEditable(blok)}
      title={title}
      menuItems={favoriteMenuList}
      selectedList={selectedList}
      createNewLink="/account/my-lists/create-list"
    />
  )
}

export default FavoriteListsBlok
