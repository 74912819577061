import {Disclosure} from '@headlessui/react'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import useUser from '../../../lib/hooks/use-user'
import {dateToShortString} from '../../../lib/utitlities'
import Button from '../../buttons/Button'
import Price from '../../components/Price'
import styles from './CollapsibleInvoiceCard.module.css'
export interface CollapsibleInvoiceCardProps {
  id: number | string
  name: string
  price: string
  timestamp: string
  remark?: string
  documents?: string[]
}

export const CollapsibleInvoiceCard = ({
  id,
  name,
  timestamp,
  remark,
  documents,
  price,
}: CollapsibleInvoiceCardProps) => {
  const {t} = useTranslation()
  const {locale} = useRouter()

  const {user} = useUser()

  const date = new Date(timestamp)

  const downloadDocument = async (documentName: string) => {
    const response = await fetch(
      `/api/get-document-azure-storage?name=${user.activeBU}/${documentName}`,
    )

    const blobDocument = await response.json()
    const link = document.createElement('a')
    link.href = blobDocument.url
    link.download = blobDocument.originalFileName
    link.target = '_blank'
    link.click()
  }

  return (
    <Disclosure key={id}>
      {({open}) => (
        <div className={`${styles.shadow} rounded-x-3xl rounded-3xl rounded-t-3xl`}>
          <Disclosure.Button className="w-full">
            <div
              className={`h-fit 2xl:p-[1vw] flex flex-col p-4 g768:py-4 ${
                open ? 'rounded-x-3xl rounded-t-3xl' : 'rounded-3xl'
              } w-full cursor-pointer bg-fixmerWhite `}
            >
              <div className="mb-0 flex w-full items-center justify-between text-left font-bold normal-case">
                <div className="flex flex-row items-center justify-between w-full flex-wrap gap-3">
                  <h4 className="h3-like h-full g1280:h5-like">
                    <span className="g768:block hidden">
                      {t('invoiceHistory.title', {
                        name: name,
                        date: dateToShortString(date, locale!, '/'),
                      })}
                    </span>
                    <span className="g768:hidden block">
                      {dateToShortString(date, locale!, '/')}
                    </span>
                  </h4>
                  <div className="flex items-center gap-4">
                    <Price price={price} styling="small" />
                    <span
                      className={`flex transition-all ml-4 w-3 h-3 bg-no-repeat bg-center bg-contain ${
                        styles.arrow
                      } ${open ? 'rotate-0' : 'rotate-180'}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Button>
          <div>
            <Disclosure.Panel
              className={`pt-2 pb-4 px-4 2xl:py-[0.8vw] rounded-x-3xl rounded-b-3xl  bg-fixmerWhite`}
            >
              <p>{remark}</p>
              <div className="w-full flex mt-2 flex-wrap gap-2">
                {documents?.map(document => {
                  return (
                    <Button
                      key={document}
                      downloadIcon
                      iconRight
                      onClick={() => downloadDocument(document)}
                      hideChevron
                      download
                      label={`Download ${document}`}
                      styling="primary"
                    />
                  )
                })}
              </div>
            </Disclosure.Panel>
          </div>
        </div>
      )}
    </Disclosure>
  )
}

export default CollapsibleInvoiceCard
