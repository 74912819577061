import type {ErrorInfo, ReactNode} from 'react'
import * as Sentry from '@sentry/nextjs'
import {Component} from 'react'
import Head from 'next/head'
import Status from './components/Status'
import ComponentContainer from './containers/ComponentContainer'
import ContentContainer from './containers/ContentContainer'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error: ', error, errorInfo)
    Sentry.captureException(errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Head>
            <title>Server error</title>
          </Head>
          <main className="h-screen flex items-center w-screen">
            <div className="min-h-[50vh] w-full mt-4 g768:min-h-[60vh]">
              <ContentContainer roundedTopDesktop>
                <ComponentContainer type="medium" noPadding>
                  <Status
                    title="Oops.."
                    description="Something went wrong"
                    buttonLabel="return to homepage"
                  />
                </ComponentContainer>
              </ContentContainer>
            </div>
          </main>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
