export interface ComponentContainer {
  type: 'fullscreen' | 'large' | 'medium' | 'full'
  children: JSX.Element | JSX.Element[]
  className?: string
  noPadding?: boolean
}

const ComponentContainer = ({type, children, className = '', noPadding}: ComponentContainer) => {
  return (
    <div
      className={`flex justify-center ${
        !noPadding ? 'my-12 g768:my-[5vw] 2xl:my-[3.5vw] g1680:my-[4.5vw]' : ''
      }  componentContainer ${
        type === 'fullscreen'
          ? 'w-screen px-xSmall'
          : type === 'large'
          ? `w-[90vw] g768:w-[85vw] m-auto `
          : type === 'full'
          ? 'w-full h-full'
          : 'g768:w-3/5 g768:min-w-[40rem] w-[90vw] m-auto'
      }`}
    >
      <div className={`w-full ${className}`}>{children}</div>
    </div>
  )
}

export default ComponentContainer
