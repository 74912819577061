import {storyblokEditable} from '@storyblok/react'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import useInStoryblokEditor from '../../../lib/hooks/use-in-storyblok-editor'
import useUser from '../../../lib/hooks/use-user'
import {fetchSalesContactByEmail} from '../../../lib/storyblok-api'
import type {SalesContactStoryblok} from '../../../storyblok-types/component-types-sb'
import ContactCard from '../../cards/ContactCard'

const SalesContactBlok = ({
  fitParent,
  blok,
}: {
  blok?: SalesContactStoryblok
  fitParent?: boolean
}) => {
  const {inStoryblokEditor} = useInStoryblokEditor()
  const {user} = useUser()
  const {locale} = useRouter()

  const salesContactEmail = inStoryblokEditor ? 'test.contact@sales.com' : user.salesContact

  const queryEnabled =
    !!salesContactEmail && (!blok || (!inStoryblokEditor && blok.use_user_contact_info))

  const {data: salesContactResponse, isLoading: isSalesContactLoading} = useQuery(
    'salesContact',
    async () => fetchSalesContactByEmail(salesContactEmail!, locale!),
    {
      enabled: queryEnabled,
    },
  )

  if (!isSalesContactLoading && salesContactEmail && !salesContactResponse) {
    const msg = `No sales contact blok found for email: ${salesContactEmail}`
    console.warn(msg)
  }

  const salesContact = queryEnabled ? salesContactResponse! : blok!

  if (!salesContact) {
    return null
  }

  return (
    <ContactCard
      sbEditable={blok ? storyblokEditable(blok) : undefined}
      firstName={salesContact.first_name}
      lastName={salesContact.last_name}
      position={salesContact.function}
      phone={salesContact.phone}
      email={salesContact.email}
      image={salesContact.image.filename}
      imageAlt={salesContact.image.alt}
      fitParent={fitParent}
    />
  )
}

export default SalesContactBlok
