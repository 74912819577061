import type {PromoCardProps} from '../../cards/PromoCard'
import PromoCard from '../../cards/PromoCard'
import ComponentContainer from '../../containers/ComponentContainer'
import PreviewContainer from '../../containers/PreviewContainer'
import SliderContainer from '../../containers/SliderContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface PromoListProps extends SbEditableProps {
  title: string
  seeAllPath?: string
  noPadding?: boolean
  cards?: PromoCardProps[]
}

const PromoListProps = ({title, noPadding, seeAllPath, cards, sbEditable}: PromoListProps) => {
  return (
    <div {...sbEditable}>
      <ComponentContainer type="large" className="caroussel" noPadding={noPadding}>
        <PreviewContainer title={title} path={seeAllPath}>
          <SliderContainer>
            {cards?.map((card, index) => {
              return (
                <div className={` ${index !== 0 ? 'pl-4' : ''} h-full`} key={card.id}>
                  <PromoCard
                    id={card.id}
                    image={card.image}
                    imageAlt={card.imageAlt}
                    label={card.label}
                    description={card.description}
                    link={card.link}
                    imageFill={card.imageFill}
                  />
                </div>
              )
            })}
          </SliderContainer>
        </PreviewContainer>
      </ComponentContainer>
    </div>
  )
}

export default PromoListProps
