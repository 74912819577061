import {storyblokEditable} from '@storyblok/react'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import type {QuoteStoryblok} from '../../../storyblok-types/component-types-sb'

const Quote = dynamic(() => import('../../components/Quote'))

const QuoteBlok = ({blok}: {blok: QuoteStoryblok}) => {
  const {asPath} = useRouter()

  const inAccountPage = asPath.includes('/account/')

  return (
    <Quote
      sbEditable={storyblokEditable(blok)}
      quote={blok.quote}
      author={blok.author_name}
      profession={blok.author_function}
      image={blok.author_image.filename}
      imageAlt={blok.author_image.alt ?? blok.author_image.name}
      fitParent={inAccountPage}
    />
  )
}

export default QuoteBlok
