import {CartProductCardProps} from '../components/cards/CartProductCard'
import {ListProductCardProps} from '../components/cards/ListProductCard'

export const sortProductsByCategory = (
  products: ListProductCardProps[],
  categories: {
    key?: string | null
    name?: string | null
  }[],
) => {
  const productsSorted: Record<string, ListProductCardProps[] | undefined> = {}

  categories.forEach(category => {
    const productsInCategory = products
      .filter(product => product.category?.includes(category.key as string))
      .sort((a, b) => {
        // Sort by brand
        if (a.brand && b.brand && a.brand !== b.brand) {
          return a.brand.localeCompare(b.brand)
        }
        // Then by product name
        return a.productName.localeCompare(b.productName)
      })
    if (productsInCategory.length > 0) {
      productsSorted[category.name as string] = productsInCategory
    }
  })

  //Check if there are products with no category. if so assign to custom group
  const productsNoCategory = products
    .filter(product => product.category === '')
    .sort((a, b) => {
      // Sort by brand
      if (a.brand && b.brand && a.brand !== b.brand) {
        return a.brand.localeCompare(b.brand)
      }
      // Then by product name
      return a.productName.localeCompare(b.productName)
    })

  if (productsNoCategory.length > 0) {
    productsSorted['Other'] = productsNoCategory
  }

  return productsSorted
}

export const sortproductsByCategoryCart = (
  products: CartProductCardProps[],
  categories: {
    key?: string | null
    name?: string | null
  }[],
) => {
  const productsSorted: Record<string, CartProductCardProps[] | undefined> = {}

  categories.forEach(category => {
    const productsInCategory = products
      .filter(product => product.categoryKey?.includes(category.key as string))
      .sort((a, b) => {
        // Sort by brand
        if (a.brand && b.brand && a.brand !== b.brand) {
          return a.brand.localeCompare(b.brand)
        }
        // Then by product name
        return a.productName.localeCompare(b.productName)
      })
    if (productsInCategory.length > 0) {
      productsSorted[category.name as string] = productsInCategory
    }
  })

  //Check if there are products with no category. if so assign to custom group
  const productsNoCategory = products
    .filter(product => product.categoryKey === '')
    .sort((a, b) => {
      // Sort by brand
      if (a.brand && b.brand && a.brand !== b.brand) {
        return a.brand.localeCompare(b.brand)
      }
      // Then by product name
      return a.productName.localeCompare(b.productName)
    })

  if (productsNoCategory.length > 0) {
    productsSorted['Other'] = productsNoCategory
  }

  return productsSorted
}
