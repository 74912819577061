import {storyblokEditable} from '@storyblok/js'
import type {FaqListStoryblok} from '../../../storyblok-types/component-types-sb'
import RichText from '../../RichText'
import CollapsibleList from '../../collection/CollapsibleList'

const FaqListBlok = ({blok}: {blok: FaqListStoryblok}) => {
  const FAQCards = blok.faq_items.map(faq => {
    return {
      id: faq._uid,
      title: faq.title,
      description: <RichText richText={faq.description} />,
      openDefault: faq.open_by_default,
    }
  })

  return <CollapsibleList sbEditable={storyblokEditable(blok)} cards={FAQCards} />
}

export default FaqListBlok
