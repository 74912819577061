import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import type {HomepageStoryblok} from '../../../storyblok-types/component-types-sb'

const Homepage = ({blok}: HomepageStoryblok) => (
  <main {...storyblokEditable(blok)}>
    {blok.body
      ? blok.body.map((blok: any) => <StoryblokComponent blok={blok} key={blok._uid} />)
      : null}
  </main>
)

export default Homepage
