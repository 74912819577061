import {useRouter} from 'next/router'
import PQueue from 'p-queue'
import pRetry from 'p-retry'
import {
  MutableRefObject,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {QueryClient, QueryKey, useMutation, useQuery, useQueryClient} from 'react-query'
import {CartsOnlyIdsAndQuantityQuery} from '../../gql-types'
import fetchCtData from '../commercetools/fetch-ct-data'
import {QueryType} from '../commercetools/query-types'
import useUser from '../hooks/use-user'
import {CartProps, mapCart} from '../map-cart-items'
import {TFunction, useTranslation} from 'next-i18next'

export type CartContextType = {
  cartId?: string
  cartProductQuantities: Record<string, {lineItemId: string; quantity: number}>
  cartProductsCount: number
  cartQueryKeys: Record<CartQueryKey, QueryKey>
  isCartLoading: boolean
  isPlacingOrder: boolean
  cartWithPrices: CartWithPrices
  cartAddProduct: (productKey: string, categoryKey: string, quantity?: number) => Promise<void>
  cartChangeQuantity: (productKey: string, quantity: number) => Promise<void>
  cartRemoveProduct: (productKey: string) => Promise<void>
  cartClear: () => Promise<void>
  cartAddProductList: (
    productKeys: string[],
    categoryKeys: string[],
    quantities?: number[],
    onSuccess?: () => void,
    onError?: () => void,
  ) => Promise<void>
  cartCreateOrder: (
    orderConfirmationLink: string,
    deliveryDate?: string,
    onError?: () => void,
  ) => Promise<string>
}

type CartWithPrices = {
  cart: CartProps | undefined
  cartError: Error | null
  isCartLoading: boolean
  isCartFetching: boolean
}

const initialCart: CartContextType = {
  cartId: undefined,
  cartProductQuantities: {},
  cartProductsCount: 0,
  cartQueryKeys: {
    quantities: '',
    subtotals: '',
    promo_benefits: '',
    product_price_with_promotions: '',
  },
  cartWithPrices: {
    cart: undefined,
    cartError: null,
    isCartFetching: false,
    isCartLoading: false,
  },
  isCartLoading: false,
  isPlacingOrder: false,
  cartAddProduct: async () => {},
  cartChangeQuantity: async () => {},
  cartRemoveProduct: async () => {},
  cartClear: async () => {},
  cartAddProductList: async () => {},
  cartCreateOrder: async () => '',
}

type CartQueryKey = 'quantities' | 'subtotals' | 'promo_benefits' | 'product_price_with_promotions'

type CartQuantitiesQueryResult =
  | Pick<CartContextType, 'cartId' | 'cartProductQuantities' | 'cartProductsCount'>
  | undefined

type CartChangeQuantityArgs = {
  productKey: string
  quantity: number
}

type CartAddProductListArgs = {
  productKeys: string[]
  quantities?: number[]
}

const pQueue = new PQueue({
  concurrency: 1,
})

const DEBOUNCE_TIMEOUT_MS = 300

export const CartContext = createContext<CartContextType>(initialCart)

export const CartContextProvider: React.FC = ({children}) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const {t} = useTranslation()
  const {user} = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)

  // Query keys
  const queryKeys = useMemo<Record<CartQueryKey, QueryKey>>(
    () => ({
      quantities: ['cart', 'quantities', user.activeBU],
      subtotals: ['cart', 'subtotals', user.activeBU],
      promo_benefits: ['cart', 'promo_benefits', user.activeBU],
      product_price_with_promotions: ['cart', 'product_price_with_promotions', user.activeBU],
    }),
    [user.activeBU],
  )

  // Timer refs and timeouts for debouncing (timer per product key)
  const debounceTimers = useRef<Record<string, NodeJS.Timeout | undefined>>({})

  // Invalidates all cart related queries
  const invalidateCartQueries = useCallback(async () => {
    await queryClient.invalidateQueries('cart')
  }, [queryClient])

  useEffect(() => {
    // Refetch latest cart state by invalidating queries when...
    //  - no more api calls are pending (promise queue is empty)
    //  - no more debounce timers are running
    pQueue.on('idle', async () => {
      const hasDebounceTimersRunning = Object.keys(debounceTimers.current).length > 0

      if (!hasDebounceTimersRunning) {
        setIsLoading(false)
        await invalidateCartQueries()
      }
    })

    return () => {
      pQueue.removeAllListeners()
    }
  }, [invalidateCartQueries])

  // Fetch from CT: cart id, total line item quantity, and quantity for each lineItem
  const {data: cartQuantities} = useQuery({
    queryKey: queryKeys.quantities,
    queryFn: () => fetchCartProductQuantities(),
    enabled: !!user.activeBU,
    onError: error => console.error(error),
  })

  // Query to get cart with subtotals
  const {
    data: cart,
    error: cartError,
    isLoading: isCartLoading,
    isFetching: isCartFetching,
  } = useQuery<CartProps, Error>({
    queryKey: [...queryKeys.subtotals, cartQuantities?.cartId],
    queryFn: () => fetchCartWithSubTotals(cartQuantities!.cartId!, router.locale!, t),
    enabled: !!user.activeBU && !!cartQuantities?.cartId && !!cartQuantities.cartProductsCount,
    cacheTime: 60 * 60 * 1000, // 1 hour
    staleTime: 60 * 60 * 1000, // 1 hour
    retryDelay: attemptIndex => Math.min(500 * 2 ** attemptIndex, 15000),
    retry: (_, error) => error.message === t('cart.retry'),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
  })

  const cartWithPrices = {
    cart,
    cartError,
    isCartLoading,
    isCartFetching,
  }

  const {mutateAsync: placeOrderMutation} = useMutation(
    'place_order',
    async (deliveryDate?: string) => {
      const params = new URLSearchParams()

      if (deliveryDate) {
        params.set('deliveryDate', deliveryDate)
      }

      return fetch(`/api/place-order?${params.toString()}`)
    },
  )
  // Add new product to cart
  const cartAddProduct = async (productKey: string, categoryKey: string, quantity?: number) => {
    setIsLoading(true)

    // Update UI optimistacally
    await optimisticChangeQuantity(queryClient, queryKeys.quantities, {
      productKey,
      quantity: quantity ?? 1,
    })

    // Add API call to promise queue, no debounce, higher priority
    queueApiCall(
      `/api/cart/add-product?productKey=${productKey}&locale=${router.locale}&quantity=${
        quantity ?? 1
      }&categoryKey=${categoryKey}`,
      pQueue,
      {
        priority: 1,
      },
    )
  }

  // Change quantity of product in cart
  const cartChangeQuantity = async (productKey: string, quantity: number) => {
    setIsLoading(true)

    // Update UI optimistically
    await optimisticChangeQuantity(queryClient, queryKeys.quantities, {
      productKey,
      quantity,
    })

    // Add API call to promise queue, debounced
    queueApiCall(
      `/api/cart/change-quantity?productKey=${productKey}&quantity=${quantity}&locale=${router.locale}`,
      pQueue,
      {
        debounce: {
          timeMs: DEBOUNCE_TIMEOUT_MS,
          timersRef: debounceTimers,
          timerKey: productKey,
        },
      },
    )
  }

  // Remove product from cart
  const cartRemoveProduct = async (productKey: string) => {
    setIsLoading(true)

    // Update UI optimistically
    await optimisticChangeQuantity(queryClient, queryKeys.quantities, {
      productKey,
      quantity: 0,
    })

    // Add api call to promise queue, debounced
    queueApiCall(`/api/cart/remove-product?productKey=${productKey}`, pQueue, {
      debounce: {
        timeMs: DEBOUNCE_TIMEOUT_MS,
        timersRef: debounceTimers,
        timerKey: productKey,
      },
    })
  }

  // Clear all products in cart
  const cartClear = async () => {
    setIsLoading(false)

    // Cancel all pending cart operations
    pQueue.clear()

    // Clear the cart
    queueApiCall('/api/cart/delete', pQueue)
  }

  // Add a list of products to cart
  const cartAddProductList = async (
    productKeys: string[],
    categoryKeys: string[],
    quantities?: number[],
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    setIsLoading(true)

    // Update UI optimistically
    await optimisticAddProductList(queryClient, queryKeys.quantities, {
      productKeys,
      quantities,
    })

    // Split large lists into multiple calls
    let page = 0
    const productsPerPage = 100

    while (page * productsPerPage < productKeys.length) {
      // Build url
      const params = new URLSearchParams()

      const start = page * productsPerPage
      const end = start + productsPerPage

      params.append('productKeys', productKeys.slice(start, end).join(';'))
      params.append('categoryKeys', categoryKeys.slice(start, end).join(';'))

      if (quantities) {
        params.append('quantities', quantities.slice(start, end).join(';'))
      }

      // Add API call to promise queue
      queueApiCall(`/api/cart/add-list-with-quantities?${params.toString()}`, pQueue, {
        onSuccess,
        onError,
      })

      page = page + 1
    }
  }

  // Create order from cart
  const cartCreateOrder = async (
    orderConfirmationLink: string,
    deliveryDate?: string,
    onError?: () => void,
  ) => {
    const response = await placeOrderMutation(deliveryDate, {
      onSuccess: async response => {
        if (response.ok) {
          router.events.on('routeChangeStart', () => {
            setIsPlacingOrder(true)
          })
          router.push(orderConfirmationLink, undefined)
          router.events.on('routeChangeComplete', () => {
            setIsPlacingOrder(false)
          })
        } else {
          onError?.()
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKeys.quantities)
        await queryClient.invalidateQueries(queryKeys.subtotals)
      },
    })
    const json = await response.json()
    return json.createOrderFromCart.id as string
  }

  // Provided context
  const providedContext: CartContextType = {
    cartId: cartQuantities?.cartId,
    cartProductsCount: cartQuantities?.cartProductsCount ?? 0,
    cartProductQuantities: cartQuantities?.cartProductQuantities ?? {},
    cartQueryKeys: queryKeys,
    cartWithPrices,
    isCartLoading: isLoading,
    isPlacingOrder,
    cartAddProduct,
    cartChangeQuantity,
    cartRemoveProduct,
    cartClear,
    cartAddProductList,
    cartCreateOrder,
  }

  return <CartContext.Provider value={providedContext}>{children}</CartContext.Provider>
}

// Fetch functions

const fetchCartProductQuantities = async (): Promise<CartQuantitiesQueryResult> => {
  const response = await fetchCtData<CartsOnlyIdsAndQuantityQuery>(
    QueryType.CartsOnlyIdsAndQuantity,
  )

  const cart = response.asAssociate.carts.results[0]

  if (!cart) {
    return undefined
  }

  const cartProductQuantities: CartContextType['cartProductQuantities'] = {}

  cart?.lineItems?.forEach(lineItem => {
    if (lineItem.productKey) {
      cartProductQuantities[lineItem.productKey] = {
        lineItemId: lineItem.id,
        quantity: lineItem.quantity,
      }
    }
  })

  return {
    cartId: cart?.id,
    cartProductQuantities,
    cartProductsCount: cart?.totalLineItemQuantity ?? 0,
  }
}

const fetchCartWithSubTotals = async (cartId: string, locale: string, t: TFunction) => {
  const response = await fetch(
    `/api/get-shopping-cart-with-subtotals?cartId=${cartId}&locale=${locale}`,
  )

  if (response.status === 409 || response.status === 404) {
    throw new Error(t('cart.retry'))
  }

  if (response.status === 424) {
    throw new Error('cart_needs_reset')
  }

  if (!response.ok) {
    throw new Error(t('cart.fetchError'))
  }

  const json = await response.json()

  return mapCart(json.data?.[0], locale ?? 'nl')
}

// Optimistically update the cart state for a product quantity change, returns snapshot of the old state
const optimisticChangeQuantity = async (
  queryClient: QueryClient,
  queryKey: QueryKey,
  args: CartChangeQuantityArgs,
) => {
  const previousState = await optimisticMutate<CartChangeQuantityArgs, CartQuantitiesQueryResult>(
    queryClient,
    queryKey,
    args,
    (args, oldState) => {
      if (!oldState) return oldState

      const {productKey, quantity = 1} = args

      const oldQuantity = oldState.cartProductQuantities[productKey]?.quantity ?? 0

      const newState: CartQuantitiesQueryResult = {
        ...oldState,
        cartProductsCount: oldState.cartProductsCount - oldQuantity + quantity,
        cartProductQuantities: {
          ...oldState.cartProductQuantities,
          [productKey]: {
            lineItemId: '',
            quantity: quantity,
          },
        },
      }

      return newState
    },
  )

  return previousState
}

// Optimistically update the cart state when adding a list of products to cart, returns snapshot of the old state
const optimisticAddProductList = async (
  queryClient: QueryClient,
  queryKey: QueryKey,
  args: CartAddProductListArgs,
) => {
  const previousState = await optimisticMutate<CartAddProductListArgs, CartQuantitiesQueryResult>(
    queryClient,
    queryKey,
    args,
    (args, oldState) => {
      const {productKeys, quantities} = args

      const listQuantity = quantities?.reduce((sum, item) => sum + item, 0) ?? 0

      const newState: CartQuantitiesQueryResult = oldState
        ? {
            ...oldState,
            cartProductsCount: oldState.cartProductsCount + listQuantity,
          }
        : {
            cartProductQuantities: {},
            cartProductsCount: listQuantity,
          }

      productKeys.forEach((productKey, i) => {
        const currentItem = newState.cartProductQuantities[productKey]
        const quantity = quantities?.[i] ?? 1

        newState.cartProductQuantities[productKey] = {
          lineItemId: currentItem?.lineItemId ?? '',
          quantity: (currentItem?.quantity ?? 0) + quantity,
        }
      })

      return newState
    },
  )

  return previousState
}

// Allows for optimistic ui updates
// See: https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
const optimisticMutate = async <TVariables, TContext>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  args: TVariables,
  mutateFn: (args: TVariables, oldState?: TContext) => TContext,
): Promise<TContext | undefined> => {
  // Cancel any outgoing refetches (so they don't overwrite the optimistic update)
  await queryClient.cancelQueries({queryKey})

  // Snapshot the previous state
  const snapshot = queryClient.getQueryData<TContext>(queryKey)

  // Optimistically update to the new state
  // This function should mock the api operations of the mutation
  queryClient.setQueryData<TContext>(queryKey, old => mutateFn(args, old))

  // Return a context object with the snapshotted state
  return snapshot
}

// Adds API call to promise queue
// Subsequest calls with the same debounce timerKey are debounced, only the last one will execute after timeMs has passed
const queueApiCall = (
  url: string,
  pQueue: PQueue,
  options?: {
    priority?: number
    retries?: number
    debounce?: {
      timersRef: MutableRefObject<Record<string, NodeJS.Timeout | undefined>>
      timerKey: string
      timeMs: number
    }
    onSuccess?: () => void
    onError?: () => void
  },
) => {
  // Default options
  const {priority = 0, retries = 3, debounce = undefined} = options ?? {}

  // Reset debounce timer for product key
  if (debounce && debounce.timersRef.current[debounce.timerKey]) {
    clearTimeout(debounce.timersRef.current[debounce.timerKey])
  }

  // Adds promise to the promise queue with specified priority and number of retries
  const enqueue = () =>
    pQueue.add(
      () =>
        pRetry(
          () =>
            fetch(url)
              .then(response => {
                if (response.ok) {
                  return response.text()
                }
                return Promise.reject(response)
              })
              .then(_json => {
                options?.onSuccess?.()
              })
              .catch((response: Response) => {
                response.text().then(text => {
                  console.error(`Failed to fetch "${url}" (${text})`)
                })
                options?.onError?.()
              }),
          {
            retries,
          },
        ),
      {
        priority,
      },
    )

  if (!debounce) {
    // If not debounced, enqueue immediately
    enqueue()
  } else {
    // If debounced, enqueue after timeout has passed
    debounce.timersRef.current[debounce.timerKey] = setTimeout(() => {
      enqueue()
      delete debounce.timersRef.current[debounce.timerKey]
    }, debounce.timeMs)
  }
}
