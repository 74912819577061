import React from 'react'
import {useQuery} from 'react-query'
import type {AccountQuery, AddressFragment} from '../../gql-types'
import fetchCtData from '../../lib/commercetools/fetch-ct-data'
import {QueryType} from '../../lib/commercetools/query-types'
import useInStoryblokEditor from '../../lib/hooks/use-in-storyblok-editor'
import type {SbEditableProps} from '../storyblok/blok-props'
import SalesContactBlok from '../storyblok/page/SalesContactBlok'
import useUser from './../../lib/hooks/use-user'

export interface AccountDetails extends SbEditableProps {
  rows: {label: string; value: string}[]
  hideSalesContact?: boolean
  salesContactHeader?: string
}

const AccountDetails: React.FC<AccountDetails> = ({
  sbEditable,
  rows,
  hideSalesContact = false,
  salesContactHeader,
}) => {
  const {user} = useUser()
  const {inStoryblokEditor} = useInStoryblokEditor()

  // Fetch account information
  const {data: customerResponse} = useQuery(
    `customer_${user.activeBU}`,
    async () => fetchCtData<AccountQuery>(QueryType.Account),
    {
      enabled: !!user.activeBU,
    },
  )

  const replaceValue = (value: string) => {
    if (inStoryblokEditor) {
      return value
    }

    switch (value) {
      case '{{firstName}}':
        return customerResponse?.customer?.firstName ?? ''
      case '{{lastName}}':
        return customerResponse?.customer?.lastName ?? ''
      case '{{email}}':
        return customerResponse?.customer?.email ?? ''
      case '{{company}}':
        return `${customerResponse?.businessUnit?.name} \n ${user.activeBU}` ?? ''
      case '{{billingAddress}}':
        return customerResponse?.businessUnit?.defaultBillingAddress
          ? addressToString(customerResponse.businessUnit.defaultBillingAddress)
          : ''
      case '{{shippingAddress}}':
        return customerResponse?.businessUnit?.defaultShippingAddress
          ? addressToString(customerResponse.businessUnit.defaultShippingAddress)
          : ''
      default:
        return value
    }
  }

  const dataRows: {label: string; value: string; spacing?: boolean}[] = rows.map(row => ({
    label: row.label,
    value: replaceValue(row.value),
  }))

  const salesContact = hideSalesContact ? null : <SalesContactBlok fitParent />

  return (
    <div className="grid grid-cols-9" {...sbEditable}>
      <div className="col-span-9 h-fit mb-4 g768:mb-0 flex flex-col gap-4 rounded-2xl bg-fixmerWhite p-8 g1024:px-0 g1200:col-span-5 ">
        {dataRows.map((data, index) => {
          return (
            <React.Fragment key={`${index}_${data.label}`}>
              <div className="flex flex-wrap">
                <span
                  className={`whitespace-pre-line g768:w-[12rem] w-[8rem] break-words font-semiBold text-fixmerGrey ${
                    data.spacing ? 'mt-4' : ''
                  }`}
                >
                  {data.label}
                </span>
                {data.label === 'Email' ? (
                  <div className={`w-full flex g768:w-1/2 flex-wrap ${data.spacing ? 'mt-4' : ''}`}>
                    <span>{data.value.split('@')[0]}@</span>
                    <span>{data.value.split('@')[1]}</span>
                  </div>
                ) : (
                  <span
                    className={`whitespace-pre-line w-[12rem] g768:w-1/2 break-words  ${
                      data.spacing ? 'mt-4' : ''
                    }`}
                  >
                    {data.value}
                  </span>
                )}
              </div>
              {}
            </React.Fragment>
          )
        })}
      </div>

      <div className="col-span-9 mt-4 g768:mt-8 g1200:col-span-4 g1200:mt-0">
        {salesContact ? (
          <>
            {salesContactHeader ? <h3 className="g1200:hidden">{salesContactHeader}</h3> : null}
            <div className="mt-4">{salesContact}</div>
          </>
        ) : null}
      </div>
    </div>
  )
}

const addressToString = (addres: AddressFragment) => {
  return `${addres.streetName ?? ''} ${addres.streetNumber ?? ''}\n${addres.postalCode ?? ''} ${
    addres.city ?? ''
  }`
}

export default AccountDetails
