import classNames from 'classnames'
import styles from './LoadingSpinner.module.css'

export type LoadingSpinnerProps = {
  centerLoader?: boolean
}

const LoadingSpinner = ({centerLoader = true}: LoadingSpinnerProps) => {
  const spinnerContainer = centerLoader
    ? classNames('inline-block h-full w-full flex items-center justify-center')
    : classNames('inline-block w-20 h-20')

  const spinnerItem = classNames(
    'box-border block absolute w-16 h-16 m-2 border-transparent rounded-full',
  )

  return (
    <div className={`${spinnerContainer} ${styles.ringContainer}`}>
      <div className={`${spinnerItem} ${styles.ringItem}`} />
      <div className={`${spinnerItem} ${styles.ringItem}`} />
      <div className={`${spinnerItem} ${styles.ringItem}`} />
      <div className={`${spinnerItem} ${styles.ringItem}`} />
    </div>
  )
}

export default LoadingSpinner
