import {useRouter} from 'next/router'
import {useEffect} from 'react'

// This hooks saves the current locale in the NEXT_LOCALE cookie
const useLocaleCookie = () => {
  const {locale} = useRouter()

  useEffect(() => {
    document.cookie = `NEXT_LOCALE=${locale!}`
  }, [locale])
}

export default useLocaleCookie
