import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {useState} from 'react'
import {useInfiniteQuery, useQuery} from 'react-query'
import {priceToString} from '../../../lib/commercetools/ct-utilities'
import useUser from '../../../lib/hooks/use-user'
import {CosmosInvoice, Translations} from '../../../lib/service-types'
import {InvoiceListStoryblok} from '../../../storyblok-types/component-types-sb'
import {CollapsibleInvoiceCardProps} from '../../cards/CollapsibleInvoiceCard'
import InvoiceList from '../../collection/InvoiceList'
import LoadingSpinner from '../../components/LoadingSpinner'

const INVOICES_PER_PAGE = 10

const InvoiceListBlok = ({blok}: {blok: InvoiceListStoryblok}) => {
  const router = useRouter()
  const {user} = useUser()
  const {t} = useTranslation('common')

  const [year, setYear] = useState('')

  const title = blok.title

  //Fetch all distinct years where the user has invoices. When done set the latest year as default
  const {data: invoiceYears, error: invoiceYearsError} = useQuery(
    ['invoice_years_list', user.activeBU],
    async () => {
      const response = await fetch(`/api/get-invoice-years-by-customer`)

      if (!response.ok) {
        throw new Error('Failed to fetch invoice years')
      }

      const years = await response.json()

      if (years.length > 0) {
        setYear(years[0])
      }
      return years.map((year: string, index: number) => {
        return {
          id: index,
          name: year,
        }
      })
    },
    {
      retry: 3,
    },
  )

  if (year === '' && invoiceYears && invoiceYears.length > 0) {
    setYear(invoiceYears[0].name)
  }

  const {
    data: invoicePages,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery<{
    invoices: CollapsibleInvoiceCardProps[]
    continuationToken: string
  }>({
    queryKey: ['invoice_list', user.activeBU, year],
    getNextPageParam: lastPage => lastPage.continuationToken,
    queryFn: async ({pageParam}) => {
      // Fetch invoices from cosmos db
      const url = '/api/get-invoices-by-customer'
      const params = new URLSearchParams()
      params.set('year', year)
      params.set('limit', INVOICES_PER_PAGE.toString())

      if (pageParam) {
        params.set('continuation', pageParam)
      }

      const invoicesResponse = await fetch(`${url}?${params}`)
      const invoicesJson = await invoicesResponse.json()

      const invoices = invoicesJson.invoices as CosmosInvoice[]
      const continuationToken = invoicesJson.continuationToken as string

      const mappedInvoices = invoices.map(invoice => {
        return {
          id: invoice.id,
          documents: invoice.documents,
          name: invoice.name[router.locale! as keyof Translations] as string,
          remark: invoice.remark[router.locale! as keyof Translations] as string,
          price: priceToString(invoice.price)!,
          timestamp: invoice.timestamp,
        }
      })
      return {
        invoices: mappedInvoices,
        continuationToken,
      }
    },
    enabled: !!year,
    refetchOnWindowFocus: false,
  })

  const invoices = invoicePages?.pages.flatMap(page => page.invoices)

  return (
    <>
      {invoices && invoices.length > 0 ? (
        <InvoiceList
          title={title ?? ''}
          invoices={invoices}
          years={invoiceYears}
          hasMore={hasNextPage ?? false}
          loadMore={() => fetchNextPage()}
          isLoading={isRefetching}
          setYear={setYear}
        />
      ) : (
        <div className="w-full">
          <div className="flex gap-4 justify-between g768:justify-start w-full">
            <h2>{title}</h2>
          </div>
          <div className="my-8">
            {invoiceYearsError ? (
              <p>{t('invoice.fetchError')}</p>
            ) : invoiceYears && invoiceYears.length === 0 ? (
              <p>{t('invoice.noInvoiceFound')}</p>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default InvoiceListBlok
