export const attributesListToMap = (
  attributes?: {name: string; value: any}[],
): Record<string, any> => {
  if (!attributes) return {}

  return Object.fromEntries(attributes.map(attr => [attr.name, attr.value]))
}

export const currencySymbols: Record<string, string> = {
  EUR: '€',
}

export const centPrecisionToString = (
  centAmount: number,
  fractionDigits: number,
  currencyCode: string,
  toFixed: number = 2,
) => {
  const symbol = currencySymbols[currencyCode] ?? currencyCode
  const amount = centAmount / 10 ** fractionDigits

  return `${symbol} ${amount.toFixed(toFixed)}`
}

export const centPrecision = (centAmount: number, fractionDigits: number, toFixed: number = 2) => {
  const amount = centAmount / 10 ** fractionDigits
  return parseFloat(amount.toFixed(toFixed))
}

export const priceToString = (price?: {
  cent_amount: number
  fraction_digits: number
  currency_code: string
}) => {
  return price
    ? centPrecisionToString(price.cent_amount, price.fraction_digits, price.currency_code)
    : null
}
