import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import styles from './PreviewContainer.module.css'

export interface PreviewContainerProps {
  title: string
  path?: string
  mediaTextContainer?: boolean
  children: JSX.Element
}

const PreviewContainer = ({title, path, children, mediaTextContainer}: PreviewContainerProps) => {
  const {t} = useTranslation('common')

  return (
    <>
      <div
        className={`flex justify-between mb-6 items-end ${mediaTextContainer ? 'g768:hidden' : ''}`}
      >
        <h2 className="g768:max-w-[40%] max-w-[60%]">{title}</h2>
        {path ? (
          <Link href={path} className="flex">
            <div className="flex items-center">
              <p className="mr-3 max-w-[10rem] g768:form-like font-semiBold">
                {t('navigation.all')}
              </p>
              <div
                className={`bg-fixmerBlue p-1 w-6 h-6 g1680:h-[1vw] flex justify-center items-center g1680:w-[1vw] rounded-full`}
              >
                <div
                  className={`${styles.arrow} bg-contain bg-center bg-no-repeat g1680:h-[0.5vw] g1680:w-[0.5vw] w-3 h-3`}
                ></div>
              </div>
            </div>
          </Link>
        ) : null}
      </div>
      {children}
    </>
  )
}

export default PreviewContainer
