import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import type {ProfileHomepageStoryblok} from '../../../storyblok-types/component-types-sb'

const ProfileHomepage = ({blok}: ProfileHomepageStoryblok) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.body
        ? blok.body.map((blok: any) => <StoryblokComponent blok={blok} key={blok._uid} />)
        : null}
    </main>
  )
}

export default ProfileHomepage
