// Fixed translations for SSR pages

module.exports = {
  pathTranslations: {
    products: {
      en: '/products',
      nl: '/producten',
      fr: '/produits',
    },
    promotions: {
      en: '/products/promotions',
      nl: '/producten/promoties',
      fr: '/produits/promotions',
    },
  }
}
