import {createContext, useState} from 'react'

interface ClientProductSearchContextType {
  query: string
  sortKey: string
  setQuery: (query: string) => void
  setSortKey: (sortKey: string) => void
  nextMatch: () => void
  prevMatch: () => void
  subscribe: (func: OnCycleMatchFunction) => void
  unsubscribe: (func: OnCycleMatchFunction) => void
}

export const ClientProductSearchContext = createContext<ClientProductSearchContextType>({
  query: '',
  sortKey: 'default',
  setQuery: () => {},
  setSortKey: () => {},
  nextMatch: () => {},
  prevMatch: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
})

type OnCycleMatchFunction = (direction: 'next' | 'prev') => void

export const ClientProductSearchContextProvider: React.FC = ({children}) => {
  const [query, setQuery] = useState<string>('')
  const [sortKey, setSortKey] = useState<string>('default')

  const [observers, setObservers] = useState<OnCycleMatchFunction[]>([])

  const nextMatch = () => {
    notifyObservers('next')
  }

  const prevMatch = () => {
    notifyObservers('prev')
  }

  const notifyObservers = (direction: 'next' | 'prev') => {
    observers.forEach(func => {
      func(direction)
    })
  }

  const subscribe = (func: OnCycleMatchFunction) => {
    setObservers(current => [...current, func])
  }

  const unsubscribe = (func: OnCycleMatchFunction) => {
    setObservers(current => [...current].filter(f => f != func))
  }

  return (
    <ClientProductSearchContext.Provider
      value={{
        query,
        sortKey,
        setQuery,
        setSortKey,
        nextMatch,
        prevMatch,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </ClientProductSearchContext.Provider>
  )
}
