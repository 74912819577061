import ComponentContainer from '../../containers/ComponentContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface TextCardProps extends SbEditableProps {
  content: JSX.Element
  fitParent?: boolean
}

const TextCard = ({content, sbEditable, fitParent}: TextCardProps) => {
  return (
    <div {...sbEditable}>
      <ComponentContainer
        type={!fitParent ? 'large' : 'full'}
        noPadding={fitParent}
        className={!fitParent ? '2xl:w-[50vw]' : ''}
      >
        <div className="textCard">{content}</div>
      </ComponentContainer>
    </div>
  )
}

export default TextCard
