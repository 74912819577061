import classNames from 'classnames'
import {useTranslation} from 'next-i18next'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useContext} from 'react'
import {CartContext} from '../../../lib/context/cart-context'
import {ModalContext} from '../../../lib/context/modal-context'
import {getPriceFromString, gtmHandleProduct} from '../../../lib/gtm-events'
import useUser from '../../../lib/hooks/use-user'
import {centPrecision, centPrecisionToString} from './../../../lib/commercetools/ct-utilities'
import useProductPrices from './../../../lib/hooks/use-product-prices'
import useProductsInLists from './../../../lib/hooks/use-products-in-list'
import styles from './ProductCard.module.css'

const AddToCartButton = dynamic(() => import('../../components/AddToCartButton'))
const Price = dynamic(() => import('../../components/Price'))

export interface PromoLabelProps {
  label: string
  url: string
}

export interface ProductCardProps {
  type?: 'product'
  id: string
  brand?: string
  productName: string
  image?: string
  imageAlt: string
  newProduct?: boolean
  bestSeller?: boolean
  contentPerUnit: string
  basePrice?: string // Fetched if not provided
  reducedPrice?: string // Fetched if not provided
  pricePerUnit?: string
  promos?: PromoLabelProps[]
  onDetailPage?: boolean
  horizontal?: boolean
  category?: string
  slug?: string
  path?: string
  favorited?: boolean // Fetched if not provided
  amountInCart?: number // Fetched if not provided
}

const ProductCard = ({
  id,
  brand,
  productName,
  image,
  imageAlt,
  newProduct,
  bestSeller,
  contentPerUnit,
  basePrice,
  reducedPrice,
  pricePerUnit,
  horizontal,
  promos,
  onDetailPage,
  category,
  slug,
  path,
  favorited,
  amountInCart,
}: ProductCardProps) => {
  const router = useRouter()
  const {user} = useUser()
  const {t} = useTranslation('common')
  const {productSlugsInLists} = useProductsInLists()
  const {prices} = useProductPrices(onDetailPage ? [id] : [])

  const {cartProductQuantities, cartAddProduct, cartRemoveProduct, cartChangeQuantity} =
    useContext(CartContext)

  // Favorited in lists
  const isFavorited = favorited || (slug ? productSlugsInLists.includes(slug) : false)

  // Amount in cart
  const amount = amountInCart ?? cartProductQuantities[id]?.quantity ?? 0

  // Pricing
  const productPrice = prices?.[id]
  const hasPromotions = (promos?.length ?? 0) > 0

  let displayedBasePrice = basePrice
  let displayedReducedPrice = reducedPrice

  if (productPrice?.price) {
    displayedBasePrice = centPrecisionToString(
      productPrice.price.cent_amount,
      productPrice.price.fraction_digits,
      productPrice.price.currency_code,
    )
  }

  if (productPrice?.promo_price && !onDetailPage && hasPromotions) {
    displayedReducedPrice = centPrecisionToString(
      productPrice.promo_price.cent_amount,
      productPrice.promo_price.fraction_digits,
      productPrice.promo_price.currency_code,
    )
  }

  // Handlers
  const handleGTM = (event: 'add_to_cart' | 'remove_from_cart', quantity?: number) => {
    const price = getPriceFromString(displayedReducedPrice ?? displayedBasePrice!)

    gtmHandleProduct(event, price, id, productName, brand ?? '', category ?? '', quantity ?? 1)
  }

  const handleAddToCart = async () => {
    await cartAddProduct(id, category ?? '')
    handleGTM('add_to_cart')
  }
  const handleRemoveProduct = async () => {
    await cartRemoveProduct(id)
    handleGTM('remove_from_cart')
  }

  const handleChangeAmount = async (quantity: number) => {
    const previousAmount = cartProductQuantities[id].quantity
    await cartChangeQuantity(id, quantity)

    if (previousAmount < quantity) {
      handleGTM('add_to_cart', quantity - previousAmount)
    } else {
      handleGTM('remove_from_cart', previousAmount - quantity)
    }
  }

  const handleFavoriteClick = () => {
    if (user.activeBU) {
      showListModal()
    } else {
      router.push(`/login?url=${router.asPath}`)
    }
  }

  // Modal
  const {addProduct, showModal} = useContext(ModalContext)

  const showListModal = () => {
    showModal()
    addProduct({
      id,
      image,
      imageAlt,
      brand,
      productName,
      category,
      price: centPrecision(
        productPrice?.price?.cent_amount!,
        productPrice?.price?.fraction_digits!,
      ),
      contentPerUnit,
    })
  }

  // Class names
  const cardWrapperClasses = classNames(
    'w-full overflow-hidden relative bg-fixmerWhite h-full rounded-3xl',
    {
      'min-h-[8rem]': !horizontal,
      'g768:min-h-[14rem]': horizontal,
      'min-h-[14rem]': horizontal && promos && promos.length > 0,
      'min-h-[10rem]': horizontal && promos && promos.length < 0,
    },
  )

  const cardContentClasses = classNames('flex h-full relative', {
    'p-6 flex-col gap-y-2': !horizontal,
    'pb-24': !horizontal && user.activeBU,
    'cursor-default': onDetailPage,
    'min-h-[15rem]': horizontal && onDetailPage,
  })

  const imageContentClasses = classNames('relative', {
    'w-full h-[9.375rem]': !horizontal,
    'mx-3': horizontal,
    'h-[18rem]': !horizontal && onDetailPage,
    'w-[20%]': horizontal && !onDetailPage,
    'w-[40%] mr-4': horizontal && onDetailPage,
  })

  const textContentWrapper = classNames('flex flex-col', {
    'gap-y-2 mt-2 items-center': !horizontal,
    'gap-y-[5px]': horizontal && !onDetailPage,
    'gap-y-3 justify-between': horizontal && onDetailPage,
  })

  const promoCollectionWrapper = classNames('flex flex-wrap mb-6 gap-x-1 gap-y-2', {
    'justify-center': !horizontal,
  })

  const buttonWrapper = classNames('m-auto w-full flex left-0 absolute z-10 bottom-0 mb-6', {
    'justify-center': !horizontal,
    'justify-end pr-4': horizontal,
    'mb-16': horizontal && onDetailPage,
  })

  const favoriteIcon = classNames(
    'w-6 h-6 absolute right-4 top-3 z-10 cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out',
    {
      'w-8 h-8': horizontal && onDetailPage,
    },
  )

  const titleClasses = classNames('', {
    'h2-like g768:h3-like text-center': !horizontal,
    'min-h-[3rem] mt-4': !onDetailPage && !horizontal,
    'my-2': onDetailPage && !horizontal,
    'g768:text-h4 g768:max-w-[80%] max-w-[12rem]': !onDetailPage,
    'max-w-[25rem]': onDetailPage,
  })

  const isUniqueLabel = (
    value: PromoLabelProps,
    index: number,
    array: PromoLabelProps[],
  ): boolean => {
    return array.findIndex(item => item.label === value.label) === index
  }

  return (
    <div className={`${cardWrapperClasses}`}>
      {newProduct ? (
        <div className="absolute z-10 text-[0.60rem]  -left-[3.3rem] -top-3 bg-fixmerGrey w-fit -rotate-45 font-bitter font-bold uppercase  form-like text-white px-12 pb-2 pt-8">
          {t('product.new')}
        </div>
      ) : bestSeller ? (
        <div className="absolute z-10 text-[0.60rem] -left-[3.7rem] -top-3 bg-fixmerGrey w-fit -rotate-45 font-bitter font-bold uppercase  form-like text-white px-12 pb-2 pt-9">
          {t('product.bestseller')}
        </div>
      ) : null}
      <button onClick={handleFavoriteClick} aria-label="favorite" className={favoriteIcon}>
        {isFavorited ? (
          <div
            className={`w-full h-full bg-contain bg-no-repeat bg-center ${styles.favoritesFull}`}
          ></div>
        ) : (
          <div
            className={`w-full h-full bg-contain bg-no-repeat bg-center ${styles.favoritesEmpty}`}
          ></div>
        )}
      </button>
      <Link href={path ?? '#'} className={`${cardContentClasses}`}>
        <div className={imageContentClasses}>
          {image ? (
            <Image src={image} alt={imageAlt ?? 'product'} fill className="object-contain" />
          ) : null}
        </div>
        <div className={horizontal ? 'py-6 flex flex-col w-[80%] justify-between' : ''}>
          <div>
            <div className={textContentWrapper}>
              {brand ? (
                <h5
                  className={`text-fixmerLightBlue ${
                    horizontal && !onDetailPage ? 'text-body' : 'h4-like g768:h5-like'
                  }`}
                >
                  {brand}
                </h5>
              ) : null}
              <h3 className={titleClasses}>
                <span>{productName}</span>
              </h3>
              <div
                className={`flex flex-col ${
                  !horizontal ? 'items-center' : ''
                } max-w-[12rem] justify-between`}
              >
                {contentPerUnit ? (
                  <p className="border-fixmerYellow g768:text-small w-fit border mb-2 text-fixmerBlue py-1 px-2 rounded-full">
                    {contentPerUnit}
                  </p>
                ) : null}
                <div className={promoCollectionWrapper}>
                  {promos?.filter(isUniqueLabel).map(promo => {
                    const classes = classNames(
                      'bg-fixmerRed  text-fixmerWhite text-[0.6rem] g768:text-small px-2 py-1 g768:px-4 rounded-full',
                      {
                        'group-hover:bg-fixmerBackgroundGrey transition-color duration-100 ease-in-out group-hover:text-fixmerBlue':
                          !!promo.url,
                      },
                    )

                    const content = <p className={classes}>{promo.label}</p>

                    return promo.url ? (
                      <Link key={promo.label} className="group" href={promo.url}>
                        {content}
                      </Link>
                    ) : (
                      <div key={promo.label} className="group">
                        {content}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={`flex flex-col ${!horizontal ? 'items-center' : ''}`}>
            <div className={`${horizontal ? 'w-[60%] g768:mb-0' : ''}`}>
              {displayedBasePrice ? (
                <Price
                  styling={!horizontal ? 'medium' : horizontal && !onDetailPage ? 'small' : 'large'}
                  price={displayedBasePrice ?? ''}
                  alternative={onDetailPage}
                  reducedPrice={displayedReducedPrice}
                  gap={!horizontal ? 'large' : horizontal && !onDetailPage ? 'small' : 'large'}
                ></Price>
              ) : null}
            </div>
            {user.activeBU && pricePerUnit ? (
              <p
                className={`text-fixmerGrey ${onDetailPage ? 'mt-4' : ''}`}
              >{`(${pricePerUnit} / unit)`}</p>
            ) : null}
          </div>
        </div>
      </Link>
      {user.activeBU ? (
        <AddToCartButton
          amount={amount}
          className={buttonWrapper}
          addToCart={handleAddToCart}
          removeFromCart={handleRemoveProduct}
          changeQuantity={handleChangeAmount}
        />
      ) : null}
    </div>
  )
}

export default ProductCard
