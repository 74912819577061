import {useTranslation} from 'next-i18next'
import {useContext, useEffect, useState} from 'react'
import {CartContext} from '../../../lib/context/cart-context'
import {gtmAddOrderlistToCart} from '../../../lib/gtm-events'
import Button from '../../buttons/Button'
import ListProductCard, {ListProductCardProps} from '../../cards/ListProductCard'
import LoadingDots from '../../components/LoadingDots'
import useProductPrices from '../../../lib/hooks/use-product-prices'
import {ClientProductSearchContext} from '../../../lib/context/client-product-search-context'

export interface ListCollapsibleProps {
  products?: ListProductCardProps[]
  category: string
  favoriteList?: boolean
  highlightedIds?: Set<string | number> | undefined
}

export interface VerticalProductListProps {
  title?: string
  tobaccoProducts?: ListProductCardProps[]
  nonTobaccoProducts?: ListProductCardProps[]
  tobaccoProductsSortedByCategory?: {
    [key: string]: ListProductCardProps[] | undefined
  }
  nonTobaccoProductsSortedByCategory?: {
    [key: string]: ListProductCardProps[] | undefined
  }
  favoriteList?: boolean
  deleteList?: () => void
}

type ProductsSortFunction = (a: ListProductCardProps, b: ListProductCardProps) => number

const ListCollapsible = ({
  products,
  category,
  favoriteList,
  highlightedIds,
}: ListCollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <div className="w-full py-2 px-4">
      <div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        className="flex cursor-pointer mb-2 items-center gap-2"
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          className={isOpen ? 'rotate-90' : ''}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_12924_29260)">
            <path
              d="M5.5 8.5H10.5"
              stroke="#6F7CCF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
              stroke="#6F7CCF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_12924_29260">
              <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>

        <p className="text-fixmerLightBlue">{category}</p>
      </div>
      {isOpen ? (
        <div>
          {products?.map(item => {
            return (
              <ListProductCard
                key={item.id}
                buyOptions={favoriteList}
                highlight={highlightedIds?.has(item.id)}
                {...item}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

const VerticalProductList = ({
  title,
  tobaccoProducts,
  tobaccoProductsSortedByCategory,
  nonTobaccoProducts,
  nonTobaccoProductsSortedByCategory,
  favoriteList,
  deleteList,
}: VerticalProductListProps) => {
  const {t} = useTranslation()

  const [suggestionAdded, setSuggestionAdded] = useState(0)
  const [sortByCategory, setSortByCategory] = useState(false)
  const [hightlightedIds, setHighLightedIds] = useState<Set<string | number> | undefined>()
  const [deleteWarning, setDeleteWarning] = useState(false)

  const {cartAddProductList, isCartLoading} = useContext(CartContext)
  const {query, sortKey, subscribe, unsubscribe} = useContext(ClientProductSearchContext)

  const {prices, keysLoading} = useProductPrices([])

  const setToDeleteWarning = () => {
    setDeleteWarning(true)
  }

  const handleAddToCart = async () => {
    const productKeys: string[] = []
    const categoryKeys: string[] = []
    const quantities: number[] = []

    tobaccoProducts?.forEach(product => {
      if (!product.notAvailable && prices?.[product.id]?.price) {
        productKeys.push(product.id.toString())
        categoryKeys.push(product.category ?? '')
        quantities.push(product.quantity || 1)
      }
    })

    nonTobaccoProducts?.forEach(product => {
      if (!product.notAvailable && prices?.[product.id]?.price) {
        productKeys.push(product.id.toString())
        categoryKeys.push(product.category ?? '')
        quantities.push(product.quantity || 1)
      }
    })

    await cartAddProductList(productKeys, categoryKeys, quantities, () => {
      setSuggestionAdded(suggestionAdded + 1)
      gtmAddOrderlistToCart(tobaccoProducts ?? [], nonTobaccoProducts ?? [])
    })
  }

  const handleMatchNavigation = (direction: 'next' | 'prev') => {
    const scrollTos = document.querySelectorAll('.product-card-highlight')

    const highlightCards = Array.from(scrollTos)

    const currentIndex = highlightCards.findIndex(element =>
      element.classList.contains('active-scroll-to'),
    )

    const scrollToIndex =
      direction === 'next'
        ? (currentIndex + 1) % highlightCards.length
        : (currentIndex - 1 + highlightCards.length) % highlightCards.length

    highlightCards[currentIndex]?.classList.remove('active-scroll-to')
    highlightCards[scrollToIndex]?.classList.add('active-scroll-to')
    highlightCards[scrollToIndex]?.scrollIntoView({behavior: 'smooth'})
  }

  const sortedTobaccoProducts = [...(tobaccoProducts ?? [])]
  const sortedNonTobaccoProducts = [...(nonTobaccoProducts ?? [])]

  // Highlight items when query changes
  useEffect(() => {
    if (!query) {
      setHighLightedIds(undefined)
      return
    }

    const allProducts = [...sortedTobaccoProducts, ...sortedNonTobaccoProducts]

    const matches = allProducts.filter(product => {
      return new RegExp(query, 'gi').test([product.brand, product.productName].join(' '))
    })

    setHighLightedIds(new Set(matches.map(product => product.id)))
  }, [query])

  // Scroll to first highlighted item when highlighted items change
  useEffect(() => {
    const scrollTos = document.querySelectorAll('.product-card-highlight')

    scrollTos.forEach(element => {
      element.classList.remove('active-scroll-to')
    })

    const scrollTo = scrollTos[0]

    if (scrollTo) {
      scrollTo.classList.add('active-scroll-to')
      scrollTo.scrollIntoView({behavior: 'smooth'})
    }
  }, [hightlightedIds])

  useEffect(() => {
    subscribe(handleMatchNavigation)

    return () => {
      unsubscribe(handleMatchNavigation)
    }
  }, [])

  let sortFn: ProductsSortFunction | undefined = undefined

  if (!sortKey || sortKey === 'default') {
    sortFn = (a, b) => {
      // Sort by brand
      if (a.brand !== b.brand) {
        return a.brand.localeCompare(b.brand)
      }
      // Then by product name
      return a.productName.localeCompare(b.productName)
    }
  }

  useEffect(() => {
    if (sortKey === 'category') {
      setSortByCategory(true)
    } else {
      setSortByCategory(false)
    }
  }, [sortKey])

  if (sortFn) {
    sortedTobaccoProducts.sort(sortFn)
    sortedNonTobaccoProducts.sort(sortFn)
  }

  const productsExist = tobaccoProducts?.length! > 0 || nonTobaccoProducts?.length! > 0

  return (
    <div className="w-full g1024:bg-fixmerWhite g1024:px-4 pb-4 p-0 g1024:p-0">
      <div className="flex gap-4 pt-6 g1024:pt-0 justify-between w-full">
        <h2 className={` ${favoriteList ? 'block' : 'hidden'} g768:block`}>{title}</h2>
        {productsExist ? (
          <Button
            styling="primary"
            onClick={handleAddToCart}
            className="hidden g768:block"
            label={t('general.addAllToShoppingCart')}
            disabled={keysLoading.length > 0}
          />
        ) : null}
      </div>
      {productsExist ? (
        <div>
          {!favoriteList ? (
            <div className="w-full flex justify-center g768:justify-end">
              {isCartLoading ? (
                <div className="mt-4">
                  <LoadingDots />
                </div>
              ) : suggestionAdded > 0 ? (
                <p className="text-fixmerBlue font-bold px-4 text-center mt-4">
                  {t('order.orderAddedToCart', {quantity: suggestionAdded})}
                </p>
              ) : null}
            </div>
          ) : null}
          {sortedTobaccoProducts && sortedTobaccoProducts.length > 0 ? (
            <div
              className="flex shadow-[0px_0px_6px_6px_#f0f0f0]
          rounded-2xl py-4 bg-white flex-col gap-2 g768:mt-8"
            >
              <h5 className="px-4 h3-like mb-4 g768:h5-like">{t('cart.tobaccoProducts')}</h5>
              {sortByCategory ? (
                <div className="w-full">
                  {Object.keys(tobaccoProductsSortedByCategory!).map(key => {
                    return (
                      <ListCollapsible
                        key={key}
                        category={key}
                        products={tobaccoProductsSortedByCategory![key]}
                        highlightedIds={hightlightedIds}
                        favoriteList={favoriteList}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  {sortedTobaccoProducts.map(item => {
                    return (
                      <ListProductCard
                        key={item.id}
                        buyOptions={favoriteList}
                        highlight={hightlightedIds?.has(item.id)}
                        {...item}
                      />
                    )
                  })}
                </>
              )}
            </div>
          ) : null}
          {sortedNonTobaccoProducts && sortedNonTobaccoProducts.length > 0 ? (
            <div
              className="flex shadow-[0px_0px_6px_6px_#f0f0f0]
          rounded-2xl py-4 bg-white  flex-col gap-2 mt-8"
            >
              <h5 className="px-4 h3-like g768:h5-like">{t('cart.nonTobaccoProducts')}</h5>
              {sortByCategory ? (
                <div className="w-full">
                  {Object.keys(nonTobaccoProductsSortedByCategory!).map(key => {
                    return (
                      <ListCollapsible
                        key={key}
                        category={key}
                        products={nonTobaccoProductsSortedByCategory![key]}
                        highlightedIds={hightlightedIds}
                        favoriteList={favoriteList}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  {sortedNonTobaccoProducts.map(item => {
                    return (
                      <ListProductCard
                        key={item.id}
                        buyOptions={favoriteList}
                        highlight={hightlightedIds?.has(item.id)}
                        {...item}
                      />
                    )
                  })}
                </>
              )}
            </div>
          ) : null}
          {!favoriteList ? (
            <div className="w-full flex justify-center g768:justify-end">
              {isCartLoading ? (
                <div className="mt-4">
                  <LoadingDots />
                </div>
              ) : suggestionAdded > 0 ? (
                <p className="text-fixmerBlue font-bold px-4 text-center mt-4">
                  {t('order.orderAddedToCart', {quantity: suggestionAdded})}
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="w-full flex g768:flex-row flex-col gap-4 items-center mt-8 px-4 g768:px-0 g768:justify-end justify-center">
            {favoriteList ? (
              <div className="flex w-fit justify-center">
                {deleteWarning ? (
                  <button className="text-fixmerRed underline" onClick={deleteList}>
                    {t('favorite.permanentlyDeleteList')}
                  </button>
                ) : (
                  <button className="text-fixmerRed underline" onClick={setToDeleteWarning}>
                    {t('favorite.deleteList')}
                  </button>
                )}
              </div>
            ) : null}

            <Button
              styling="primary"
              onClick={handleAddToCart}
              label={t('general.addAllToShoppingCart')}
            />
          </div>
        </div>
      ) : (
        <p className="mt-4"> {t('favorite.emptyList')} </p>
      )}
    </div>
  )
}

export default VerticalProductList
