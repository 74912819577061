import type {StoryblokBridgeV2} from '@storyblok/js'
import {useRouter} from 'next/router'
import {useEffect, useRef, useState} from 'react'
import {loadBridge} from '../storyblok/storyblok-bridge'

const useInStoryblokEditor = () => {
  const {asPath} = useRouter()

  const [isInEditor, setIsInEditor] = useState(false)

  const storyblokBridge = useRef<StoryblokBridgeV2 | null>(null)

  useEffect(() => {
    if (!storyblokBridge.current && asPath.includes('_storyblok')) {
      loadBridge(() => {
        console.debug('Storyblok bridge loaded')
        const {StoryblokBridge} = window

        const bridgeInstance = new StoryblokBridge()

        storyblokBridge.current = bridgeInstance

        setIsInEditor(storyblokBridge.current.isInEditor())
      })
    } else {
      setIsInEditor(false)
    }
  }, [asPath])

  return {
    inStoryblokEditor: isInEditor,
    storyblokBridge,
  }
}

export default useInStoryblokEditor
