import classNames from 'classnames'
import {useRef} from 'react'
import {useInViewport} from 'react-in-viewport'
import type {CategoryCardProps} from '../../cards/CategoryCard'
import CategoryCard from '../../cards/CategoryCard'
import ComponentContainer from '../../containers/ComponentContainer'
import PreviewContainer from '../../containers/PreviewContainer'
import type {SbEditableProps} from '../../storyblok/blok-props'

export interface CategoryListProps extends SbEditableProps {
  title: string
  path: string
  cards: CategoryCardProps[]
}

const CategoryList = ({title, path, cards, sbEditable}: CategoryListProps) => {
  const myRef = useRef<HTMLDivElement>(null)
  const {inViewport} = useInViewport(myRef)

  const categoryWrapperClasses = classNames(
    'flex items-center justify-center min-h-[10rem] g768:min-h-[10rem] g1200:min-h-[13rem] w-[48%]',
    {
      'g768:w-[32%]': cards.length === 3,
      'g768:w-[24%]': cards.length === 4,
      'g768:w-[19%]': cards.length === 5,
      'g1200:w-[16%] g768:w-[32%]': cards.length === 6 || cards.length > 7,
      'g1200:w-[13.5%] 768:w-[24%]': cards.length === 7,
    },
  )

  return (
    <div {...sbEditable}>
      <ComponentContainer type="large">
        <PreviewContainer title={title} path={path}>
          <div
            ref={myRef}
            className={`${
              inViewport ? 'translate-y-0 opacity-100' : 'translate-y-32 opacity-0'
            } flex flex-row grow flex-wrap justify-center gap-[2%] transition-all duration-500 g768:gap-[0.5vw] gap-y-2 my-4`}
          >
            {cards.map(card => {
              return (
                <div key={card.id} className={categoryWrapperClasses}>
                  <CategoryCard
                    id={card.id}
                    title={card.title}
                    icon={card.icon}
                    iconAlt={card.iconAlt}
                    link={card.link}
                  />
                </div>
              )
            })}
          </div>
        </PreviewContainer>
      </ComponentContainer>
    </div>
  )
}

export default CategoryList
