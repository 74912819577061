import type {SbBlokData} from '@storyblok/react'
import {StoryblokComponent} from '@storyblok/react'
import type {RenderOptions} from 'storyblok-rich-text-react-renderer'
import {render} from 'storyblok-rich-text-react-renderer'

interface RichTextProps {
  richText: any // richtext field of blok
  className?: string
  textInContainer?: boolean
}

const RichText = ({richText, className}: RichTextProps) => {
  const content = (
    <div className={`richtext ${className}`}>{render(richText, richTextResolvers)}</div>
  )

  return content
}

const richTextResolvers: RenderOptions = {
  defaultBlokResolver: (name, blok) => {
    blok.component = name
    return <StoryblokComponent blok={blok as SbBlokData} key={blok._uid as string} />
  },
}

export default RichText
